<div
  class="d-flex loader justify-content-center align-items-center"
  [class.loader-hidden]="!show"
>
  <div class="spinner">
    <div class="spinner__circle">
      <div class="spinner__circle-gradient"></div>

      <div class="spinner__circle-inner"></div>
    </div>
    <span class="ms-2 text"> Een momentje...</span>
  </div>
</div>
