import { Component, Input, OnInit } from '@angular/core';
@Component({
  selector: 'app-chart-v2',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponentV2 implements OnInit {

  @Input() chartType: any;
  @Input() chartData:any;

  constructor() { }

  ngOnInit(): void {
  }

}
