<div
  class="checkbox-group-layout content-container"
  [ngClass]="{
    boxShadow: !applyBoxShadow
  }"
>
  <div class="content-container">
    <ng-container *ngFor="let item of itemsList">
      <a class="list-group flex-row justify-content-between list-item-content">
        <div class="checkbox-icon-label-group">
          <div (click)="toggleCheckbox($event, item)" [class.non-clickable-div]="editMode != true">
            <input class="checkbox" type="checkbox" [ngModel]="item.checked" [disabled]="item.DEEPDIVE_STATE != 'DEEPDIVE_NOT_STARTED'" />
          </div>
          <div>
            <span class="icon">
              <img [src]="item.ICON" class="me-2" alt="icon" />
            </span>
          </div>
          <div class="itemLabelStructure">
            <label class="itemLabelTitle">{{ item.TITLE }}</label>
            <label class="itemLabelSubTitle">{{ getCurrentNumberOfLocation(item) }} out of {{ item.MAXIMUM_NUMBER_OF_LOCATIONS }} available locations</label>
          </div>
        </div>
        <div style="display: flex; flex-direction: row;" (click)="navigate(item)" [class.non-clickable-div]="getStatus(item.DEEPDIVE_STATE)!= 'Actie vereist' && getStatus(item.DEEPDIVE_STATE)!='Gepubliceerd'">
          <p [class]="getStatusClass(getStatus(item.DEEPDIVE_STATE))" class="status">{{getStatus(item.DEEPDIVE_STATE)}}</p>
          <img src="../../../assets/images/chevron-right.svg" alt="Right-Arrow" style="padding-left: 20px; cursor: pointer;"/>
        </div>
      </a>
    </ng-container>
  </div>
</div>
