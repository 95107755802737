<div class="regulations-layout">
  <form [formGroup]="regulationsForm">
    <div formArrayName="regulationSections">
      <ng-container
        *ngFor="
          let regSection of regulationsSections.controls;
          let index = index
        "
      >
        <div [formGroupName]="index">
          <p
            class="deepdive-text-info d-flex justify-content-between align-items-center"
            *ngIf="regSection?.get('ADDED')?.value"
          >
            <span
              class="deepdive-text-info"
              [innerHTML]="regSection?.get('TEXT')?.value"
            >
            </span>
            <app-context-menu
              [menuOptions]="menuOptions"
              (menuOptionSelected)="
                onMenuOptionSelected($event, regSection, index)
              "
            ></app-context-menu>
          </p>

          <app-text-editor
            *ngIf="!regSection?.get('ADDED')?.value"
            id="regulations_{{ index }}"
            [editorFormGroup]="regSection"
            controlName="TEXT"
          ></app-text-editor>

          <div
            class="add-buttons-block mt-4 mb-4 pb-3 d-flex justify-content-end"
            *ngIf="!regSection?.get('ADDED')?.value"
          >
            <button class="button-cancel bg-white border-0 me-4">Cancel</button>
            <button
              class="button-primary-white ms-2"
              (click)="saveSection(regSection, index)"
              [disabled]="!regSection?.get('TEXT')?.value"
            >
              Toevoegen
            </button>
          </div>
          <hr class="ruler" />
        </div>
      </ng-container>
    </div>
    <div class="mt-4 pt-3 d-flex justify-content-end">
      <button
        class="button-primary-white"
        (click)="addRegulationSection()"
        [disabled]="regulationsSections.value.length >= 4"
      >
        Een tekst sectie toevoegen
      </button>
    </div>
  </form>
</div>
