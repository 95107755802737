import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { cloneDeep, set } from 'lodash';
import { UtilService } from 'src/app/shared/services/util.service';
import { COMMENT_MENU_OPTIONS } from 'src/app/shared/utilities/constants';

@Component({
  selector: 'app-be-goal-risk-scan-details',
  templateUrl: './be-goal-risk-scan-details.component.html',
  styleUrls: ['./be-goal-risk-scan-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class BeGoalRiskScanDetailsComponent implements OnInit {
  @Input() userType!: string;
  @Input() selectedBEGoal!: any;
  // @Input() showMenu!: boolean;
  @Input() menuSelected: any = {};
  @Output() onCommentAdded = new EventEmitter<any>();
  @Output() optionEmitted = new EventEmitter<any>();
  @Output() selectedTab = new EventEmitter<any>();
  menuOptions: any = cloneDeep(COMMENT_MENU_OPTIONS);
  comment: string = this.selectedBEGoal?.NOTE;
  automatedAnswers: any = [];

  //Injecting dependencies
  public utilService = inject(UtilService);

  constructor() { }

  ngOnInit(): void {
    this.automatedAnswers = this.selectedBEGoal?.AUTOMATED_ANSWERS;
  }

  onNavChange(event: any) {
    this.selectedTab.emit(event?.nextId);
  }

  addingComment(comment: string) {
    this.onCommentAdded.emit(this.comment);
  }

  onMenuOptionSelected(option: any) {
    if (option?.dataKey === 'edit') {
      this.comment = this.selectedBEGoal?.NOTE;
    }
    this.menuSelected = option;
    this.optionEmitted.emit(option);
  }
}
