import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-generic-consultant-email',
  templateUrl: './generic-consultant-email.component.html',
  styleUrls: ['./generic-consultant-email.component.scss']
})
export class GenericConsultantEmailComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
