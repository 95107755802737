<div class="container m-20" [style.width]="'fit-content'" style=" margin-top: 50px; margin-bottom: 50px; padding: 20px; background-color: white;">
    <div class="row">
      <div class="col-md-6">
        <div class="legend-container" [style.width]="chartDimensions[0]+'px'" style="display: grid; grid-template-columns: 1fr auto;">
            <span class="graph-title"  style="text-align: center;">{{graphTopTitle}} </span>
           <ng-container *ngIf="data.length > 4">
              <data-sorting-widget 
                (applySorting)="applySorting($event)"
                (shouldShowTotal)="shouldShowTotal($event)"
                style="text-align: right;"
              ></data-sorting-widget>
           </ng-container>

        </div>
        <div class="c-container">
          <ngx-charts-bar-vertical
            [view]="chartDimensions"
            [scheme]="colorScheme"
            [results]="data"
            [gradient]="gradient"
            [xAxis]="showXAxis"
            [yAxis]="showYAxis"
            [showXAxisLabel]="false"
            [showYAxisLabel]="showYAxisLabel"
            [xAxisLabel]="xAxisLabel"
            [yAxisLabel]="yAxisLabel"
            [showDataLabel]="showDataLabel"
            [barPadding]="42"
            [yScaleMax]="yScaleMax"
            [roundEdges]="true"
            [wrapTicks]="true"
            [showGridLines]="true"
            [rotateXAxisTicks]="false"
            [trimXAxisTicks]="true"
            [maxXAxisTickLength]="12"
            [dataLabelFormatting]="formatDataLabel"
            [xAxisTickFormatting]="formatXAxisTickLabel"
            [legendPosition]="legendPosition"
            [legend]="false"
            [noBarWhenZero]="false"
          >
          </ngx-charts-bar-vertical>
        </div>
        <div *ngIf="graphBottomTitle" class="legend-container" [style.width]="chartDimensions[0]+'px'">
            <div class="legend-box"></div>
            <span class="legend-text">{{graphBottomTitle}}</span>
        </div>
      </div>
    </div>
  </div>
  