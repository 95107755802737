import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { filter, isEmpty, find, round, sum, range, reduce, forEach } from 'lodash';
import { PIE_CHART_LABELS, BE_GOALS, REDUCTION_CHART_LABELS, CHART_META, AFVAL_COLORS } from '../utilities/constants';
import { CHART_TYPES } from 'src/app/components/chart/utils';
import { CHART_DATA } from 'src/app/components/deepdive-notes/constants';
const COLORS_BG = [
  '#FF6700',
  '#0058FF',
  '#000060'
]
@Injectable({
  providedIn: 'root',
})
export class UtilService {
  constructor(private datePipe: DatePipe) { }

  //Restricting white spaces in textboxes at the first place
  public restrictLeadingSpace(event: any) {
    const strInput = event.target.value;
    if (!strInput.length) {
      event.preventDefault();
    }
  }

  //Moving to the top of screen
  public moveToTopOfTerScreen() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  //To add spaces after 4 digits
  public numberWithSpaces(number: number) {
    return number.toString().replace(/\B(?=(\d{4})+(?!\d))/g, ' ');
  }

  //To allow only digits
  public isNumber(evt: any) {
    evt = evt ? evt : window.event;
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  /**
   * Getting status text
   * @param obj - Object
   * @returns Status
   */
  public getStatus(obj: any) {
    let status = obj?.STATE;
    if (status === 'RISK_SCAN_NOT_STARTED') {
      return 'Riskscan not started';
    } else if (status === 'RISK_SCAN_SUBMITTED') {
      return 'Riskscan completed';
    } else if (status === 'RISK_SCAN_STARTED') {
      return 'Riskscan started';
    } else if (status === 'RISK_SCAN_PUBLISHED') {
      return 'Riskscan published';
    } else {
      return '';
    }
  }

  /**
   * Formatting the date
   * @param date - string (date)
   * @param format - string (date format)
   * @returns formatted date
   */
  public formatDate(date: any, format: any) {
    return this.datePipe.transform(date, format);
  }

  /**
   * Formatting the kvk number
   * @param number - number
   * @returns formatted KVK Number
   */
  public formatKvkNumber(number: any) {
    let trimmed = number?.toString().replace(/\s+/g, '');
    if (trimmed?.length > 8) {
      trimmed = trimmed?.substr(0, 8);
    }

    let numbers = [];
    for (let i = 0; i < trimmed?.length; i += 4) {
      numbers.push(trimmed?.substr(i, 4));
    }
    return numbers.join(' ');
  }

  /**
   * Returns number with leading zero if the nuimber is less than 10 and return same number if the numbwer is greater than 10
   * @param number - Number
   * @returns number with leading zero
   */
  public padWithLeadingZeros(number: any) {
    return number < 10 ? String(number).padStart(2, '0') : number;
  }

  /**
   * Determines whether the object is empty or not
   * @param object - Object
   * @returns boolean value
   */
  public isEmptyObject(object: Object) {
    return isEmpty(object);
  }

  /**
   * To filter the array by propertyname and value
   * @param array - Array
   * @param property - string
   * @returns filtered array
   */
  public filterArrayByProperty(array: any, property: string, value: string) {
    return filter(array, [property, value]);
  }

  /**
   * Returns a number converted to integer
   * @param value - number
   * @returns converted to number type
   */
  parseStringToNumber(value: any) {
    return Number(value);
  }

  /**
   * Return risk level text
   * @param risk - string (Risk level)
   * @returns risklevel text
   */
  getRiskLevelText(risk: string) {
    switch (risk) {
      case 'HIGH':
        risk = 'Hoog risico';
        break;
      case 'MODERATE':
        risk = 'Middel risico';
        break;
      case 'LOW':
        risk = 'Laag risico';
        break;
    }

    return risk;
  }

  formattingDate(date: any, delimiter: any) {
    let year = date?.year;
    let month = date?.month <= 9 ? '0' + date?.month : date?.month;
    let day = date?.day <= 9 ? '0' + date?.day : date?.day;
    let finalDate = day + delimiter + month + delimiter + year;
    return finalDate;
  }

  formatdateObject(date: any, delimiter: any) {
    const dateArray = date?.trim()?.split(/\-/g);
    const dateObj = {
      day: dateArray?.[0],
      month: dateArray?.[1],
      year: dateArray?.[2],
    };
    return dateObj;
  }

  toDate(date: any) {
    if (!date) {
      return;
    }

    const [day, month, year] = date?.split('-');
    const obj = {
      day: parseInt(day),
      month: parseInt(month),
      year: parseInt(year.split(' ')[0].trim()),
    };
    return obj;
  }

  setControlValue(
    formGroup: any,
    controlName: string,
    value: any,
    index: number
  ) {
    return formGroup?.at(index)?.get(controlName)?.setValue(value);
  }

  resetControlValue(formGroup: any, controlName: string, index: number) {
    return formGroup?.at(index)?.get(controlName)?.reset();
  }

  //returning the rounded values for displaying on the charts
  returnRoundedPercentageValues(chartValues: any) {
    chartValues = chartValues?.map((val: any) => Number(val));
    var roundedNumbers = chartValues?.map((val: any) => round(Number(val)));

    var originalSum = round(sum(chartValues)); //sum of original values
    var roundedSum = round(sum(roundedNumbers)); //sum of rounded values

    while (roundedSum != originalSum) {
      //Correcting the values by the difference of both sums
      var isRoundeSumLarger = roundedSum > originalSum;
      var maxDifferenceIndex = 0;
      var maxDifferenceValue = 0;
      for (var n = 0; n < chartValues.length; n++) {
        // Returns the absolute value of a number
        var difference = Math.abs(roundedNumbers[n] - chartValues[n]);
        if (
          (isRoundeSumLarger &&
            roundedNumbers[n] > chartValues[n] &&
            maxDifferenceValue < difference) ||
          (!isRoundeSumLarger &&
            roundedNumbers[n] < chartValues[n] &&
            maxDifferenceValue < difference)
        ) {
          maxDifferenceValue = difference;
          maxDifferenceIndex = n;
        }
      }
      var modifyValue = isRoundeSumLarger ? -1 : 1; // or whatever granularity the rounding algorithm is
      roundedNumbers[maxDifferenceIndex] += modifyValue;
      roundedSum += modifyValue;
    }
    return roundedNumbers;
  }

  getChartData(deepdiveChartData: any, deepdiveTitle?: any) {
    const locations = deepdiveChartData?.VALUES;
    const type = deepdiveChartData?.TYPE;
    const chartName = deepdiveChartData?.NAME;
    const chartId = deepdiveChartData?.ID;
    const dataLabel = deepdiveChartData?.NAME;
 
    var showTotal = true;
    var legend = true;
    if (!locations || !type) {
      return;
    }
    var i = 0;
    var backgroundColors;
    var labelColors;
    var data = [];
    var pieChartTotal = 0;
    const chartRef = CHART_META[deepdiveTitle][chartName];
    const chartType = chartRef.TYPE;
    const chartTitle = chartRef.TITLE;
    const yAxisLabel = chartRef.Y_AXIS_LABEL ?? undefined;
    const xAxisLabel = chartRef.X_AXIS_LABEL ?? undefined;
    const unit = chartRef.UNIT;
    const chartDimensions = chartRef.DIMENSIONS;
    if(chartName === 'RENEWABLE_RATES'){
      showTotal = false;
    }

    for (const location in locations) {
      if (locations.hasOwnProperty(location)) {
        var values = locations[location];
        if (type === 'PIE') {
          const pieChartValues = values?.map((chart:any)=> round(Number(chart?.VALUE)))
          pieChartTotal = pieChartValues.reduce((accumulator:any, currentValue:any) => {
            return accumulator + currentValue;
          }, 0);
          data = values?.map((chart: any) => {
            const dutchLabel = find(PIE_CHART_LABELS, ['LABEL', chart?.LABEL])?.DUTCH_LABEL ?? chart?.LABEL;
            const roundedValue = round(Number(chart?.VALUE));
            return {
              name: dutchLabel,
              value: roundedValue,
              label: `${roundedValue}${unit}`
            };
          });
        } else {
          const locationName = location;
          if (chartType === 'bar_chart') {
            //since it is bar chart, it has no more than one value at one location;
            const roundedValue = round(Number(values[0]?.VALUE));
            const locationData = {
              name: locationName,
              value: roundedValue,
            };
            data.push(locationData);
            backgroundColors = find(PIE_CHART_LABELS, ['LABEL', values[0]?.LABEL?.toUpperCase()])?.BACKGROUND_COLOR
          } else {
            let series = values?.map((chart: any) => {
              const dutchLabel = find(PIE_CHART_LABELS, ['LABEL', chart?.LABEL?.toUpperCase()])?.DUTCH_LABEL ?? chart?.LABEL;
              const roundedValue = round(Number(chart?.VALUE));

              return {
                name: dutchLabel,
                value: roundedValue,
              };
            });

            const dataSet = {
              name: locationName === 'COMPANY_WIDE' ? 'Company' : locationName,
              series,
            };
            data.push(dataSet);
          }

        }

        backgroundColors = values?.map((chart: any) => find(PIE_CHART_LABELS, ['LABEL', chart?.LABEL?.toUpperCase()])?.BACKGROUND_COLOR
        );

        labelColors = values?.map((chart: any) =>
          type === 'PIE'
            ? find(PIE_CHART_LABELS, ['LABEL', chart?.LABEL])?.COLOR
            : '#fff'
        );


      }
    }

    if (deepdiveTitle === 'Afval') {
      backgroundColors = AFVAL_COLORS;
    }
    const formatDataLabel = (value: any) => {
      return `${value} ${unit}`
    }
    return {
      chartName,
      graphTopTitle: chartTitle,
      chartId,
      data,
      formatDataLabel,
      legend,
      labels: type === 'PIE' ? false : true,
      chartDimensions,
      colorScheme: { domain: backgroundColors },
      backgroundColors,
      labelColors,
      dataLabel,
      chartType,
      unit,
      yAxisLabel,
      xAxisLabel,
      // yScaleMax: 100,
      showTotal,
      barPadding: '50%',
      pieChartTotal,
    };
  }

  getChartDataV2(type: any, breakEvenGoalId: any, index: any, deepdiveCharts: any) {
    // let utilData = this.utilService.getChartData( this.deepdiveCharts?.DEEPDIVE_CHARTS[0],this.deepdiveCharts?.TITLE);
    return;
    let chartType;
    let chartData;
    if (type === 'PERFORMANCE') {
      switch (breakEvenGoalId) {
        case 1:
          chartType = CHART_TYPES.PIE_CHART;
          CHART_DATA.pieChartSingle.data = deepdiveCharts?.DEEPDIVE_CHARTS?.find((charts: any) => charts.ID === "2")?.VALUES.COMPANY_WIDE.map((value: any) => {
            return {
              "name": value.LABEL === "RENEWABLE" ? "Renewable" : "Not Renewable",
              "value": Number(value.VALUE),
              "label": `${value.VALUE}%`
            }
          })
          chartData = index === 0 ? CHART_DATA.pieChartSingle : CHART_DATA.pieChart;
          return {
            chartType,
            chartData,
          }
        case 2:
          chartType = CHART_TYPES.PIE_CHART;
          chartData = CHART_DATA.pieChart;
          return {
            chartType,
            chartData
          }
        case 6:
          chartType = CHART_TYPES.BAR_CHART;
          chartData = CHART_DATA.ghgGraph;
          return {
            chartType,
            chartData,
          }
        case 7:
          chartType = CHART_TYPES.BAR_CHART;
          chartData = CHART_DATA.ghgGraph;
          return {
            chartType,
            chartData,
          }
        default:
          break;
      }
    } else if (type === "GOALS") {
      switch (breakEvenGoalId) {
        case 1:
          chartType = CHART_TYPES.PIE_CHART;
          chartData = index === 0 ? CHART_DATA.pieChartSingle : CHART_DATA.pieChart;
          return {
            chartType,
            chartData,
          }
        case 2:
          if (index === 0) {
            chartType = CHART_TYPES.PIE_CHART;
            chartData = CHART_DATA.pieChartSingle;
            return {
              chartType,
              chartData,
            }
          }
          return;
        case 6:
          chartType = CHART_TYPES.PIE_CHART;
          chartData = CHART_DATA.pieChartSingle;
          return {
            chartType,
            chartData,
          }
        case 7:
          chartType = CHART_TYPES.BAR_CHART;
          chartData = CHART_DATA.ghgGraph;
          return {
            chartType,
            chartData,
          }
        default:
          break;
      }
    }
    return;
  }

  //return goal chart data
  getGoalChartData(goalValue: any, title?: any, name?: any, values?: any, deepdiveYear?: any, deepdiveName?: any) {
    let currentValue = 0;
    let goalToReach = 0;
    if (deepdiveName == "Afval") {
      values.forEach((value: { "LABEL": string, "VALUE": number }) => {
        currentValue = currentValue + Number(value.VALUE);
      });
      goalToReach = goalValue;
    } else if (deepdiveName = 'Broeikasgasemissies (BKG\'s)') {
      currentValue = values[0]?.VALUE;
      goalToReach = goalValue;
    }
    let goalChartData = {
      NAME: name,
      TYPE: find(BE_GOALS, ['TITLE', title])?.CHART_TYPE ?? '',
      VALUES:
        find(BE_GOALS, ['TITLE', title])?.CHART_TYPE === 'PIE'
          ? [
            {
              LABEL: 'NONRENEWABLE',
              VALUE: 100 - Number(goalValue),
            },
            {
              LABEL: 'RENEWABLE',
              VALUE: goalValue,
            },
          ]
          : [
            {
              LABEL: deepdiveYear,
              VALUE: currentValue,
            },
            {
              LABEL: '2030',
              VALUE: goalToReach,
            }
          ],
    };
    return this.getChartData(goalChartData, title);
  }

  getDeepdiveUnit(deepdiveTitle: any) {
    return find(BE_GOALS, ['TITLE', deepdiveTitle])?.UNIT ?? '';
  }

  getDeepdiveMaxNotePerformanceSections(deepdiveTitle: any) {
    return range(
      1,
      Number(
        find(BE_GOALS, ['TITLE', deepdiveTitle])?.MAX_PERFORMACE_NOTE_SECTIONS
      ) + 1 ?? 2
    );
  }

  getDeepdiveMaxNoteGoalsSections(deepdiveTitle: any) {
    return range(
      1,
      Number(
        find(BE_GOALS, ['TITLE', deepdiveTitle])?.MAX_GOALS_NOTE_SECTIONS
      ) + 1 ?? 2
    );
  }
}
