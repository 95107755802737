import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { SharedService } from 'src/app/shared/services/shared.service';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { SORTING_ENUMS } from 'src/app/shared/constants';
@Component({
	selector: 'data-sorting-widget',
	templateUrl: './sorting-widget.component.html',
	styleUrls: ['./sorting-widget.component.scss'],
})
export class SortingWidgetComponent implements OnInit {
	@Output() applySorting = new EventEmitter<any>();
	@Output() shouldShowTotal = new EventEmitter<any>();

	closeResult = '';
	modalReference: any;
	isShowTotalEnabled: boolean = false;
	isDropdownOpen: boolean = false;
	consultantControl: any;
	isSubmenuOpen: boolean = false;
	sortBy: string = SORTING_ENUMS.HIGH_TO_LOW;
	sortingEnums: any = SORTING_ENUMS;
	parentDropDown: any = null;

	constructor(private sharedService: SharedService) { }

	ngOnInit(): void { }

	onChangeSwitch(event: any) {
		this.isShowTotalEnabled = event;
		this.shouldShowTotal.emit(event);
	}


	toggleDropdown() {
		this.isDropdownOpen = !this.isDropdownOpen;
	}


	toggleSubMenu() {
		this.isSubmenuOpen = !this.isSubmenuOpen;
	}

	setSortByValue(value: string) {
		this.sortBy = value;
		this.isSubmenuOpen = false;
		this.applySorting.emit(value);
	}

	openDropdown(dropdown: NgbDropdown) {
		this.isDropdownOpen = true;
		this.parentDropDown = dropdown;
		dropdown.open();
	}

	openSubMenu(dropdown: NgbDropdown) {
		this.isSubmenuOpen = true;
		dropdown.open();
	}

	closeSubMenu(dropdown: NgbDropdown) {
		this.isSubmenuOpen = false;
		// dropdown.close();
		this.isDropdownOpen = false;
		// this.closeDropdown();
	}

	closeDropdown() {
		this.isDropdownOpen = false;
		this.parentDropDown.close();
		this.parentDropDown = null;
	}



}
