<div
  ngbDropdown
  #selectDropdown="ngbDropdown"
  class="search-box"
  [formGroup]="formGroup"
  (openChange)="openChange($event)"
>
  <div class="search-input">
    <input
      type="text"
      class="form-control customInput"
      placeholder="Zoek..."
      (keydown.space)="utilService.restrictLeadingSpace($event)"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
      autocomplete="off"
      id="dropdownBasic_{{ controlName }}"
      value="{{ controlName }}"
      [formControlName]="controlName"
      (keyup)="search()"
      ngbDropdownAnchor
      (focus)="selectDropdown.open()"
    />
    <img
      class="arrow-icon cursor"
      ngbDropdownToggle
      [src]="
        open
          ? '../../../assets/images/select-upArrow.svg'
          : '../../../assets/images/select-downArrow.svg'
      "
      alt="arrow"
      [ngClass]="{ disabling: disabled || readonly, positionArrow: open }"
    />
  </div>
  <div
    class="select-dropdown scrollbar"
    ngbDropdownMenu
    aria-labelledby="dropdownBasic1"
    attr.aria-labelledby="dropdownBasic_{{ controlName }}"
  >
    <ul>
      <li
        class="select-options"
        *ngFor="let option of selectOptions | search : getSearchTerm()"
        ngbDropdownItem
        role="option"
        (click)="select(option)"
        attr.value="{{ option?.NAME }}"
        [ngClass]="{ active: option?.isSelected }"
      >
        <span>{{ option?.NAME }}</span>
        <img
          *ngIf="option?.isSelected"
          src="../../../assets/images/select-tick.svg"
          alt="Selected"
        />
      </li>
      <li
        class="select-options"
        *ngIf="(selectOptions | search: getSearchTerm())?.length === 0 || !selectOptions?.length"
      >
        No results found
      </li>
    </ul>
  </div>
</div>
