import { Component, EventEmitter,  inject, Input, OnInit, Output} from '@angular/core';
import { find } from 'lodash';
import { UtilService } from 'src/app/shared/services/util.service';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
// import { Component, EventEmitter, Input, OnInit, Output, FormBuilder, FormGroup, FormArray, AbstractControl } from '@angular/core';
// import { find } from 'lodash';
@Component({
  selector: 'app-scrollable-table',
  templateUrl: './scrollable-table.component.html',
  styleUrls: ['./scrollable-table.component.scss']
})
export class ScrollableTableComponent implements OnInit {
  @Input() tableData!: any;
  @Input() tableHeadings!: any[];
  @Output() onChangeCheckbox = new EventEmitter<any>();
  @Input() tableForm!: any;
  @Input() isFormEnabled!: any;
  form!: FormGroup;
  utilService = inject(UtilService);

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({});
  }

  onAnsweringCheckbox(row: any, id: any) {
    this.onChangeCheckbox.emit(row);
  }
  
  getArrayControls() {
    return (this.tableForm.get('array') as FormArray).controls;
  }

  hasError(index: any, type: any) {
    // let validators: any = type === 'text' ? ['minLength', 'maxLength'] : ['min', 'max'];
    // return Boolean((this.getArrayControls()[index].get('ANSWERS')?.hasError(validators[0]) ||
    //   this.getArrayControls()[index].get('ANSWERS')?.hasError(validators[1])))
    return false;
  }

  isTouched(index: any) {
    return true;
    // return Boolean(this.getArrayControls()[index].get('ANSWERS')?.touched)
  }

}