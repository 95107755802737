<div id="block-1">
  <div class="header" id="block-section-1">
    <img src="../../../assets/images/rabo-bank-logo.png" alt="Rabo Future-Fit" />
  </div>
  <div id="block-section-2">
    <div class="page-content">
      <h2 class="title">{{ deepdiveName ?? "" }} </h2>
      <p class="title">Hoe u er nu voorstaat</p>
      <p class="sub-title">
        Hoe u heeft gescoord op duurzaamheidsdoel {{deepdiveName}} in het jaar
        {{ deepdiveYear }} op basis van de gegevens die u hebt aangeleverd.
      </p>
    </div>
  </div>
</div>
<ng-container *ngIf="charts?.DEEPDIVE_CHARTS?.[0]">
  <div id="block-2">
    <div class="description-block">
      <p class="title">{{notesTitle}}</p>
      <p class="content">
        <markdown>
          {{previewDeepdiveDetails?.PERFORMANCE[0]?.TEXT}}
        </markdown>
      </p>
    </div>

  </div>
  <div id="block-3">
    <div class="chart-wrapper">
      <ng-container *ngIf="chartTitle">
        <p class="chart-title">{{chartTitle}}</p>
      </ng-container>
      <printable-chart width="400" [id]="charts?.DEEPDIVE_CHARTS?.[0]?.ID"
        [chartDetails]="utilService.getChartData(charts?.DEEPDIVE_CHARTS?.[0], charts?.TITLE)"></printable-chart>
    </div>

  </div>
</ng-container>

<ng-container *ngIf="charts?.DEEPDIVE_CHARTS?.[1]">
  <div id="block-4">
    <div class="description-block">
      <ng-container *ngIf="deepdiveId == '1'">
        <p class="title">Uitsplitsing energieverbruik</p>
      </ng-container>
      <ng-container *ngIf="deepdiveId == '2'">
        <p class="title">Waterbesparing</p>
      </ng-container>
      <ng-container *ngIf="deepdiveId == '6'">
        <p class="title">Energieverbruik per bron</p>
      </ng-container>
      <p class="content">
        <markdown>
          {{previewDeepdiveDetails?.PERFORMANCE[1]?.TEXT}}
        </markdown>
      </p>
    </div>
  </div>

  <div id="block-5">
    <div class="chart-wrapper">
      <ng-container *ngIf="deepdiveId == '1'">
        <p class="chart-title">Uitsplitsing energieverbruik</p>
      </ng-container>
      <ng-container *ngIf="deepdiveId == '2'">
        <p class="chart-title">Waterbesparing {{ chartTotal[1] }}</p>
      </ng-container>
      <printable-chart width="400" [id]="charts?.DEEPDIVE_CHARTS?.[1]?.ID"
        [chartDetails]="utilService.getChartData(charts?.DEEPDIVE_CHARTS?.[1], charts?.TITLE)"></printable-chart>
    </div>
  </div>

</ng-container>
<ng-container *ngIf="charts?.DEEPDIVE_CHARTS?.[2]">
  <div class="description-block" id="block-6">
    <ng-container *ngIf="deepdiveId == '2'">
      <p class="title">Waterafvoer</p>
    </ng-container>
    <p class="content">
      <markdown>
        {{previewDeepdiveDetails?.PERFORMANCE[2]?.TEXT}}
      </markdown>
    </p>
  </div>
  <div class="chart-wrapper" id="block-7">
    <ng-container *ngIf="deepdiveId == '2'">
      <p class="chart-title">Waterafvoer {{ chartTotal[2] }}</p>
    </ng-container>
    <printable-chart width="400" [id]="charts?.DEEPDIVE_CHARTS?.[2]?.ID"
      [chartDetails]="utilService.getChartData(charts?.DEEPDIVE_CHARTS?.[2], charts?.TITLE)"></printable-chart>
  </div>
</ng-container>

<div id="block-8">
  <!-- Regulations section start Why you should take action-->
  <div class="goal-section">
    <p class="title">Waarom u actie zou moeten ondernemen</p>
    <p class="sub-title mb-5"> {{whyActionDescription}} </p>
    <div class="d-flex flex-wrap">
      <span *ngFor="let regulation of previewDeepdiveDetails?.REGULATIONS" class="regulation-border">
        <span class="notes" [innerHTML]="regulation?.TEXT"> </span>
      </span>
    </div>
  </div>
</div>
<!-- Regulations section end-->

<!-- Goals section start-->

<div id="block-9">
  <div class="goal-section bg-white deepdive-section">
    <p class="title">Welke acties u zou kunnen ondernemen</p>
    <p class="sub-title">
      Maar wat kunt u doen om te zorgen dat uw organisatie toekomstbestendig
      is? Wij hebben een doel voor 2030 voor u voorgesteld en acties op een
      rijtje gezet die u zou kunnen ondernemen.
    </p>
  </div>
</div>

<div class="description-block" id="block-10">
  <ng-container *ngIf="deepdiveId == '1'">
    <p class="goal-text">
      Wij stellen voor om
      <span class="goal-number">
        {{ previewDeepdiveDetails?.GOALS[0]?.NUMBER }}
        {{ utilService.getDeepdiveUnit(charts?.TITLE) }}
      </span>
      hernieuwbare energie als doel voor
      <span class="goal-number"> 2030</span>
      te stellen voor uw organisatie.
    </p>
    <span class="content" [innerHTML]="previewDeepdiveDetails?.GOALS[0]?.TEXT">
    </span>
  </ng-container>
  <ng-container *ngIf="deepdiveId == '2'">
    <span class="goal-text" [innerHTML]="previewDeepdiveDetails?.GOALS[0]?.TEXT">
    </span>
    <span class="content" [innerHTML]="previewDeepdiveDetails?.GOALS[1]?.TEXT">
    </span>
  </ng-container>
  <ng-container *ngIf="deepdiveId == '6'">
    <p class="goal-text">
      Wij stellen voor om het reduceren van de broeikasgasemissies tot
      <span class="goal-number">
        {{ previewDeepdiveDetails?.GOALS[0]?.NUMBER }}
        {{ utilService.getDeepdiveUnit(charts?.TITLE) }}
      </span>
      in
      <span class="goal-number"> 2030</span>
      als doel te stellen voor uw organisatie.
    </p>
    <span class="content" [innerHTML]="previewDeepdiveDetails?.GOALS[0]?.TEXT">
    </span>
  </ng-container>
  <ng-container *ngIf="deepdiveId == '7'">
    <p class="goal-text">
      Wij stellen voor om
      <span class="goal-number">
        {{ previewDeepdiveDetails?.GOALS[0]?.NUMBER }}
        {{ utilService.getDeepdiveUnit(charts?.TITLE) }}
      </span>
      als doel voor
      <span class="goal-number"> 2030</span>
      te stellen voor uw organisatie.
    </p>
    <span class="content" [innerHTML]="previewDeepdiveDetails?.GOALS[0]?.TEXT">
    </span>
  </ng-container>
</div>
<ng-container *ngIf="deepdiveId == '7'">
  <div class="chart-wrapper" id="block-11">
    <p class="chart-title">{{ chartTotal[0] }}</p>
    <printable-chart width="400" [id]="'goal_1'" [chartDetails]="
                utilService.getGoalChartData(
                  previewDeepdiveDetails?.GOALS[0]?.NUMBER,
                  charts?.TITLE, 'Waste',
                  charts?.DEEPDIVE_CHARTS?.[0]?.VALUES, deepdiveYear, deepdiveName
                )
              "></printable-chart>
  </div>
</ng-container>
<ng-container *ngIf="deepdiveId != '7' && deepdiveId != '2'">
  <div class="chart-wrapper" id="block-12">
    <printable-chart width="400" [id]="'goal_1'" [chartDetails]="
                utilService.getGoalChartData(
                  previewDeepdiveDetails?.GOALS[0]?.NUMBER,
                  charts?.TITLE, charts?.DEEPDIVE_CHARTS?.[0]?.NAME,
                  charts?.DEEPDIVE_CHARTS?.[0]?.VALUES, deepdiveYear, deepdiveName
                )
              "></printable-chart>
  </div>
</ng-container>
<ng-container *ngIf="previewDeepdiveDetails?.ACTIONS && previewDeepdiveDetails.ACTIONS.length">
  <div *ngFor="let action of previewDeepdiveDetails.ACTIONS; let i = index">
    <div [attr.id]="'block-' + (i + 13)" class="action-block">
      <p class="title">{{ action.ID }}. {{ action.NOTETITLE }}</p>
      <p class="content">
        <markdown>
          {{ action.TEXT }}
        </markdown>
      </p>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!previewDeepdiveDetails?.ACTIONS?.length">
  <div id="block-13"> </div>
</ng-container>



<div id="blockContainer" #blockContainer class="new-block">
</div>