import { Action, ActionReducer } from '@ngrx/store';
import { DeepdiveAssessmentTypes } from '../actionTypes';
import { initialDeepdiveAssessmentState } from '../state/deepdiveAssessment.state';

export const deepdiveAssessmentReducer: ActionReducer<any> = (
  state = initialDeepdiveAssessmentState,
  action: any
) => {
  switch (action?.type) {
    case DeepdiveAssessmentTypes.DEEPDIVE_ASSESSMENT: {
      return {
        ...state,
        deepdiveAnswers: action?.payload?.value,
        groupId: action?.payload.groupId,
      };
    }

    default:
      return { ...state };
  }
};
