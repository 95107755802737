import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  ChangeDetectorRef,
  ViewChild,
} from '@angular/core';
import { ConsultantService } from 'src/app/shared/services/consultant.service';
import { ErrorService } from 'src/app/shared/services/error.service';
import {
  ToastService,
  TOAST_STATE,
} from 'src/app/shared/services/toast.service';
import { UtilService } from 'src/app/shared/services/util.service';
import { DeepdiveQuestionsComponent } from '../deepdive-questions/deepdive-questions.component';
import { DeepdiveQuestionsMultipleLocationsComponent } from '../deepdive-questions-multiple-locations/deepdive-questions-multiple-locations.component';

@Component({
  selector: 'app-deepdive-assessment-questions',
  templateUrl: './deepdive-assessment-questions.component.html',
  styleUrls: ['./deepdive-assessment-questions.component.scss'],
})
export class DeepdiveAssessmentQuestionsComponent implements OnInit {
  showLoader: boolean = false;
  showButtonLoader: boolean = false;
  @Input() deepDiveGroupQuestions: any;
  @Input() deepdiveTab: any;
  @Input() customerID: any;
  @Input() selectedBreakEvenGoal: any;
  @Input() deepdiveCharts: any;
  @Input() deepdiveTabs: any;
  @Input() refYear: any;
  chartTitle: any = [];
  initalAnswers: any = [];

  @Output() onChangingTab = new EventEmitter<any>();
  @Output() onChangingAnswers = new EventEmitter<any>();
  @ViewChild(DeepdiveQuestionsMultipleLocationsComponent) multipleLocationComponent!: DeepdiveQuestionsMultipleLocationsComponent;
  @ViewChild(DeepdiveQuestionsComponent) singleLocationComponent!: DeepdiveQuestionsComponent;

  private consultantService = inject(ConsultantService);
  public errorService = inject(ErrorService);
  public utilService = inject(UtilService);
  private toastService = inject(ToastService);

  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.deepdiveTab = this.deepdiveTab || this.deepdiveTabs?.[0];
  }

  onTotalCalculated(total: any): void {
    this.chartTitle.push(total);
    this.cdr.detectChanges();
  }

  onSelectingTab(tab: any) {
    this.deepdiveTab = tab;
    this.onChangingTab.emit(tab);

    //#TODO unused code
    // this.getDeepdiveQuestions(tab?.ID);
  }

  onDateSelect(date: any) {
    return !date ? '' : this.utilService.formattingDate(date, '-');
  }

  getTab(direction: string) { }

  onGroupAnswersSubmitted(data: any) { }

  onModifyingAnswers(answersArray?: any) {
    let error_message = '';
    this.showButtonLoader = true;
    const answeredArray = answersArray.reduce(
      (answeredArray: any, eachquestion: any) => {
        // if (!eachquestion?.DISABLED) {
        answeredArray.push({
          QUESTION_ID: eachquestion.ID,
          ANSWERS: eachquestion?.answerlabel
            ? [
              ...eachquestion?.answerlabel?.map(
                (labelValue: any) => labelValue ?? ''
              ),
            ]
            : [
              {
                ANSWER:
                  eachquestion?.TYPE === 'DATE'
                    ? this.onDateSelect(eachquestion?.ANSWERS)
                    : eachquestion?.ANSWERS ?? '',
              },
            ],
          LOCATION: eachquestion?.LOCATION
        });
        // }
        return answeredArray;
      },
      []
    );

    this.consultantService
      .saveDeepdiveAnswers(
        this.customerID,
        this.selectedBreakEvenGoal?.ID,
        this.deepdiveTab?.ID,
        this.refYear,
        answeredArray,

      )
      .subscribe({
        next: (response: any) => {
          this.toastService.showToast(
            TOAST_STATE.success,
            'Antwoorden zijn opgeslagen'
          );
          this.onChangingAnswers.emit(this.deepdiveTab);
        },
        error: (error) => {
          // handle error
          this.showButtonLoader = false;
          error_message =
            error?.error?.message ??
            'Er ging iets mis. Vernieuw de pagina of neem contact op met het website ondersteuningsteam.';
          this.toastService.showToast(TOAST_STATE.danger, error_message);
          this.utilService.moveToTopOfTerScreen();
        },
        complete: () => {
          //Request completed
          this.showButtonLoader = false;
          this.utilService.moveToTopOfTerScreen();
        },
      });
  }

  onPencilIconClick() {
    this.multipleLocationComponent.toggleForm();
    this.getInitialQuestionFormAnswers();
  }

  getQuestionFormState() {
    return this.multipleLocationComponent?.getQuestionFormState();
  }

  getInitialQuestionFormAnswers() {
    let initialQuestionData = this.multipleLocationComponent || this.singleLocationComponent;
    this.initalAnswers = initialQuestionData?.getQuestionsFormArray();
  }

  onChangeDeepdiveAnswers() {
    let questionData: any = this.multipleLocationComponent || this.singleLocationComponent;
    let array: any = [];

    if (this.deepdiveTab.ID == 10) {

      let formArray1 = questionData?.getTableQuestionsFormArray1().flat();
      let formArray2 = questionData?.getTableQuestionsFormArray2().flat();
      let textForm = questionData?.getTextForm().flat();
      array = formArray1.filter((a1: { ID: any; LOCATION: any; answerlabel: { LABEL: any; ANSWER: any; }[] }) => {
        const matchingObject = this.initalAnswers.find((a2: { ID: any; LOCATION: any; answerlabel: { LABEL: any; ANSWER: any; }[] }) =>
          a1.ID === a2.ID && a1.LOCATION === a2.LOCATION
        );

        if (matchingObject) {
          return a1.answerlabel.some((label1) => {
            const matchingLabel = matchingObject.answerlabel.find((label2: { LABEL: any; }) => label1.LABEL === label2.LABEL);

            return matchingLabel ? label1.ANSWER !== matchingLabel.ANSWER : true;
          });
        } else {
          return true;
        }
      });

      let formArray2Filtered = formArray2.filter((a1: { ID: any; ANSWERS: any; LOCATION: any }) => !this.initalAnswers.some((a2: { ID: any; ANSWERS: any; LOCATION: any }) => a1.ID === a2.ID && a1.ANSWERS === a2.ANSWERS && a1.LOCATION === a2.LOCATION));

      const matchedObjects = this.initalAnswers.filter((a1: { ID: any; LOCATION: any }) =>
        formArray2Filtered.some((a2: { ID: any; LOCATION: any }) =>
          a1.ID === a2.ID && a1.LOCATION === a2.LOCATION
        )
      );

      array.push(...matchedObjects.map((a1: { ID: any; LOCATION: any; ANSWERS: any }) => {
        const matchingObject = formArray2Filtered.find((a2: { ID: any; LOCATION: any; ANSWERS: any }) =>
          a1.ID === a2.ID && a1.LOCATION === a2.LOCATION
        );

        return matchingObject ? { ...a1, ANSWERS: matchingObject.ANSWERS } : a1;
      }));
      array = [...array, ...textForm]
    } else {
      let newAnswersArray = questionData?.getQuestionsFormArray();
      array = newAnswersArray;
      //#TODO testing
      //newAnswersArray.filter((a1: { ID: any; ANSWERS: any; }) => this.initalAnswers.some((a2: { ID: any; ANSWERS: any; }) => a1.ID === a2.ID && a1.ANSWERS === a2.ANSWERS));
    }

    if (!array) {
      return;
    }

    if (questionData?.questionsForm?.status === 'VALID') {
      this.onModifyingAnswers(array);
    }
  }

}
