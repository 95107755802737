<div class="risks-legenda">
  <span
    class="d-inline-flex me-2 mb-4 mb-md-0 pe-1"
    *ngFor="let riskLevel of riskLevels; let i = index"
  >
    <img
      src="../../../assets/images/{{
        (riskType === 'businessActivity' ? 'dot' : riskLevel.key) + '.svg'
      }}"
      class="me-2"
      alt="Low Risk"
    />
    <span
      >{{
        utilService.filterArrayByProperty(
          beGoalsList,
          riskType === "businessActivity"
            ? "INDUSTRY_RISK_LEVEL"
            : "RISK_LEVEL",
          riskLevel?.key
        ).length
      }}
      {{ utilService.getRiskLevelText(riskLevel?.key) }}</span
    >
  </span>
</div>
