<div class="container" [style.width]="'fit-content'" style=" margin-top: 50px;
margin-bottom: 50px; padding: 20px; background-color: white;">
    <div class="row">
      <div class="col-md-6">
        <div class="legend-container" [style.width]="chartDimensions[0]+'px'">
            <span class="graph-title">{{ graphTopTitle }}</span>
            <ng-container *ngIf="data.length > 4">
              <data-sorting-widget 
                (applySorting)="applySorting($event)"
                (shouldShowTotal)="shouldShowTotal($event)"
                style="text-align: right;"
              ></data-sorting-widget>
           </ng-container>
        </div>
        <ngx-charts-bar-vertical-stacked
          [view]="chartDimensions"
          [scheme]="colorScheme"
          [results]="data"
          [gradient]="gradient"
          [xAxis]="showXAxis"
          [yAxis]="showYAxis"
          [showXAxisLabel]="false"
          [showYAxisLabel]="showYAxisLabel"
          [xAxisLabel]="xAxisLabel"
          [yAxisLabel]="yAxisLabel"
          [tooltipDisabled]="true"
          (select)="onSelect($event)"
          [showDataLabel]="showDataLabel"
          [barPadding]="42"
          [yScaleMax]="100"
          [activeEntries]="[1]"
          [wrapTicks]="true"
          [showGridLines]="true"
          [roundDomains]="true"
          [rotateXAxisTicks]="false"
          [trimXAxisTicks]="true"
          [maxXAxisTickLength]="12"
          [dataLabelFormatting]="formatDataLabel"
          [xAxisTickFormatting]="formatXAxisTickLabel"
          [legendPosition]="legendPosition"
          [legend]="false"
          [noBarWhenZero]="false"
        >
        </ngx-charts-bar-vertical-stacked>
        <div class="legend-container" [style.width]="chartDimensions[0]+'px'">
            <div class="legend-box"></div>
            <span class="legend-text">{{xAxisLabel}}</span>
        </div>
      </div>
    </div>
  </div>
  