//#TODO remove unused component

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-deepdive-assessment-results',
  templateUrl: './deepdive-assessment-results.component.html',
  styleUrls: ['./deepdive-assessment-results.component.scss'],
})
export class DeepdiveAssessmentResultsComponent implements OnInit {
  @Input() groupDeepdiveQuestions :any;
  @Output() selectedAssessmentTab = new EventEmitter<any>();
  @Output() selectDeepdiveResultsTab = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  onNavChange(event: any) {
    this.selectedAssessmentTab.emit(event?.nextId);
  }

  selectingDeepdiveTab(tab:any){
    this.selectDeepdiveResultsTab.emit(tab);
  }
}
