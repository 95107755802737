<div class="notes-layout">
  <form [formGroup]="notesForm">
    <div formArrayName="notesSections" class="row-regulations">
      <ng-container
        *ngFor="
          let eachNote of noteSections.controls;
          let index = index;
          last as isLast
        "
      >
        <ng-container *ngIf="type === 'PERFORMANCE' || type === 'GOALS'">
          <div class="notes-content-container">
            <div class="left-layout">
              <div [formGroupName]="index">
                <div class="d-flex justify-content-between">
                  <div>
                    <p class="deepdive-notes-title">
                      {{ getNotesTitle(type, breakEvenGoal.ID, index) }}
                    </p>
                    <div
                      class="goal-text"
                      *ngIf="type === 'GOALS' && breakEvenGoal.ID != '2'"
                    >
                      <p
                        class="deepdive-notes-title"
                        *ngIf="selectedType?.subTitles"
                      >
                        Voorstel doel
                      </p>
                      <span class="me-2 pe-1 goal-title">
                        {{ getSubTitleText(breakEvenGoal.ID) }}
                      </span>
                      <ng-container>
                        <input
                          class="mb-2"
                          *ngIf="!isPublishView"
                          formControlName="NUMBER"
                          onlynumber
                          (blur)="onAutoSave($event, eachNote, index)"
                          [ngClass]="{
                            errorInputBorder:
                              (eachNote.get('NUMBER')?.value > 100 ||
                                eachNote.get('NUMBER')?.value < 1) &&
                              eachNote.get('NUMBER')?.touched
                          }"
                        />
                        <span *ngIf="isPublishView" class="number-text">
                          {{ eachNote.get("NUMBER")?.value }}
                        </span>
                        <span class="number-text">
                          {{ unit ?? "" }}
                        </span>
                      </ng-container>
                      <ng-container *ngIf="breakEvenGoal.ID == '1'">
                        <span class="ms-2 ps-1 goal-title">
                          hernieuwbare energie te gebruiken in
                          <span class="number-text"> 2030 </span>
                          .
                        </span>
                      </ng-container>
                      <ng-container *ngIf="breakEvenGoal.ID == '6'">
                        <span class="ms-2 ps-1 goal-title">
                          in <span class="number-text"> 2030 </span> .
                        </span>
                      </ng-container>
                      <ng-container *ngIf="breakEvenGoal.ID == '7'">
                        <span class="ms-2 ps-1 goal-title">
                          afval als doel voor 2030 te stellen.
                        </span>
                      </ng-container>
                      <ng-container *ngIf="unit === '%'">
                        <div
                          class="d-flex pt-2"
                          id="error_message_goal"
                          [ngClass]="
                            (eachNote.get('NUMBER')?.value > 100 ||
                              eachNote.get('NUMBER')?.value < 1) &&
                            eachNote.get('NUMBER')?.touched
                              ? 'visible'
                              : 'invisible'
                          "
                        >
                          <img
                            src="../../../assets/images/exclamation-circle.svg"
                            alt="Error"
                            class="align-self-start"
                          />
                          <span
                            class="error px-1"
                            *ngIf="
                              eachNote.get('NUMBER')?.value > 100 ||
                              eachNote.get('NUMBER')?.value < 1
                            "
                            >Maximale toegestane waarde is 100</span
                          >
                        </div>
                      </ng-container>
                    </div>

                    <div
                      class="goal-text"
                      *ngIf="type === 'GOALS' && breakEvenGoal.ID == '2'"
                    >
                      <p
                        class="deepdive-notes-title"
                        *ngIf="selectedType?.subTitles && index == 0"
                      >
                        Titel doel
                      </p>
                      <p
                        class="deepdive-notes-title"
                        *ngIf="selectedType?.subTitles && index == 1"
                      >
                        Omschrijving doel
                      </p>
                    </div>

                    <span
                      [innerHTML]="eachNote?.get('TEXT')?.value"
                      *ngIf="isPublishView"
                      class="notes-description"
                    >
                    </span>
                  </div>
                </div>
                <!-- <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                  *ngIf="eachNote?.get('LOADER')?.value"
                ></span>
                {{}} -->
                <app-text-editor
                  *ngIf="!isPublishView"
                  (autoSaveContent)="onAutoSave($event, eachNote, index)"
                  id="{{ type }}_{{ index }}"
                  [editorFormGroup]="eachNote"
                  controlName="TEXT"
                ></app-text-editor>

                <!-- <div
                  class="add-buttons-block mt-4 mb-4 pb-3 d-flex justify-content-end"
                  *ngIf="!eachNote?.get('ADDED')?.value"
                >
                  <button
                    class="button-cancel bg-white border-0 me-4"
                    (click)="clearEditor(eachNote, index)"
                  >
                    Cancel
                  </button>
                  <button
                    class="button-primary-white ms-2"
                    (click)="addOrEditNotesSection(eachNote, index)"
                    [disabled]="
                      !eachNote?.get('TEXT')?.value ||
                      !eachNote?.valid ||
                      !eachNote?.get('NUMBER')?.valid ||
                      hasForbiddenWord(eachNote?.get('TEXT')?.value)
                    "
                  >
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      *ngIf="eachNote?.get('LOADER')?.value"
                    ></span>
                    <span *ngIf="!eachNote?.get('LOADER')?.value">{{
                      menuSelected?.dataKey === "edit" ? "Opslaan" : "Toevoegen"
                    }}</span>
                  </button>
                </div> -->
                <!-- <hr class="ruler" *ngIf="!isLast" /> -->
              </div>
            </div>
            <div class="right-layout">
              <div class="spacer"></div>
              <ng-container
                *ngIf="sectionCharts[chartNames[type]?.[breakEvenGoal.ID]?.[index]?.['HALF']] as chartDataV2"
              >
                <ng-container *ngIf="chartDataV2?.chartType">
                  <app-chart-v2
                    [chartData]="chartDataV2?.chartData"
                    [chartType]="chartDataV2?.chartType"
                  >
                  </app-chart-v2>
                </ng-container>
              </ng-container>
              <!-- <ng-container
                *ngIf="
                  utilService.getChartDataV2(type, breakEvenGoal.ID, index)
                "
              >

              
                <app-chart-v2
                  [chartData]="
                    utilService.getChartDataV2(type, breakEvenGoal.ID, index)
                      ?.chartData
                  "
                  [chartType]="
                    utilService.getChartDataV2(type, breakEvenGoal.ID, index)
                      ?.chartType
                  "
                >
                </app-chart-v2>
              </ng-container> -->
            </div>
          </div>

          <ng-container
            *ngIf="sectionCharts[chartNames[type]?.[breakEvenGoal.ID]?.[index]?.['FULL']] as chartDataV2"
          >
          <div style="flex-direction: row;">
            <ng-container *ngIf="chartDataV2?.chartType">
              <app-chart-v2
                [chartData]="chartDataV2?.chartData"
                [chartType]="chartDataV2?.chartType"
              >
              </app-chart-v2>
            </ng-container>

            <ng-container
              *ngIf="
                index == 1 &&
                breakEvenGoal.ID === 2 &&
                sectionCharts[
                  'WATER_REUSE_EFFICIENCY_PER_LOCATION'
                ] as chartDataV2x
              "
            >
              <ng-container *ngIf="chartDataV2x?.chartType">
                <app-chart-v2
                  [chartData]="chartDataV2x?.chartData"
                  [chartType]="chartDataV2x?.chartType"
                >
                </app-chart-v2>
              </ng-container>
            </ng-container>
          </div>

          </ng-container>

          <!-- <ng-container *ngIf="getFullChartData(type, breakEvenGoal.ID, index)">
            <app-chart-v2
              [chartData]="
                getFullChartData(type, breakEvenGoal.ID, index)?.chartData
              "
              [chartType]="
                getFullChartData(type, breakEvenGoal.ID, index)?.chartType
              "
            >
            </app-chart-v2>
          </ng-container> -->
        </ng-container>
        <!-- Action starts-->
        <ng-container *ngIf="type === 'ACTIONS'">
          <div class="notes-content-container">
            <div class="left-layout">
              <div [formGroupName]="index">
                <div class="d-flex justify-content-between">
                  <!-- Action title starts-->
                  <div
                    class="actions-text-section pb-2"
                    *ngIf="type === 'ACTIONS' && index % 2 === 0"
                  >
                    <span *ngIf="isPublishView" class="notes-title">
                      {{ index + 1 }}. {{ eachNote?.get("NOTETITLE")?.value }}
                    </span>
                    <input
                      *ngIf="!isPublishView"
                      formControlName="NOTETITLE"
                      class="actions-title"
                      placeholder="Titel: actie you can take"
                      (blur)="onAutoSave($event, eachNote, index)"
                    />
                    <a
                      *ngIf="noteSections.value.length !== 1 && !isPublishView"
                      class="cta-text"
                      (click)="removeRegulationSection(index)"
                      >Verwijder deze text</a
                    >
                    <!-- <span *ngIf="eachNote?.get('ADDED')?.value">{{
                      eachNote?.get("NOTETITLE")?.value
                    }}</span> -->
                  </div>
                  <!-- Action title ends-->

                  <!-- Adding static title only for the actions tab section-->
                  <!-- <ng-container *ngIf="index % 2 !== 0">
                    <p class="deepdive-notes-title">Beschrijving actie</p>
                    <span
                      [innerHTML]="eachNote?.get('TEXT')?.value"
                      *ngIf="eachNote?.get('ADDED')?.value"
                    >
                    </span>
                    <div *ngIf="eachNote?.get('ADDED')?.value">
                      <app-context-menu
                        [menuOptions]="menuOptions"
                        (menuOptionSelected)="
                          onMenuOptionSelected($event, eachNote, index)
                        "
                      ></app-context-menu>
                    </div>
                  </ng-container> -->
                </div>
                <ng-container *ngIf="index % 2 !== 0">
                  <span
                    [innerHTML]="eachNote?.get('TEXT')?.value"
                    class="notes-description"
                    *ngIf="isPublishView"
                  >
                  </span>
                  <app-text-editor
                    *ngIf="!isPublishView"
                    (autoSaveContent)="onAutoSave($event, eachNote, index)"
                    id="{{ type }}_{{ index }}"
                    [editorFormGroup]="eachNote"
                    controlName="TEXT"
                  ></app-text-editor>
                </ng-container>
                <!-- <div
                  class="add-buttons-block mt-4 mb-4 pb-3 d-flex justify-content-end"
                >
                  <button
                    class="button-cancel bg-white border-0 me-4"
                    (click)="clearEditor(eachNote, index)"
                  >
                    Cancel
                  </button>
                  <button
                    class="button-primary-white ms-2"
                    (click)="addOrEditNotesSection(eachNote, index)"
                    [disabled]="
                      !eachNote?.get('TEXT')?.value ||
                      !eachNote?.valid ||
                      !eachNote?.get('NUMBER')?.valid ||
                      hasForbiddenWord(eachNote?.get('TEXT')?.value)
                    "
                  >
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      *ngIf="eachNote?.get('LOADER')?.value"
                    ></span>
                    <span *ngIf="!eachNote?.get('LOADER')?.value">{{
                      menuSelected?.dataKey === "edit" ? "Opslaan" : "Toevoegen"
                    }}</span>
                  </button>
                </div> -->
                <!-- <hr class="ruler" *ngIf="!isLast" /> -->
              </div>
            </div>
            <div class="right-layout">
              <div [formGroupName]="index">
                <div class="d-flex justify-content-between">
                  <!-- Action title starts-->
                  <div
                    class="actions-text-section ms-5 pb-2"
                    *ngIf="type === 'ACTIONS' && index % 2 !== 0"
                  >
                    <span *ngIf="isPublishView" class="notes-title">
                      {{ index + 1 }}. {{ eachNote?.get("NOTETITLE")?.value }}
                    </span>

                    <input
                      *ngIf="!isPublishView"
                      formControlName="NOTETITLE"
                      class="actions-title"
                      placeholder="Titel: actie you can take"
                      (blur)="onAutoSave($event, eachNote, index)"
                    />
                    <a
                      *ngIf="noteSections.value.length !== 1 && !isPublishView"
                      class="cta-text"
                      (click)="removeRegulationSection(index)"
                      >Verwijder deze text</a
                    >
                  </div>
                  <!-- Action title ends-->
                </div>
                <ng-container *ngIf="index % 2 === 0">
                  <span
                    [innerHTML]="eachNote?.get('TEXT')?.value"
                    *ngIf="isPublishView"
                    class="notes-description"
                  >
                  </span>
                  <app-text-editor
                    (autoSaveContent)="onAutoSave($event, eachNote, index)"
                    id="{{ type }}_{{ index }}"
                    [editorFormGroup]="eachNote"
                    controlName="TEXT"
                    *ngIf="!isPublishView"
                  ></app-text-editor>
                </ng-container>

                <!-- <div
                  class="add-buttons-block mt-4 mb-4 pb-3 d-flex justify-content-end"
                  *ngIf="!eachNote?.get('ADDED')?.value"
                >
                  <button
                    class="button-cancel bg-white border-0 me-4"
                    (click)="clearEditor(eachNote, index)"
                  >
                    Cancel
                  </button>
                  <button
                    class="button-primary-white ms-2"
                    (click)="addOrEditNotesSection(eachNote, index)"
                    [disabled]="
                      !eachNote?.get('TEXT')?.value ||
                      !eachNote?.valid ||
                      !eachNote?.get('NUMBER')?.valid ||
                      hasForbiddenWord(eachNote?.get('TEXT')?.value)
                    "
                  >
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      *ngIf="eachNote?.get('LOADER')?.value"
                    ></span>
                    <span *ngIf="!eachNote?.get('LOADER')?.value">{{
                      menuSelected?.dataKey === "edit" ? "Opslaan" : "Toevoegen"
                    }}</span>
                  </button>
                </div> -->
                <!-- <hr class="ruler" *ngIf="!isLast" /> -->
              </div>
            </div>
          </div>
          <div
            *ngIf="type === 'ACTIONS' && !isLast"
            style="flex: 1"
            class="horizontal-ruler"
          ></div>
          <ng-container *ngIf="getFullChartData(type, breakEvenGoal.ID, index)">
            <app-chart-v2
              [chartData]="
                getFullChartData(type, breakEvenGoal.ID, index)?.chartData
              "
              [chartType]="
                getFullChartData(type, breakEvenGoal.ID, index)?.chartType
              "
            >
            </app-chart-v2>
          </ng-container>
        </ng-container>

        <!-- Action Ends-->
        <div *ngIf="type === 'REGULATIONS'" class="col-md-6">
          <div class="regulation-item">
            <!--      *ngIf="!eachNote?.get('ADDED')?.value" -->
            <span
              [innerHTML]="eachNote?.get('TEXT')?.value"
              *ngIf="isPublishView"
              class="notes-description"
            >
            </span>
            <app-text-editor
              *ngIf="!isPublishView"
              (autoSaveContent)="onAutoSave($event, eachNote, index)"
              id="{{ type }}_{{ index }}"
              [editorFormGroup]="eachNote"
              controlName="TEXT"
            ></app-text-editor>
            <a
              *ngIf="noteSections.value.length !== 1 && !isPublishView"
              class="cta-text"
              (click)="removeRegulationSection(index)"
              >Verwijder deze text</a
            >
          </div>
        </div>
      </ng-container>
    </div>

    <div
      class="add-cta"
      *ngIf="(type === 'REGULATIONS' || type === 'ACTIONS') && !isPublishView"
    >
      <div class="horizontal-ruler"></div>
      <button
        class="button-primary-gray"
        (click)="addRegulationSection()"
        [disabled]="noteSections.value.length >= 4"
      >
        <img
          src="../../../assets/images/down-arrow.svg"
          alt="down arrow icon"
        />
        <span class="cta-text">
          Text toevoegen (
          {{ noteSections.value.length }}
          / 4)
        </span>
      </button>
    </div>
  </form>
</div>
