import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import {
  ToastService,
  TOAST_STATE,
} from 'src/app/shared/services/toast.service';
@Component({
  selector: 'checkbox-group',
  templateUrl: './checkbox-group.component.html',
  styleUrls: ['./checkbox-group.component.scss'],
})
export class CheckboxGroupComponent implements OnInit {
  @Input() itemsList!: any;
  @Input() applyBoxShadow: any;

  @Output() onToggleItem = new EventEmitter<string>();

  ngOnInit(): void {}

  constructor(private toastService: ToastService) {}

  toggleCheckbox(event: Event, item: any): void {
    if (item.DEEPDIVE_STATE !== 'DEEPDIVE_NOT_STARTED') {
      event.preventDefault();
      this.toastService.showToast(
        TOAST_STATE.danger,
        'Kan het assessment niet deselecteren. Klant is assessment al gestart'
      );
      setTimeout(() => this.toastService.dismissToast(), 2000);

      return;
    }

    this.onToggleItem.emit(item);
  }
}
