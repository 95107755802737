export const DEEP_DIVE_STATUS = [
    {
        ID: 'PUBLISHED',
        NAME: 'Gepubliceerd'
    },
    {
        ID: 'ACTION_REQUIRED',
        NAME: 'Actie vereist'
    },
    {
        ID: 'IN_PROGRESS',
        NAME: 'Gestart'
    },
    {
        ID: 'NOT_YET_STARTED',
        NAME: "Nog niet gestart"
    },
];