import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  NgbCalendar,
  NgbDateAdapter,
  NgbDateParserFormatter,
} from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import { cloneDeep, every, find, set } from 'lodash';
import { Observable, take } from 'rxjs';
import { UtilService } from 'src/app/shared/services/util.service';
import { DeepdiveAssessmentActions } from 'src/app/store/actions';
import { IAppState } from 'src/app/store/state';
import { deepdiveGroup8 } from 'src/app/shared/deepdive-group8-init';
import { getFormGroupStyle, parseRefYear, ENERGY_GRADES, getLabel } from './utils';


@Component({
  selector: 'app-deepdive-table-questions',
  templateUrl: './deepdive-table-questions.component.html',
  styleUrls: ['./deepdive-table-questions.component.scss'],
})
export class DeepdiveTableQuestionsComponent implements OnInit, OnChanges {
  @Input() deepDiveQuestions!: any[];
  @Input() selectedTab!: any;
  @Input() groupDeepDiveDetails: any = {};
  @Input() tabsLength!: number;
  @Input() showDescription: boolean = true;
  @Input() deepdiveStatus!: any;
  @Input() refYear: any;

  storeValue: any;
  questionsForm!: FormGroup;
  dropDownForm!: FormGroup;
  dropDownOptions: any;
  showLoader: boolean = false;
  formBuilder = inject(FormBuilder);
  utilService = inject(UtilService);
  private ngbCalendar = inject(NgbCalendar);
  private dateAdapter = inject(NgbDateAdapter);
  private dateFormatter = inject(NgbDateParserFormatter);
  @Output() onSelectingPreviousTab = new EventEmitter<any>();
  @Output() onSubmittingGroupAnswers = new EventEmitter<any>();
  state!: Observable<IAppState>;
  allDependentQuestionsHidden: boolean = true;
  hideEnergyLabelCQuestion: boolean = true;
  initialDeepDiveQuestions: any;
  private cdRef = inject(ChangeDetectorRef);
  selectedEnergyLabel: any;
  constructor(private store: Store<IAppState>) { }

  ngOnInit(): void {
    this.store.subscribe((state) => {
      this.storeValue = state;
    });
    this.initForm();
    this.initialDeepDiveQuestions = this.deepDiveQuestions;
    this.calculateAllDependentQuestionsHidden();
    this.parseRefYear();
  }

  getFormGroupStyle(value: any): any {
    return getFormGroupStyle(value);
  }

  parseRefYear() {
    this.deepDiveQuestions = parseRefYear(this.deepDiveQuestions, this.refYear);
  }

  get today() {
    return this.dateFormatter.format(this.ngbCalendar.getToday())!;
  }

  getLabel(value: any){
    return getLabel(value, this.refYear);
  }

  ngOnChanges() {
    this.deepDiveQuestions = this.deepDiveQuestions;
    this.initForm();
  }

  returnControl(array: any, label: any, value: any) {
    array?.map(
      (eachLabelValue: any) =>
        this.formBuilder.control(
          eachLabelValue?.ANSWER
            ? eachLabelValue?.LABEL === label
              ? eachLabelValue?.ANSWER
              : ''
            : ''
        ),
      [Validators.max(value?.MAX_VALUE), Validators.min(value?.MIN_VALUE)]
    );
  }

  enableEnergyLabelRelatedQuestion(selectedOption: any, currentQuestion: any){
    let selectedLabel = selectedOption?.selection?.NAME;
    let isDisabled = ENERGY_GRADES.indexOf(selectedLabel) !== -1;
    this.selectedEnergyLabel = selectedLabel;
    this.deepDiveQuestions.map((question, index) => {
      currentQuestion?.RELATED_QUESTIONS?.filter((id: any) => {
        if (Number(id) === Number(question?.ID)) {
          question.DISABLED = isDisabled;
          //Disabling the answers for the dependent question if the answer is NO
          this.getArrayControls()
            ?.at(index)
            ?.get('DISABLED')
            ?.setValue(question.DISABLED);
          //clearing the answers for the dependent question if the answer is NO
          if (question?.TYPE === 'MULTI_NUMBER') {
            question?.ANSWER?.ANSWERS?.map((eachValue: any, i: any) => {
              this.getAnswerLabelControls(index)[i].get('ANSWER')?.reset();
              this.getAnswerLabelControls(index)[i].get('ANSWER')?.setValue('');
            });
          }
          this.getArrayControls()?.at(index)?.get('ANSWERS')?.reset();
          this.getArrayControls()?.at(index)?.get('ANSWERS')?.setValue('');
        }
      });
    });
  }
  onOptionSelected(selectedOption: any, currentQuestion: any) {
    this.getQuestionsFormArray();
    this.enableEnergyLabelRelatedQuestion(selectedOption, currentQuestion);
  }

  initForm() {
    const deepDiveQuestions = this.deepDiveQuestions;
    if (!this.deepDiveQuestions?.length) {
      return;
    }
    this.buildDropDown();
    this.questionsForm = this.formBuilder.group({
      array: this.formBuilder.array(
        deepDiveQuestions?.map((value: any) =>
          this.formBuilder.group({
            ID: this.formBuilder.control(value?.ID ?? ''),
            TEXT: this.formBuilder.control(value?.TEXT ?? ''),
            TYPE: this.formBuilder.control(value?.TYPE ?? ''),
            answerlabel: this.buildAnswerLabel(value),
            DISABLED: this.formBuilder.control(value?.DISABLED ?? ''),
            UNIT: this.formBuilder.control(value?.UNIT ?? ''),
            ANSWERS: this.getAnswerControl(value),
          })
        )
      ),
    });

    if (this.deepdiveStatus === 'DEEPDIVE_SUBMITTED') {
      this.questionsForm?.disable();
      return;
    }

    this.questionsForm.valueChanges.subscribe((res) => {
      const formArray: FormArray = this.questionsForm.get('array') as FormArray;
      this.store.dispatch(
        DeepdiveAssessmentActions.saveGroupDeepDiveAssessmentAnswers({
          value: formArray?.value,
          groupId: this.selectedTab?.ID,
        })
      );
    });
  }

  buildDropDown() {
    let dropDownQuestion = this.deepDiveQuestions?.filter((question) => question.TYPE === "DROPDOWN")[0];
    if (dropDownQuestion) {
      let dropDownAnswer = dropDownQuestion?.ANSWER?.ANSWERS[0]?.ANSWER ?? '';
      this.dropDownForm = this.formBuilder.group({
        dropDown: this.formBuilder.control(
          { value: dropDownAnswer , disabled: false },
        ),
      });
      this.dropDownOptions = dropDownQuestion?.ANSWER_LABELS?.map((label: string) => ({
        NAME: label,
        isSelected: dropDownAnswer === label,
      }))
    }
  }
  getFormatedValue(value: any) {
    if (value?.TYPE === 'DATE') {
      let date = this.utilService.toDate(
        value?.ANSWER?.ANSWERS?.[0]?.ANSWER
      ) ?? null;
      return date;
    }
    return value?.ANSWER?.ANSWERS?.[0]?.ANSWER ?? '';
  }

  getValidators(value: any) {
    if (value?.TYPE === 'TEXT' || value?.TYPE === 'TEXT_AREA') {
      return [
        Validators.maxLength(value?.MAX_VALUE),
        Validators.minLength(value?.MIN_VALUE),
      ];
    }
    return [
      Validators.max(value?.MAX_VALUE),
      Validators.min(value?.MIN_VALUE),
    ]
  }


  getAnswerControl(value: any) {
    let formatedValue = this.getFormatedValue(value)
    let validators = this.getValidators(value);
    return this.formBuilder.control(
      formatedValue,
      validators,
    )
  }

  buildAnswerLabel(value: any) {
    if (value?.TYPE === 'MULTI_NUMBER') {
      return this.formBuilder.array(value?.ANSWER_LABELS?.map((eachValue: any) =>
        this.formBuilder.group({
          LABEL: this.formBuilder.control(eachValue ?? ''),
          ANSWER: this.formBuilder.control(
            find(value?.ANSWER?.ANSWERS, ['LABEL', eachValue])?.ANSWER ?? '',
            [
              Validators.max(value?.MAX_VALUE),
              Validators.min(value?.MIN_VALUE),
            ]
          ),
        })
      ))
    } else if (value?.TYPE === 'CHECKBOX') {
      return this.formBuilder.array(value?.ANSWER_LABELS?.map((eachValue: any) =>
        this.formBuilder.group({
          LABEL: this.formBuilder.control(eachValue ?? ''),
          ANSWER: this.formBuilder.control(
            find(value?.ANSWER?.ANSWERS, ['LABEL', eachValue])?.ANSWER === 'true' ?? '',
          ),
        })
      ))
    } else {
      return false;
    }
  }

  getArrayControls() {
    return (this.questionsForm.get('array') as FormArray).controls;
  }

  getAnswerLabelControls(index: any) {
    return (
      (this.questionsForm.get('array') as FormArray)
      ['at'](index)
        .get('answerlabel') as FormArray
    ).controls;
  }

  getQuestionsFormArray() {
    const formArray: FormArray = this.questionsForm.get('array') as FormArray;

    let answers = cloneDeep(formArray?.value);
    
    answers = answers?.map((question: any)=>{
      if(Number(question?.ID) === 27){
        question.ANSWERS = this.selectedEnergyLabel;
      }
      return question;
    })
    return answers;
  }

  clearDate(index: number, controlName: any) {
    this.getArrayControls()?.at(index)?.get(controlName)?.reset();
    this.getArrayControls()?.at(index)?.get(controlName)?.setValue('');
  }

  backSpace(event: any, index: number, controlName: any) {
    if (event.keyCode == 8 || event.keyCode == 46) {
      this.clearDate(index, controlName);
    }
  }

  getPreviousTab() {
    this.onSelectingPreviousTab.emit();
  }

  //Destroying the toast
  ngOnDestroy() {
    const formArray: FormArray = this.questionsForm?.get('array') as FormArray;
    formArray.clear();
  }

  //Disabling or enabling the dependent questions based on radio button
  onAnsweringRadioButton(relatedQuestionArray: any, answer: any) {
    this.deepDiveQuestions.map((question, index) => {
      relatedQuestionArray.filter((id: any) => {
        if (Number(id) === Number(question?.ID)) {
          question.DISABLED = answer === 'Ja' ? false : true;
          //Disabling the answers for the dependent question if the answer is NO
          this.getArrayControls()
            ?.at(index)
            ?.get('DISABLED')
            ?.setValue(question.DISABLED);
          //clearing the answers for the dependent question if the answer is NO
          if (question?.TYPE === 'MULTI_NUMBER') {
            question?.ANSWER?.ANSWERS?.map((eachValue: any, i: any) => {
              this.getAnswerLabelControls(index)[i].get('ANSWER')?.reset();
              this.getAnswerLabelControls(index)[i].get('ANSWER')?.setValue('');
            });
          }
          this.getArrayControls()?.at(index)?.get('ANSWERS')?.reset();
          this.getArrayControls()?.at(index)?.get('ANSWERS')?.setValue('');
        }
      });
    });
  }

  onAnsweringCheckbox(value: any, idx: any) {
    let currentAnswers = value?.ANSWER?.ANSWERS;
    if (currentAnswers == undefined) {
      let currentAnswer = deepdiveGroup8.find((element: { ID: string; }) => element.ID === value.ID)
      currentAnswers = currentAnswer?.ANSWER?.ANSWERS;
    }
    currentAnswers[idx].ANSWER = currentAnswers[idx].ANSWER === 'true' ? 'false' : 'true';

    this.deepDiveQuestions.map((question, index) => {
      value?.RELATED_QUESTIONS?.filter((id: any) => {
        if (Number(id) === Number(question?.ID)) {
          question.DISABLED = !this.calculateShouldShowDependentQuestions(currentAnswers);
          //Disabling the answers for the dependent question if a checkbox is checked
          this.getArrayControls()
            ?.at(index)
            ?.get('DISABLED')
            ?.setValue(question.DISABLED);
          this.getArrayControls()?.at(index)?.get('ANSWERS')?.reset();
          this.getArrayControls()?.at(index)?.get('ANSWERS')?.setValue('');
        }
      });
    });

    this.calculateAllDependentQuestionsHidden();
  }

  calculateShouldShowDependentQuestions(currentAnswers: any,) {
    return currentAnswers.find((element: { ANSWER: string; }) => element.ANSWER === 'true') ? true : false;
  }

  calculateAllDependentQuestionsHidden() {
    this.allDependentQuestionsHidden = this.deepDiveQuestions.find((value: { DISABLED: boolean; ID: string; }) => value.DISABLED == false && Number(value.ID) > 55 && Number(value.ID) < 68) ? false : true;
  }

}
