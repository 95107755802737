export const ENERGY_GRADES = ['A+++++', 'A++++', 'A+++', 'A++', 'A+', 'A', 'B'];

export function getFormGroupStyle(value: any): any {
  if (value?.LOCATION !== "GENERIC" && value.LOCATION_LIST.length > 1) {
    return ({
      border: 'none',
      padding: 0,
      // display: 'none'
    })
  }

  return {
    border: (value?.ID > 42 && value?.ID < 68) || (value?.ID >= 13 && value?.ID <= 22) || (value?.ID == 28 && value?.DISABLED) ? 'none' : '',
    padding: (value?.ID > 42 && value?.ID < 68) || (value?.ID >= 13 && value?.ID <= 22) || (value?.ID == 28 && value?.DISABLED) ? '0' : '',
    display: ((value?.ID > 55 && value?.ID < 68) && value?.DISABLED) ? 'none' : ''
  };
}


export function parseRefYear(deepDiveQuestions: any, year: any) {
  const searchRegExp = /<ref-year>/gi;
  const dDQuestions = JSON.stringify(deepDiveQuestions);
  const result = dDQuestions.replace(searchRegExp, year);
  return JSON.parse(result);
}


export function getLabel(value: any, refYear: string) {
  if (value?.ID >= 13 && value?.ID <= 19) {
    return value?.TEXT?.replace('Wat is het aantal wagens in uw wagenpark? - ', '')?.replace(' (0 indien n.v.t.)', '');
  } else if (value?.ID >= 20 && value?.ID <= 22) {
    return value?.TEXT?.replace(`Wat was uw brandstofverbruik (liter) van uw wagenpark in ${refYear}? - `, '')?.replace(' (0 indien n.v.t.)', '');
  } else if (value?.ID > 42 && value?.ID < 68) {
    return value?.TEXT;
  }
  return '';
}


export function shouldRenderCustomLabel(value: any) {
  const ID = Number(value?.ID);
  return (ID === 13 || ID === 20 || ID === 43) && !value.PREVIOUSLY_RENDERED;
}

export function getCustomLabel(value: any, refYear: any) {
  const ID = Number(value?.ID);
  switch (ID) {
    case 43:
      return "1. Vink hieronder aan welke afvalstromen u heeft binnen uw bedrijf:";
    case 13:
      return "2. Wat is het aantal wagens in uw wagenpark? Wat is het aantal wagens in uw wagenpark? (0 indien n.v.t.)"
    case 20:
      return `3. Wat was uw brandstofverbruik (liter) van uw wagenpark in ${refYear}? (0 indien n.v.t.)`
    default:
      return '';
  }
}

export function getColumnHeaders(questions: any, range: any) {
  const headers: any = [];
  if (questions?.length) {
    questions.forEach((question: any) => {
      if (question?.ID >= range[0] && question?.ID <= range[1]) {
        headers.push(question.TEXT);
      }
    })
  }
  let set = new Set(headers);

  return Array.from(set);
}

export function getRowHeaders(question: any) {

}



function rowData(questionsData: any, refYear:any) {
  const originalQuestions: any = [];

  questionsData.forEach((question: any) => {
    question.LOCATION_LIST.forEach((location: any) => {
      const questionObj = {
        ...question,
        ...location,
      }
      delete questionObj.LOCATION_LIST;
      originalQuestions.push(questionObj);
    })
  });

  const locationBasedQuestions: any = {};


  originalQuestions.forEach((question: any) => {
    const location = question.LOCATION;
    if (!locationBasedQuestions[location]) {
      locationBasedQuestions[location] = [question];
    } else {
      locationBasedQuestions[location].push(question);
    }

  })

  const questionsArray = Object.values(locationBasedQuestions);
  let formattedRow: any = []
  questionsArray.forEach((row: any) => {
    let newRow: any = {};
    newRow.header = row[0].LOCATION;
    row.forEach((col: any) => {
      let columnText = getLabel(col, refYear).replace('\t','');
      newRow[columnText] = col;
    })

    formattedRow?.push(newRow);
  })

  return Object.values(formattedRow);
}

export function createQuestionTableTab10(questionsData: any, year: any) {
  // const columnHeaders = getColumnHeaders(questionsData, [43, 54]);
  const checkboxTableTitle = "1. Vink hieronder aan welke afvalstromen u heeft binnen uw bedrijf:";
  const checkboxTableRows = questionsData.filter((question: any) => question?.ID >= 43 && question?.ID <= 54);
  const numberTableTitle = `2. Vul in hoeveel kilo u van de onderstaande afvalstromen heeft genereert in ${year}. Indien niet bekend kunt u een schatting maken.`;
  const numberTableRows = questionsData.filter((question: any) => question?.ID >= 56 && question?.ID <= 67);
  const remainingQuestion = questionsData.filter((question: any) => question?.ID == 55);
  // const tableRows = rowData(checkboxTableRows);

  return [
    {
      title: checkboxTableTitle,
      tableHeadings: getColumnHeaders(questionsData, [43, 54]),
      tableData: rowData(checkboxTableRows, year),
    }, {
      ...remainingQuestion
    }, {
      title: numberTableTitle,
      tableHeadings: getColumnHeaders(questionsData, [56, 67]),
      tableData: rowData(numberTableRows, year),
    }
  ]
}


export function createQuestionTableTab04(questionsData: any, year: any) {
  // const columnHeaders = getColumnHeaders(questionsData, [43, 54]);
  const wagenTableTitle = "2. Wat is het aantal wagens in uw wagenpark? Wat is het aantal wagens in uw wagenpark? (0 indien n.v.t.)";
  const wagenTableRows = questionsData.filter((question: any) => question?.ID >= 13 && question?.ID <= 19);
  const fuelTableTitle = `3. Wat was uw brandstofverbruik (liter) van uw wagenpark in ${year}? (0 indien n.v.t.)`;
  const fuelTableRows = questionsData.filter((question: any) => question?.ID >= 20 && question?.ID <= 22);
  const remainingQuestion = questionsData.filter((question: any) => question?.ID == 23);
  // const tableRows = rowData(checkboxTableRows);
  const wagenTableHeaders = getColumnHeaders(questionsData, [13, 19])?.map((heading: any) => {
    return heading?.replace('Wat is het aantal wagens in uw wagenpark? - ', '')?.replace(' (0 indien n.v.t.)', '').replace('\t','')
  })

  const feulTableHeaders = getColumnHeaders(questionsData, [20, 22])?.map((heading: any) => {
    return heading?.replace(`Wat was uw brandstofverbruik (liter) van uw wagenpark in ${year}? - `, '')?.replace(' (0 indien n.v.t.)', '');
  })
  return [
    questionsData[0],
    {
      title: wagenTableTitle,
      tableHeadings: wagenTableHeaders,
      tableData: rowData(wagenTableRows, year),
    }, {
      title: fuelTableTitle,
      tableHeadings: feulTableHeaders,
      tableData: rowData(fuelTableRows, year),
    },
    {
      ...remainingQuestion
    }
  ]
}
