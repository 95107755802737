import { Component, EventEmitter, Input, OnInit, Output, ElementRef, ViewChild } from '@angular/core';
import { set } from 'lodash';

@Component({
  selector: 'app-risk-scan-goals',
  templateUrl: './risk-scan-goals.component.html',
  styleUrls: ['./risk-scan-goals.component.scss'],
})
export class RiskScanGoalsComponent implements OnInit {
  @Input() begoals!: any[];
  @Output() onSelectedBeGoal = new EventEmitter<any>();
  @ViewChild('begoalsList', { static: false }) begoalsListRef!: ElementRef;
  offset: any;
  topDistance: any;

  ngAfterViewInit() {
    this.measureDistanceFromTop();
  }

  measureDistanceFromTop() {
    const element = this.begoalsListRef.nativeElement as HTMLElement;
    const elementRect = element.getBoundingClientRect();
    const screenHeight = window.innerHeight;
    const offsetPercentage = 0.1; // 10% of the screen height
    
    const dynamicOffset = screenHeight * offsetPercentage;

    this.topDistance = elementRect.top - dynamicOffset;
  }

  scrollToMiddle() {
    window.scrollTo({ top: this.topDistance, behavior: 'smooth' });
  }

  constructor() { }

  ngOnInit(): void {
    //Sorting the BE GOALS based on the ID
    this.begoals = this.begoals?.sort(
      (a: any, b: any) => Number(a.ID) - Number(b.ID)
    );
  }



  onSelectingBEGoal(beGoal: Object) {
    this.begoals.forEach((goal: any) => delete goal?.isSelected);
    set(beGoal, 'isSelected', true);
    this.onSelectedBeGoal.emit(beGoal);
    this.scrollToMiddle();
  }
}
