export const DESCRIPTION_TEXT: { [key: number]: string } = {
    1: "Hoe u heeft gescoord op duurzaamheidsdoel Energie in het jaar {year} op basis van de gegevens die u hebt aangeleverd.",
    2: "Hoe u heeft gescoord op duurzaamheidsdoel Water in het jaar {year} op basis van de gegevens die u hebt aangeleverd.",
    6: "Hoe u heeft gescoord op duurzaamheidsdoel Broeikasgasemissies in het jaar {year} op basis van de gegevens die u hebt aangeleverd.",
    7: "Hoe u heeft gescoord op duurzaamheidsdoel Afval in het jaar {year} op basis van de gegevens die u hebt aangeleverd."
}

export const getDescriptionText = (year: string, beGoalId: number): string => {
    return DESCRIPTION_TEXT[beGoalId]?.replace('{year}', year);
}

export const getNotesSectionTitle = (noteType: string, beGoalId: number): string => {
    switch (noteType) {
        case 'PERFORMANCE':
        return 'Hoe u er nu voorstaat';
        case 'REGULATIONS':
            return 'Waarom u actie zou moeten ondernemen'
        case 'GOALS':
            return 'Welke acties u zou kunnen ondernemen'
        default:
            return '';
    }
}

export const getNotesSectionDescription = (noteType: string, beGoalId: number): string => {
    switch (noteType) {
        case 'REGULATIONS':
            return 'Overheden en verschillende bedrijven hebben zich al openlijk gecommitteerd aan 100% duurzame energieopwek in 2050. Dit gaat ook impact hebben op uw organisatie. Op de korte termijn voorzien wij al het onderstannde.'
        case 'GOALS':
            return 'In dit gedeelte beschrijven we in detail uw doeleinden en de acties die u kunt nemen om uw organisatie Future Fit te maken.'
        default:
            return '';
    }
}

export function incrementLastElementX(arr:any, commentId: any): any {
    // Find the object with key commentId
    const objIndex = arr.findIndex((obj:any) => obj.hasOwnProperty(commentId));
    
    // If object with key commentId is found
    if (objIndex !== -1) {
        // Increment the last element of the array
        const lastElement = arr[objIndex][commentId].slice(-1)[0]; // Get the last element
        const incrementedLastElement = lastElement + 1;
        
        // Add the incremented number to the array
        arr[objIndex][commentId].push(incrementedLastElement);
    }
    return arr;
}

export function incrementLastElement(arr: { [key: string]: number[] }[], noteCommentId: number): { [key: string]: number[] }[] {
    const objIndex = arr.findIndex(obj => obj.hasOwnProperty(noteCommentId));
    
    if (objIndex !== -1) {
        const lastElement = arr[objIndex][noteCommentId].slice(-1)[0];
        const incrementedLastElement = lastElement + 1;
        
        arr[objIndex][noteCommentId.toString()].push(incrementedLastElement);
        
        // Return a new array with the updated element to trigger change detection
        return [...arr];
    }
    
    // If no object with the given key is found, return the original array
    return arr;
}
