import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  modal: any;
  instance: any;
  constructor(private ngbModal: NgbModal) {}

  open(content: any, config?: any) {
    this.modal = this.ngbModal.open(content, config);
    this.instance = this.modal._windowCmptRef.instance;
    setTimeout(() => {
      if (config && config.windowClass) {
        this.instance.windowClass = `custom-show ${config.windowClass}`;
      } else {
        this.instance.windowClass = `custom-show`;
      }
    }, 10);

    return this.modal;
  }

  closeModal() {
    const fx = this.modal._removeModalElements.bind(this.modal);
    this.modal._removeModalElements = () => {
      this.instance.windowClass = '';
      setTimeout(fx, 0);
    };
  }
}
