<div
  class="question-block mb-2"
  *ngFor="let eachQuestion of accordionData; let i = index"
>
  <ngb-accordion [closeOthers]="true" *ngIf="eachQuestion?.TOOLTIP">
    <ngb-panel class="question mb-4" id="{{ i + 1 }}">
      <ng-template ngbPanelTitle>
        <markdown>
          <p class="m-0 pe-3">{{ i + 1 }}. {{ eachQuestion?.TEXT }}</p>
        </markdown>
      </ng-template>
      <ng-template ngbPanelContent>
        <markdown>
          <p>
            {{ eachQuestion?.TOOLTIP }}
          </p>
        </markdown>
      </ng-template>
    </ngb-panel>
  </ngb-accordion>
  <div class="question" *ngIf="!eachQuestion?.TOOLTIP">
    <markdown>
       <p>{{ i + 1 }}. {{ eachQuestion?.TEXT }}</p>
    </markdown>
  </div>
  <div class="d-flex radio align-items-center mt-3">
    <p class="me-5 mb-0">
      <input
        type="radio"
        name="answer_{{ i }}"
        id="ja_{{ i }}"
        (change)="onAnswering(eachQuestion, true)"
        [checked]="eachQuestion?.ANSWER === 'true'"
        [disabled]="disabled"
      />
      <label for="ja_{{ i }}" class="answer">Ja</label>
    </p>
    <p class="mb-0">
      <input
        type="radio"
        name="answer_{{ i }}"
        id="nee_{{ i }}"
        (change)="onAnswering(eachQuestion, false)"
        [checked]="eachQuestion?.ANSWER === 'false'"
        [disabled]="disabled"
      />
      <label for="nee_{{ i }}" class="answer">Nee</label>
    </p>
  </div>
</div>
