import {
  Component,
  Input,
  OnInit,
  ViewChild,
  EventEmitter,
  Output,
} from '@angular/core';
import { BaseChartDirective } from 'ng2-charts';
import 'chartjs-plugin-labels';
import { ChartConfiguration } from 'chart.js';
import { Chart } from 'chart.js';
import ChartDataLabels, { Context } from 'chartjs-plugin-datalabels';
import { auto } from '@popperjs/core';
import { Router } from '@angular/router';
import { TEMP_BG, splitLabel, isStackedGraph } from './utils';
Chart.register(ChartDataLabels);
Chart.defaults.scale.grid.display = false;
Chart.defaults.datasets.bar.barThickness = 70;
Chart.defaults.scale.ticks.display = false;
const TCWU = 'Totaal commercieel watergebruik';
const TWD = 'Totaal waterafvoer';
@Component({
  selector: 'printable-chart',
  templateUrl: './printable-chart.component.html',
  styleUrls: ['./printable-chart.component.scss'],
})
export class PrintableChartComponent implements OnInit {
  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;
  @Output() onTotalCalculated = new EventEmitter<string>();

  @Input() chartDetails: any;
  @Input() id: any;
  @Input() width: any;
  chartData: any;
  options: any;
  isCenterAligned: any;
  isDashboard: any;
  legendHtml: any;

  constructor(private router: Router) { }

  ngOnInit() {
    const unit = this.chartDetails?.unit;
    let labels = this.chartDetails?.labels;
    let isStacked = isStackedGraph(this.chartDetails.dataLabel);
    let backgroundColors = isStacked
      ? TEMP_BG
      : this.chartDetails?.backgroundColors;
    let data = this.chartDetails?.data;
    let datasets = [
      {
        label: this.chartDetails?.dataLabel,
        data,
        backgroundColor: backgroundColors,
        borderColor: backgroundColors,
      },
    ];
    this.isDashboard = this.router.url.indexOf('landing-page') !== -1;
    this.isCenterAligned = !isStacked || this.isDashboard;
    if (isStacked) {
      let initializeLabels = [''];
      //remove zero values and labels
      const filteredLabels = labels.filter(
        (value: number, index: number) => data[index] !== 0
      );
      const filteredData = data.filter((value: number) => value !== 0);
      let totaal = -1;
      const indexOfTCWU = filteredLabels?.indexOf(TCWU);
      if (indexOfTCWU !== -1) {
        totaal = filteredData[indexOfTCWU];
        filteredLabels?.splice(indexOfTCWU, 1);
        filteredData?.splice(indexOfTCWU, 1);
      }

      const indexOfTWD = filteredLabels?.indexOf(TWD);
      if (indexOfTWD !== -1) {
        totaal = filteredData[indexOfTWD];
        filteredLabels?.splice(indexOfTWD, 1);
        filteredData?.splice(indexOfTWD, 1);
      }

      const total = filteredData?.reduce(
        (accumulator: number, currentValue: number) =>
          accumulator + currentValue,
        0
      );
      let finalTotal = totaal == -1 ? total : totaal
      let formatedValue = Number(finalTotal)?.toLocaleString(
        'nl-nl'
      );
      const totalString = `Totaal: ${formatedValue} ${this.chartDetails?.unit}`;
      this.onTotalCalculated.emit(totalString);
      if (this.isDashboard) {
        datasets = [
          {
            backgroundColor: '#FF7523',
            borderColor: '#FF7523',
            label: totalString,
            data: [total],
          },
        ];
        labels = ['2022'];
        this.options = {
          responsive: true,
          aspectRatio: 1,
          plugins: {
            tooltip: {
              callbacks: {
                label: (context: Context) => {
                  let label = context.dataset.label || '';
                  let value = Number(
                    context.chart.data.datasets[0].data[context.dataIndex]
                  ).toLocaleString('nl-nl');

                  const valueAndUnit = value
                    ? `${value} ${this.chartDetails?.unit ?? ''}`
                    : 0;

                  if (label) {
                    label = `${label} : ${valueAndUnit}`;
                  }
                  return label;
                },
              },
            },

            legend: {
              display:
                this.chartDetails?.chartType === 'doughnut' ? true : false,
              position: 'bottom',
              labels: {
                boxWidth: 16,
                boxHeight: 16,
                font: {
                  size: 14,
                  family: "'Myriad Pro Regular', sans-serif",
                  style: 'normal',
                  weight: '400',
                  padding: 40,
                },
              },
            },
            datalabels: {
              formatter: (value: any, context: Context) => {
                let percentageNum = Number(
                  context.chart.data.datasets[0].data[context.dataIndex]
                ).toLocaleString('nl-nl');
                return percentageNum
                  ? `${percentageNum} ${this.chartDetails?.unit}`
                  : 0;
              },
              display: (context: Context) => {
                //showing value on chart only if the value is greater than 0
                return context.dataset.data[context.dataIndex] !== 0; // or >= 1 or ...
              },
              color: this.chartDetails?.labelColors,
              overlap: {
                display: auto,
              },
              font: {
                size: this.chartDetails?.chartType === 'doughnut' ? 14 : 11,
                family: "'Myriad Pro Regular', sans-serif",
                style: 'italic',
                weight: '400',
                // padding: 40,
              },
              // padding: 40,
            },
          },
          scales: this.chartDetails?.chartType !== 'doughnut' && {
            x: {
              ticks: {
                display: true,
              },
              border: {
                enabled: true,
                display: false,
              },
            },
            y: {
              border: {
                enabled: true,
                display: false,
              },
            },
          },
        };
      } else {
        //normalized data
        const normalizedData = filteredData;
        let lagendsArr: any = [];
        datasets = filteredLabels?.map((label: string, index: number) => {
          let localValue = Number(normalizedData[index]).toLocaleString(
            'nl-nl'
          );
          let localValueUnit = localValue + ` ${unit}`;
          label = `${label}: ${localValueUnit}`;
          const labelArray = splitLabel(label);
          lagendsArr.push({
            localValue: normalizedData[index],
            label: label,
            backgroundColor: backgroundColors[index]
          });
          return {
            label: labelArray,
            backgroundColor: backgroundColors[index],
            data: [normalizedData[index]],
          };
        });

        lagendsArr = lagendsArr.sort((a: any, b: any) => a.localValue - b.localValue);
        this.legendHtml = JSON.parse(JSON.stringify(lagendsArr));
        datasets = datasets.sort((a, b) => b.data[0] - a.data[0]);
        labels = initializeLabels;
        this.options = {
          responsive: true,
          // maintainAspectRatio: true,
          maintainAspectRatio: false,
          // height: 400,
          aspectRatio: 1,
          scales: {
            x: {
              stacked: true,
              display: false, // Hide x-axis
            },
            y: {
              stacked: true,
              display: false, // Hide y-axis
            },
          },
          plugins: {
            title: {
              display: false,
              text: 'Custom Chart Title',
              textAlign: 'center',
              marginBottom: '40px',
              color: '#0058ff',
              font: {
                size: '18px',
                family: "'Myriad Pro Regular', sans-serif",
                style: 'italic',
                weight: '400',
              },
            },
            legend: {
              display: true,
              onClick: () => { },
              fullSize: true,
              position: 'right', // Set the position of the legend to the top
              reverse: true,
              align: 'center',
              labels: {
                background: 'red',
                usePointStyle: false,
                border: 2,
                color: 'transparent',
                boxWidth: 0,
                boxHeight: 0,
                font: {
                  size: 30,
                  family: "'Myriad Pro Regular', sans-serif",
                  style: 'normal',
                  weight: '400',
                },
              },
            },
            datalabels: {
              color: 'transparent',
            },
          },
          interaction: {
            intersect: false, // Disable tooltip interaction
            mode: 'none', // Set the tooltip mode to index
          },
          tooltips: {
            enabled: false, // Hide the tooltips
          },
        };
      }
    }

    this.chartData = {
      labels,
      datasets,
    };

    if (!isStacked) {
      this.onTotalCalculated.emit('Sustainable impact');
      this.options = {
        responsive: true,
        aspectRatio: 1,
        plugins: {
          tooltip: {
            callbacks: {
              label: (context: Context) => {
                let label = context.dataset.label || '';
                let value = Number(
                  context.chart.data.datasets[0].data[context.dataIndex]
                ).toLocaleString('nl-nl');

                const valueAndUnit = value
                  ? `${value} ${this.chartDetails?.unit ?? ''}`
                  : 0;

                if (label) {
                  label = `${label} : ${valueAndUnit}`;
                }
                return label;
              },
            },
          },

          legend: {
            display: this.chartDetails?.chartType === 'doughnut' ? true : false,
            position: 'bottom',
            labels: {
              boxWidth: 16,
              boxHeight: 16,
              font: {
                size: 14,
                family: "'Myriad Pro Regular', sans-serif",
                style: 'normal',
                weight: '400',
                padding: 40,
              },
            },
          },
          datalabels: {
            formatter: (value: any, context: Context) => {
              let percentageNum = Number(
                context.chart.data.datasets[0].data[context.dataIndex]
              ).toLocaleString('nl-nl');
              return percentageNum
                ? `${percentageNum} ${this.chartDetails?.unit}`
                : 0;
            },
            display: (context: Context) => {
              //showing value on chart only if the value is greater than 0
              return context.dataset.data[context.dataIndex] !== 0; // or >= 1 or ...
            },
            color: this.chartDetails?.labelColors,
            overlap: {
              display: auto,
            },
            font: {
              size: this.chartDetails?.chartType === 'doughnut' ? 14 : 11,
              family: "'Myriad Pro Regular', sans-serif",
              style: 'italic',
              weight: '400',
              // padding: 40,
            },
            // padding: 40,
          },
        },
        scales: this.chartDetails?.chartType !== 'doughnut' && {
          x: {
            ticks: {
              display: true,
            },
            border: {
              enabled: true,
              display: false,
            },
          },
          y: {
            border: {
              enabled: true,
              display: false,
            },
          },
        },
      };
    }

    plugins: [ChartDataLabels];
  }

  ngOnChanges() {
    this.chart?.update();
  }
}
