import { Component, AfterViewInit, Renderer2, ElementRef, Input, ChangeDetectorRef } from '@angular/core';
import { LegendPosition, LegendType } from 'ngx-charts-extended';
import { ASCENDING, DESCENDING, sortDataByName, sortDataByValue } from '../utils';
import { SORTING_ENUMS } from 'src/app/shared/constants';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss']
})

export class BarChartComponent implements AfterViewInit {
  @Input() chartDimensions: [number, number] = [938, 314]; // Default view dimensions
  @Input() graphTopTitle: string = ''; // Default top title
  @Input() graphBottomTitle: string = ''; // Default bottom title
  @Input() colorScheme: any; // You may need to define the type of colorScheme based on your data structure
  @Input() data: any[] = []; // Your chart data
  @Input() yScaleMax: number = 100; // Default yScaleMax
  @Input() barPadding: number = 42; // Default bar padding
  @Input() formatDataLabel!: any; // You may need to define the type of formatDataLabel based on your data structure
  @Input() yAxisLabel: string = '';

  width: number = this.chartDimensions[0] || 0;
  height: number = this.chartDimensions[1] || 0;
  multi: any[] = [];

  // view: [number, number] = [this.width, this.height];
  showDataLabel = false;
  // options
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = false;
  showXAxisLabel = true;
  xAxisLabel = 'Renewable';
  showYAxisLabel = true;
  // yAxisLabel = 'Ratio of renewable energy (%)';
  rotateXAxisTicks = false;
  legendPosition: LegendPosition = LegendPosition.Below;
  legendType: LegendType = LegendType.Legend;

  constructor(private renderer: Renderer2, private elementRef: ElementRef, private cdr: ChangeDetectorRef) {

  }

  ngAfterViewInit(): void {}

  formatXAxisTickLabel(value: any): string {
    return `${value}`;
  }

  shouldShowTotal(value: boolean) {
    this.showDataLabel = value;
  }

  applySorting(value: string) {
    switch (value) {
      case SORTING_ENUMS.HIGH_TO_LOW:
        this.data = [...sortDataByValue(this.data, DESCENDING)];
        break;
      case SORTING_ENUMS.LOW_TO_HIGH:
        this.data = [...sortDataByValue(this.data, ASCENDING)];
        break;
      case SORTING_ENUMS.LOCATION_NAME_AZ:
        this.data = [...sortDataByName(this.data, ASCENDING)]
        break;
      case SORTING_ENUMS.LOCATION_NAME_ZA:
        this.data = [...sortDataByName(this.data, DESCENDING)]
        break;
      default:
        break;
    }
  }

}
