import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-submit-confirmation-modal',
  templateUrl: './submit-confirmation-modal.component.html',
  styleUrls: ['./submit-confirmation-modal.component.scss'],
})
export class SubmitConfirmationModalComponent implements OnInit {
  @Output() submitAnswers: EventEmitter<any> = new EventEmitter();
  showButtonLoader: boolean = false;
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {}

  onSubmitingAnswers(action: string) {
    this.showButtonLoader = true;
    this.submitAnswers.emit(action);
  }
}
