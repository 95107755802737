import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

import { LoaderState } from '../models/loader';

@Injectable()
export class LoaderService {
  public loaderSubject = new BehaviorSubject<LoaderState>({ show: false });
  loaderState = this.loaderSubject.asObservable();
  constructor() {}
  show() {
    this.loaderSubject.next(<LoaderState>{ show: true });
  }
  hide() {
    this.loaderSubject.next(<LoaderState>{ show: false });
  }
}
