<div class="d-flex flex-column justify-content-between" style="height: 100%">
  <div>
    <div class="d-flex justify-content-between">
      <div class="title">
        <p>CSRD</p>
      </div>
      <button class="edit-button" (click)="enableEditMode()">
        <img src="../../../assets/images/pencil.svg" alt="Edit" />
      </button>
    </div>
    <!-- TODO change copy to dutch -->
    <p class="title">CSRD 'niet plichtig'</p>
    <p>
      Wil je de CSRD 'niet plichtig' module activeren?
    </p>
    <form [formGroup]="editCSRDform">
      <div class="text">
        <div class="d-flex radio align-items-center mt-3 mb-3">
          <p class="mb-0">
            <input
              type="radio"
              formControlName="csrdBasis"
              [value]="'NONE'"
              [checked]="csrdBasis === 'NONE'"
              id="csrdBasis_none"
            />
            <label for="csrdBasis_none" class="answer">Niet actief</label>
          </p>
          <p class="mb-0">
            <input
              type="radio"
              formControlName="csrdBasis"
              [value]="'BASIS'"
              [checked]="csrdBasis === 'BASIS'"
              id="csrdBasis_basis"
            />
            <label for="csrdBasis_basis" class="answer">Basis</label>
          </p>
          <p class="mb-0">
            <input
              type="radio"
              formControlName="csrdBasis"
              [value]="'NARRATIVE'"
              [checked]="csrdBasis === 'NARRATIVE'"
              id="csrdBasis_narrative"
            />
            <label for="csrdBasis_narrative" class="answer">Narrative</label>
          </p>
        </div>
      </div>
    </form>
  </div>
  <div *ngIf="editMode == true" class="layout d-flex justify-content-center mt-4 " style="width: 100%;">
    <button class="button-primary-orange me-4 pe-2" (click)="updateCustomerDetails()">
      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="showButtonLoader"></span>
      <span *ngIf="!showButtonLoader">Opslaan</span>
    </button>
    <button class="cancel-button" (click)="cancelChanges()">
      Cancel
    </button>
  </div>
</div>
