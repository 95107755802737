// step.service.ts
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class StepService {
    currentStep = 1;
    stepCompleted = [false, false, false];

    setCurrentStep(step: number) {
        this.currentStep = step;
    }

    setStepCompleted(step: number) {
        this.stepCompleted[step - 1] = true;
    }
}
