import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import {
  ToastService,
  TOAST_STATE,
} from 'src/app/shared/services/toast.service';
import { getStatusClass, getStatus } from '../table/utils';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'be-goals-status-checkbox-group',
  templateUrl: './be-goals-status-checkbox-group.component.html',
  styleUrls: ['./be-goals-status-checkbox-group.component.scss'],
})
export class BeGaolsStatusCheckboxGroupComponent implements OnInit {
  @Input() itemsList!: any;
  @Input() applyBoxShadow: any;
  @Input() editMode: any;
  @Input() year:any;
  @Input() currentNumberOfLocations: any;
  @Output() onToggleItem = new EventEmitter<string>();
  getStatusClass: any = getStatusClass;
  getStatus: any = getStatus;

  ngOnInit(): void {}

  constructor(private toastService: ToastService, private router: Router, public route: ActivatedRoute) {}

  getCurrentNumberOfLocation(item: any): any{
    let location = this.currentNumberOfLocations?.find((location: any)=> location.BREAK_EVEN_GOAL_ID == item.BREAK_EVEN_GOAL_ID);
    return location?.NUMBER_OF_SELECTED_LOCATIONS || 0;
  }

  navigate(item:any):void{
    let customerID = this.route?.parent?.snapshot?.paramMap?.get('id');
    this.router.navigate([`consultants/selected-client/${customerID}/assessments`],
    {
      queryParams: {
        breakEvenGoalId: item?.ID,
        year: this.year
      }
    });
  }

  toggleCheckbox(event: Event, item: any): void {
    if (item.DEEPDIVE_STATE !== 'DEEPDIVE_NOT_STARTED') {
      event.preventDefault();
      this.toastService.showToast(
        TOAST_STATE.danger,
        'Kan het assessment niet deselecteren. Klant is assessment al gestart'
      );
      setTimeout(() => this.toastService.dismissToast(), 2000);

      return;
    }

    this.onToggleItem.emit(item);
  }
}
