<ng-container [ngSwitch]="chartType">
  <ng-container *ngSwitchCase="'pie_chart'">
    <!-- Render Pie Chart Component -->
    <app-pie-chart
      [chartDimensions]="chartData.chartDimensions"
      [graphTopTitle]="chartData.graphTopTitle"
      [graphBottomTitle]="chartData.xAxisLabel"
      [colorScheme]="chartData.colorScheme"
      [data]="chartData.data"
      [yScaleMax]="chartData.yScaleMax"
      [barPadding]="chartData.barPadding"
      [formatDataLabel]="chartData.formatDataLabel"
      [yAxisLabel]="chartData.yAxisLabel"
      [legend]="chartData.legend"
      [labels]="chartData.labels"
      [unit]="chartData.unit"
      [showTotal]="chartData.showTotal"
      [pieChartTotal]="chartData.pieChartTotal"
    ></app-pie-chart>
  </ng-container>
  <ng-container *ngSwitchCase="'bar_chart'">
    <!-- Render Bar Chart Component -->
    <app-bar-chart
      [chartDimensions]="chartData.chartDimensions"
      [graphTopTitle]="chartData.graphTopTitle"
      [graphBottomTitle]="chartData.graphBottomTitle"
      [colorScheme]="chartData.colorScheme"
      [data]="chartData.data"
      [yScaleMax]="chartData.yScaleMax"
      [barPadding]="chartData.barPadding"
      [formatDataLabel]="chartData.formatDataLabel"
      [yAxisLabel]="chartData.yAxisLabel"
    ></app-bar-chart>
  </ng-container>
  <ng-container *ngSwitchCase="'stacked_bar_chart'">
    <!-- Render Stacked Bar Chart Component -->
    <app-stacked-bar-chart
      [chartDimensions]="chartData.chartDimensions"
      [graphTopTitle]="chartData.graphTopTitle"
      [graphBottomTitle]="chartData.xAxisLabel"
      [colorScheme]="chartData.colorScheme"
      [data]="chartData.data"
      [yScaleMax]="chartData.yScaleMax"
      [barPadding]="chartData.barPadding"
      [formatDataLabel]="chartData.formatDataLabel"
      [yAxisLabel]="chartData.yAxisLabel"
      [xAxisLabel]="chartData.xAxisLabel"
    ></app-stacked-bar-chart>
  </ng-container>
  <ng-container *ngSwitchCase="'stack_bar_chart_advance'">
    <!-- Render Advanced Stacked Bar Chart Component -->
    <app-stacked-bar-chart-advance
      [chartDimensions]="chartData.chartDimensions"
      [graphTopTitle]="chartData.graphTopTitle"
      [graphBottomTitle]="chartData.graphBottomTitle"
      [colorScheme]="chartData.colorScheme"
      [data]="chartData.data"
      [yScaleMax]="chartData.yScaleMax"
      [barPadding]="chartData.barPadding"
      [formatDataLabel]="chartData.formatDataLabel"
      [yAxisLabel]="chartData.yAxisLabel"
    ></app-stacked-bar-chart-advance>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <!-- Render a default component if none of the cases match -->
    <div>No chart available for this type</div>
  </ng-container>
</ng-container>