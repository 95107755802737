import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  NgbCalendar,
  NgbDateAdapter,
  NgbDateParserFormatter,
} from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import { cloneDeep, every, find, set } from 'lodash';
import { Observable, take } from 'rxjs';
import { UtilService } from 'src/app/shared/services/util.service';
import { IAppState } from 'src/app/store/state';
import {
  getFormGroupStyle,
  parseRefYear,
  ENERGY_GRADES,
  getLabel,
} from './utils';

@Component({
  selector: 'app-csrd-questions',
  templateUrl: './csrd-questions.component.html',
  styleUrls: ['./csrd-questions.component.scss'],
})
export class CSRDQuestionsComponent implements OnInit, OnChanges {
  @Input() csrdQuestions!: any[];
  @Input() selectedTab!: any;
  @Input() tabsLength!: number;
  @Input() showDescription: boolean = true;
  @Input() csrdStatus!: any;
  @Input() refYear: any;

  storeValue: any;
  questionsForm!: FormGroup;
  dropDownForm!: FormGroup;
  dropDownOptions: any;
  showLoader: boolean = false;
  formBuilder = inject(FormBuilder);
  utilService = inject(UtilService);
  private ngbCalendar = inject(NgbCalendar);
  private dateAdapter = inject(NgbDateAdapter);
  private dateFormatter = inject(NgbDateParserFormatter);
  @Output() onSelectingPreviousTab = new EventEmitter<any>();
  @Output() onSubmittingGroupAnswers = new EventEmitter<any>();
  allDependentQuestionsHidden: boolean = true;
  initialcsrdQuestions: any;
  selectedEnergyLabel: any;
  constructor(private store: Store<IAppState>) {}

  ngOnInit(): void {
    this.initialcsrdQuestions = this.csrdQuestions;
    this.questionsForm = this.formBuilder.group({
      array: this.formBuilder.array([]), // Initialize as an empty form array
    });
    this.populateQuestions();
    this.calculateAllDependentQuestionsHidden();
    this.parseRefYear();
    // this.questionsForm.valueChanges.subscribe((value) => {
    //   console.log('value', value);
    // });
  }

  getFormGroupStyle(value: any): any {
    return getFormGroupStyle(value);
  }

  parseRefYear() {
    this.csrdQuestions = parseRefYear(this.csrdQuestions, this.refYear);
  }

  get today() {
    return this.dateFormatter.format(this.ngbCalendar.getToday())!;
  }

  getLabel(value: any) {
    return getLabel(value, this.refYear);
  }

  ngOnChanges(changes: any): void {
    // if (changes.csrdQuestions && !changes.csrdQuestions.firstChange) {
    //   // Only do something when csrdQuestions changes, not on every change
    //   this.populateQuestions();
    // }
  }

  returnControl(array: any, label: any, value: any) {
    array?.map(
      (eachLabelValue: any) =>
        this.formBuilder.control(
          eachLabelValue?.ANSWER
            ? eachLabelValue?.LABEL === label
              ? eachLabelValue?.ANSWER
              : ''
            : ''
        ),
      [Validators.max(value?.MAX_VALUE), Validators.min(value?.MIN_VALUE)]
    );
  }

  enableEnergyLabelRelatedQuestion(selectedOption: any, currentQuestion: any) {
    let selectedLabel = selectedOption?.selection?.NAME;
    let isDisabled = ENERGY_GRADES.indexOf(selectedLabel) !== -1;
    this.selectedEnergyLabel = selectedLabel;
    this.csrdQuestions.map((question, index) => {
      currentQuestion?.RELATED_QUESTIONS?.filter((id: any) => {
        if (Number(id) === Number(question?.ID)) {
          question.DISABLED = isDisabled;
          //Disabling the answers for the dependent question if the answer is NO
          this.getArrayControls()
            ?.at(index)
            ?.get('DISABLED')
            ?.setValue(question.DISABLED);
          //clearing the answers for the dependent question if the answer is NO
          if (question?.TYPE === 'MULTI_NUMBER') {
            question?.ANSWER?.ANSWERS?.map((eachValue: any, i: any) => {
              this.getAnswerLabelControls(index)[i].get('ANSWER')?.reset();
              this.getAnswerLabelControls(index)[i].get('ANSWER')?.setValue('');
            });
          }
          this.getArrayControls()?.at(index)?.get('ANSWERS')?.reset();
          this.getArrayControls()?.at(index)?.get('ANSWERS')?.setValue('');
        }
      });
    });
  }
  onOptionSelected(selectedOption: any, currentQuestion: any) {
    this.getQuestionsFormArray();
    this.enableEnergyLabelRelatedQuestion(selectedOption, currentQuestion);
  }

  onCheckboxChange(event: Event, question: any) {
    const target = event.target as HTMLInputElement; // Cast to HTMLInputElement
    const isChecked = target.checked; // Get the checked status of the checkbox
    const questionIndex = this.csrdQuestions.findIndex(
      (q) => q.ID === question.ID
    ); // Find the question index

    if (questionIndex !== -1) {
      const answersControl =
        this.getArrayControls()[questionIndex].get('ANSWERS'); // Get the corresponding form control

      if (answersControl) {
        // Update the form control value based on the checkbox status
        answersControl.setValue(isChecked ? 'checked' : ''); // Use 'checked' or any suitable value as needed
      }
    }
  }

  getControlName(groupIndex: number, questionIndex: number): string {
    return `array.${groupIndex}.GROUPED_ANSWERS.${questionIndex}.ANSWERS`;
  }

  populateQuestions() {
    const formArray = this.questionsForm.get('array') as FormArray;

    this.csrdQuestions.forEach((question, i) => {
      if (question.TYPE === 'GROUPED') {
        const groupForm = this.formBuilder.group({
          ID: [question.ID],
          GROUPED_ANSWERS: this.formBuilder.array([]), // Initialize the array for grouped answers
        });

        const groupedANSWERSArray = groupForm.get(
          'GROUPED_ANSWERS'
        ) as FormArray;

        // Determine the number of groups based on DEFAULT_NUMBER_OF_GROUPS and existing GROUPED_ANSWERS
        const noOfGroups = Math.max(
          Number(question.DEFAULT_NUMBER_OF_GROUPS),
          question.ANSWER?.GROUPED_ANSWERS?.length || 0
        );

        for (let gi = 0; gi < noOfGroups; gi++) {
          const groupAnswers = this.formBuilder.array([]) as FormArray;

          question?.GROUP?.forEach((groupQuestion: any, gQi: any) => {
            const answer =
              question.ANSWER?.GROUPED_ANSWERS?.[gi]?.ANSWERS?.[gQi]?.ANSWERS[0]
                ?.ANSWER || '';

            const groupQuestionForm = this.formBuilder.group({
              ID: [groupQuestion.ID || gQi.toString()],
              ANSWERS: [answer],
              MIN_VALUE: [groupQuestion.MIN_VALUE || ''],
              MAX_VALUE: [groupQuestion.MAX_VALUE || ''],
              DESCRIPTION: [groupQuestion.DESCRIPTION || ''],
              TYPE: [groupQuestion.TYPE || ''],
              TEXT: [groupQuestion.TEXT || ''],
              ANSWER_LABELS: [groupQuestion.ANSWER_LABELS || ['']],
              PLACEHOLDER: [groupQuestion.PLACEHOLDER || ''],
            });

            groupAnswers.push(groupQuestionForm);
          });

          const groupAnswerFormGroup = this.formBuilder.group({
            ID: [
              question.ANSWER?.GROUPED_ANSWERS[gi]?.ID || (gi + 1).toString(),
            ],
            ANSWERS: groupAnswers,
          });

          groupedANSWERSArray.push(groupAnswerFormGroup);
        }

        formArray.push(groupForm);
      } else {
        // Non-grouped question
        const questionForm = this.formBuilder.group({
          ANSWERS: [
            question.ANSWER?.ANSWERS[0]?.ANSWER || '',
            Validators.required,
          ], // Adjust validators as needed
          ID: [question.ID],
          TYPE: [question.TYPE],
          PLACEHOLDER: [question.PLACEHOLDER || ''],
          DISABLED: [question.DISABLED || false],
        });

        formArray.push(questionForm);
      }
    });
  }

  getFormArrayControls() {
    return (this.questionsForm.get('array') as FormArray).controls;
  }

  getQuestionGroupControls(groupIndex: number) {
    return (
      this.getFormArrayControls()[groupIndex].get('questions') as FormArray
    ).controls;
  }

  getDependentValue(id: string) {
    if (id == '4')
      return 'Geconsolideerde basis';
    return 'Ja';
  }

  createQuestionFormGroup(question: any): FormGroup {
    if (question.TYPE === 'GROUPED') {
      return this.formBuilder.group({
        GROUP: this.formBuilder.array(
          question.GROUP.map((g: any) => this.createSubQuestionFormGroup(g))
        ),
      });
    } else {
      return this.formBuilder.group({
        ID: this.formBuilder.control(question?.ID ?? ''),
        TEXT: this.formBuilder.control(question?.TEXT ?? ''),
        TYPE: this.formBuilder.control(question?.TYPE ?? ''),
        DESSCRIPTION: this.formBuilder.control(question?.DESCRIPTION ?? ''),
        answerlabel: this.buildAnswerLabel(question),
        DISABLED: this.formBuilder.control(question?.DISABLED ?? ''),
        UNIT: this.formBuilder.control(question?.UNIT ?? ''),
        ANSWERS: this.getAnswerControl(question),
        // Add additional form controls if needed
      });
    }
  }

  createSubQuestionFormGroup(subQuestion: any): FormGroup {
    return this.formBuilder.group({
      ID: this.formBuilder.control(subQuestion?.ID ?? ''),
      TEXT: this.formBuilder.control(subQuestion?.TEXT ?? ''),
      DESSCRIPTION: this.formBuilder.control(subQuestion?.DESCRIPTION ?? ''),
      TYPE: this.formBuilder.control(subQuestion?.TYPE ?? ''),
      answerlabel: this.buildAnswerLabel(subQuestion),
      DISABLED: this.formBuilder.control(subQuestion?.DISABLED ?? ''),
      UNIT: this.formBuilder.control(subQuestion?.UNIT ?? ''),
      ANSWERS: this.getAnswerControl(subQuestion),
    });
  }

  buildDropDown() {
    let dropDownQuestion = this.csrdQuestions?.filter(
      (question) => question.TYPE === 'DROPDOWN'
    )[0];
    if (dropDownQuestion) {
      let dropDownAnswer = dropDownQuestion?.ANSWER?.ANSWERS[0]?.ANSWER ?? '';
      this.dropDownForm = this.formBuilder.group({
        dropDown: this.formBuilder.control({
          value: dropDownAnswer,
          disabled: false,
        }),
      });
      this.dropDownOptions = dropDownQuestion?.ANSWER_LABELS?.map(
        (label: string) => ({
          NAME: label,
          isSelected: dropDownAnswer === label,
        })
      );
    }
  }
  getFormatedValue(value: any) {
    if (value?.TYPE === 'DATE') {
      let date =
        this.utilService.toDate(value?.ANSWER?.ANSWERS?.[0]?.ANSWER) ?? null;
      return date;
    }
    return value?.ANSWER?.ANSWERS?.[0]?.ANSWER ?? '';
  }

  getValidators(value: any) {
    if (value?.TYPE === 'TEXT' || value?.TYPE === 'TEXT_AREA') {
      return [
        Validators.maxLength(value?.MAX_VALUE),
        Validators.minLength(value?.MIN_VALUE),
      ];
    }
    return [Validators.max(value?.MAX_VALUE), Validators.min(value?.MIN_VALUE)];
  }

  getAnswerControl(value: any) {
    let formatedValue = this.getFormatedValue(value);
    let validators = this.getValidators(value);
    return this.formBuilder.control(formatedValue, validators);
  }

  buildAnswerLabel(value: any) {
    if (value?.TYPE === 'MULTI_NUMBER') {
      return this.formBuilder.array(
        value?.ANSWER_LABELS?.map((eachValue: any) =>
          this.formBuilder.group({
            LABEL: this.formBuilder.control(eachValue ?? ''),
            ANSWER: this.formBuilder.control(
              find(value?.ANSWER?.ANSWERS, ['LABEL', eachValue])?.ANSWER ?? '',
              [
                Validators.max(value?.MAX_VALUE),
                Validators.min(value?.MIN_VALUE),
              ]
            ),
          })
        )
      );
    } else if (value?.TYPE === 'CHECKBOX') {
      return this.formBuilder.array(
        value?.ANSWER_LABELS?.map((eachValue: any) =>
          this.formBuilder.group({
            LABEL: this.formBuilder.control(eachValue ?? ''),
            ANSWER: this.formBuilder.control(
              find(value?.ANSWER?.ANSWERS, ['LABEL', eachValue])?.ANSWER ===
                'true' || ''
            ),
          })
        )
      );
    } else {
      return false;
    }
  }

  getArrayControls(type?: any, index?: any) {
    const arrayControls = (this.questionsForm.get('array') as FormArray)
      .controls;
    return arrayControls;
  }

  getAnswerLabelControls(index: any) {
    return (
      (this.questionsForm.get('array') as FormArray)
        ['at'](index)
        .get('answerlabel') as FormArray
    ).controls;
  }

  getQuestionsFormArray() {
    const formArray: FormArray = this.questionsForm.get('array') as FormArray;
    return cloneDeep(formArray?.value);
  }

  clearDate(index: number, controlName: any) {
    this.getArrayControls()?.at(index)?.get(controlName)?.reset();
    this.getArrayControls()?.at(index)?.get(controlName)?.setValue('');
  }

  backSpace(event: any, index: number, controlName: any) {
    if (event.keyCode == 8 || event.keyCode == 46) {
      this.clearDate(index, controlName);
    }
  }

  getPreviousTab() {
    this.onSelectingPreviousTab.emit();
  }

  //Destroying the toast
  ngOnDestroy() {
    const formArray: FormArray = this.questionsForm?.get('array') as FormArray;
    formArray.clear();
  }

  //Disabling or enabling the dependent questions based on radio button
  onAnsweringRadioButton(
    relatedQuestionArray: any,
    answer: any,
    dependentValue?: any
  ) {
    //#TODO Dependent Value should be in API, handle based on dependent value
    // dependentValue = dependentValue || 'Geconsolideerde basis';
    dependentValue = dependentValue || 'Ja';
    this.csrdQuestions.forEach((question, index) => {
      relatedQuestionArray.filter((id: any) => {
        if (Number(id) === Number(question?.ID)) {
          if (question?.ID === '119')
            dependentValue = "Ja";
          if (question?.ID === '120')
            dependentValue = "Nee";
          question.DISABLED = answer !== dependentValue;
          //Disabling the answers for the dependent question if the answer is NO
          this.getArrayControls()
            ?.at(index)
            ?.get('DISABLED')
            ?.setValue(question.DISABLED);
          //clearing the answers for the dependent question if the answer is NO
          if (question?.TYPE === 'MULTI_NUMBER') {
            question?.ANSWER?.ANSWERS?.forEach((_: any, i: any) => {
              this.getAnswerLabelControls(index)[i].get('ANSWER')?.reset();
              this.getAnswerLabelControls(index)[i].get('ANSWER')?.setValue('');
            });
          }
          this.getArrayControls()?.at(index)?.get('ANSWERS')?.reset();
          this.getArrayControls()?.at(index)?.get('ANSWERS')?.setValue('');
        }
      });
    });
  }

  onAnsweringCheckbox(value: any, idx: any) {
    let currentAnswers = value?.ANSWER?.ANSWERS;
    currentAnswers[idx].ANSWER =
      currentAnswers[idx].ANSWER === 'true' ? 'false' : 'true';

    this.csrdQuestions.map((question, index) => {
      value?.RELATED_QUESTIONS?.filter((id: any) => {
        if (Number(id) === Number(question?.ID)) {
          question.DISABLED =
            !this.calculateShouldShowDependentQuestions(currentAnswers);
          //Disabling the answers for the dependent question if a checkbox is checked
          this.getArrayControls()
            ?.at(index)
            ?.get('DISABLED')
            ?.setValue(question.DISABLED);
          this.getArrayControls()?.at(index)?.get('ANSWERS')?.reset();
          this.getArrayControls()?.at(index)?.get('ANSWERS')?.setValue('');
        }
      });
    });

    this.calculateAllDependentQuestionsHidden();
  }

  calculateShouldShowDependentQuestions(currentAnswers: any) {
    return !!currentAnswers.find(
      (element: { ANSWER: string }) => element.ANSWER === 'true'
    );
  }

  calculateAllDependentQuestionsHidden() {
    this.allDependentQuestionsHidden = this.csrdQuestions.find(
      (value: { DISABLED: boolean; ID: string }) =>
        value.DISABLED == false &&
        Number(value.ID) > 55 &&
        Number(value.ID) < 68
    )
      ? false
      : true;
  }

  testValue(value: any) {}

  getNumberOfGroups(value: any, qIndex: any) {
    const noOfGroups = Math.max(
      value.DEFAULT_NUMBER_OF_GROUPS,
      this.questionsForm?.value?.array[qIndex].GROUPED_ANSWERS?.length
    );
    const arr = Array(noOfGroups)
      .fill(0)
      .map((_, i) => i);
    return arr;
  }

  getControlArray(valueId: number, groupIndex: number): FormArray {
    const formArray = this.questionsForm.get('array') as FormArray;
    const group = formArray.at(valueId) as FormGroup;
    const groupAnswers = group.get('GROUPED_ANSWERS') as FormArray;
    return groupAnswers;
  }
  // Method to add a new group
  addGroup(questionIndex: number) {
    const formArray = this.questionsForm.get('array') as FormArray;
    const groupForm = formArray
      .at(questionIndex)
      .get('GROUPED_ANSWERS') as FormArray;

    const newGroupIndex = groupForm.length;
    const question = this.csrdQuestions[questionIndex];
    const newGroupAnswers = this.formBuilder.array([]) as FormArray;

    question.GROUP.forEach((groupQuestion: any, gQi: any) => {
      const groupQuestionForm = this.formBuilder.group({
        ID: [groupQuestion.ID || gQi.toString()],
        ANSWERS: [''], // Default to empty string or customize as needed
        MIN_VALUE: [groupQuestion.MIN_VALUE || ''],
        MAX_VALUE: [groupQuestion.MAX_VALUE || ''],
        TEXT: [groupQuestion.TEXT || ''],
        DESCRIPTION: [groupQuestion.DESCRIPTION || ''],
        ANSWER_LABELS: [groupQuestion.ANSWER_LABELS || ['']],
        TYPE: [groupQuestion.TYPE || ''],
        PLACEHOLDER: [groupQuestion.PLACEHOLDER || ''],
      });

      newGroupAnswers.push(groupQuestionForm);
    });

    const newGroupFormGroup = this.formBuilder.group({
      ID: [(newGroupIndex + 1).toString()],
      ANSWERS: newGroupAnswers,
    });
    groupForm.push(newGroupFormGroup);
  }

  removeGroup(questionIndex: number, groupIndex: number) {
    const formArray = this.questionsForm.get('array') as FormArray;
    console.log(questionIndex, groupIndex);
    const groupArray = formArray
      .at(questionIndex)
      .get('GROUPED_ANSWERS') as FormArray;

    if (groupArray && groupArray.length > 1) {
      // Ensure at least one group remains
      groupArray.removeAt(groupIndex);
    } else {
      console.warn('Cannot remove the group. At least one group must remain.');
    }
  }
  trackByIndex(index: number, item: any): any {
    return index;
  }
}
