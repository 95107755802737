import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import {
	FormBuilder,
	FormControl,
	FormGroup,
	Validators,
} from '@angular/forms';
import { SharedService } from 'src/app/shared/services/shared.service';
import { regions, DEEP_DIVE_STATUS } from 'src/app/shared/constants';
@Component({
	selector: 'data-filter',
	templateUrl: './filter.component.html',
	styleUrls: ['./filter.component.scss'],
})
export class FilterComponent implements OnInit {
	@Input() consultantList: any;
	@Output() applySelectedFilters = new EventEmitter<any>();

	closeResult = '';
	filterForm!: FormGroup;
	sectorList: any = [];
	regionsList: any = [];
	statusList: any = [];
	modalReference: any;
	isAssignedToMe: boolean = false;
	selectedFilters: any = {};
	isDropdownOpen: boolean = false;
	consultantControl: any;

	constructor(private formBuilder: FormBuilder,
		private sharedService: SharedService,
	) { }

	ngOnInit(): void {
		this.initForm();
		this.regionsList = regions;
		this.statusList = DEEP_DIVE_STATUS;
	}

	onChangeSwitch(event: any) {
		this.isAssignedToMe = event;
		this.selectedFilters = {
			...this.selectedFilters,
			isAssignedToMe: event,
		}
		this.updateForm(event);

	}

	updateForm(event: any) {
		if (event) {
			delete this.selectedFilters.consultant
		}
		this.consultantControl.reset({ value: '', disabled: event });
	}
	/**
	 * Initializing the form
	 */
	initForm() {
		this.isAssignedToMe = false;
		this.consultantControl = new FormControl(
			{ value: '', disabled: false }
		);
		this.filterForm = this.formBuilder.group({
			assignedToMe: new FormControl(
				{ value: false, disabled: false },
				Validators.required
			),
			region: new FormControl(
				{ value: '', disabled: false },
				Validators.required
			),
			consultant: this.consultantControl,
			status: new FormControl(
				{ value: '', disabled: false }
			),
		});
	}

	toggleDropdown() {
		this.isDropdownOpen = !this.isDropdownOpen;
	}

	onOptionSelected(event: any) {
		this.selectedFilters = {
			...this.selectedFilters,
			[event.controlName]: event?.selection,
		}
	}

	resetFilters() {
		this.initForm();
		this.selectedFilters = {};
		this.applyFilters();
		this.closeDropdown(); 
	}

	closeDropdown(){
		this.isDropdownOpen = false;
	}

	applyFilters() {
		this.closeDropdown(); 
		this.applySelectedFilters.emit(this.selectedFilters)
		this.sharedService.setConsultantOverviewFilters(this.selectedFilters);
	}

}
