import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ErrorState } from 'src/app/shared/models/error';
import { ErrorService } from 'src/app/shared/services/error.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

  @Input() errorType!: string;
  error: Boolean = false;
  private subscription!: Subscription;
  constructor(
    private errorService: ErrorService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.subscription = this.errorService.errorState.subscribe(
      (state: ErrorState) => {
        this.error = state.error;
        this.cdr.detectChanges();
      }
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
