import { Component, AfterViewInit, Renderer2, ElementRef, Input} from '@angular/core';
import { multi } from './data';
import { LegendPosition, LegendType } from 'ngx-charts-extended';
import { sortDataByName, sortDataByTotal, DESCENDING, ASCENDING } from '../utils';
import { SORTING_ENUMS } from 'src/app/shared/constants';

@Component({
  selector: 'app-stacked-bar-chart',
  templateUrl: './stacked-bar-chart.component.html',
  styleUrls: ['./stacked-bar-chart.component.scss']
})

export class StackedBarChartComponent implements AfterViewInit {
  @Input() chartDimensions: [number, number] = [938, 314]; // Default view dimensions
  width: number = this.chartDimensions[0];
  height: number = this.chartDimensions[1];
  index: number = 0;
  currIndex: number = 0;
  view: [number, number] = [this.width, this.height];
  showDataLabel: boolean = true;
  // options
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = false;
  showXAxisLabel = true;
  @Input() xAxisLabel:any;
  showYAxisLabel = true;
  @Input() yAxisLabel:any; //= 'Ratio of renewable energy (%)';
  rotateXAxisTicks = false;
  legendPosition: LegendPosition = LegendPosition.Below;
  legendType: LegendType = LegendType.Legend;
  @Input() data: any[] = multi;




  @Input() graphTopTitle: string = ''; // Default top title
  @Input() graphBottomTitle: string = ''; // Default bottom title
  @Input() colorSchemeN: any; // You may need to define the type of colorScheme based on your data structure
  // @Input() data: any[] = []; // Your chart data
  @Input() yScaleMax: number = 100; // Default yScaleMax
  @Input() barPadding: number = 42; // Default bar padding
  @Input() formatDataLabel!: any; // You may need to define the type of formatDataLabel based on your data structure
  // @Input() yAxisLabel: string = '';
  @Input() legend!: boolean;
  @Input() labels!: boolean;
  @Input() unit!: string;

  formatXAxisTickLabel(value: any): string {
    return `${value}`;
  }

  shouldShowTotal(value: boolean) {
    this.showDataLabel = value;
  }

  applySorting(value: string) {
    switch (value) {
      case SORTING_ENUMS.HIGH_TO_LOW:
        this.data = [...sortDataByTotal(this.data, DESCENDING)];
        break;
      case SORTING_ENUMS.LOW_TO_HIGH:
        this.data = [...sortDataByTotal(this.data, ASCENDING)];
        break;
      case SORTING_ENUMS.LOCATION_NAME_AZ:
        this.data = [...sortDataByName(this.data, ASCENDING)]
        break;
      case SORTING_ENUMS.LOCATION_NAME_ZA:
        this.data = [...sortDataByName(this.data, DESCENDING)]
        break;
      default:
        break;
    }
  }

  formatDataLabelN = (value: any, index: any): string => {
    if (this.index > 2) {
      this.index = 0;
      this.currIndex++;
    }

    if (this.currIndex > 8) {
      this.currIndex = 0;
    }
    // or simply return the value as is

    let valueX = multi[this.currIndex]?.series[0]?.value;
    this.index++;
    return `${valueX}${this.unit}`;
  }

  ngAfterViewInit(): void {
    const labels = this.elementRef.nativeElement.querySelectorAll('.textDatalabel'); labels.forEach((element: HTMLElement) => { this.renderer.setStyle(element, 'font-size', '25px'); });
  }
  colorScheme: any = { domain: ['#0058FF', '#e0e0e0',], };


  // {
  //   domain: ['#0058FF'],
  // };

  constructor(private renderer: Renderer2, private elementRef: ElementRef) {}

  onSelect(event: any) {
    console.log(event);
  }

}
