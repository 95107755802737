import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'edit-dropdown',
	templateUrl: './edit-dropdown.component.html',
	styleUrls: ['./edit-dropdown.component.scss'],
})
export class EditDropDownComponent implements OnInit {
	@Output() rename = new EventEmitter<any>();
	@Output() delete = new EventEmitter<any>();
	@Input() isDeleteAllowed: any;

	modalReference: any;
	isDropdownOpen: boolean = false;



	ngOnInit(): void { }

	onRenamePressed (){
		this.isDropdownOpen = !this.isDropdownOpen;
		this.rename.emit();
	}

	onDeletePressed (){
		this.isDropdownOpen = !this.isDropdownOpen;
		this.delete.emit();
	}

	toggleDropdown() {
		this.isDropdownOpen = !this.isDropdownOpen;
	}

	openDropdown(dropdown: NgbDropdown) {
		this.isDropdownOpen = true;
		dropdown.open();
	}

	closeDropdown(dropdown: NgbDropdown) {
		this.isDropdownOpen = false;
		dropdown.close();
	}

}
