import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { ErrorState } from '../models/error';

@Injectable()
export class ErrorService {
  public errorSubject = new BehaviorSubject<ErrorState>({ error: false });
  errorState = this.errorSubject.asObservable();

  constructor() {}
  show() {
    this.errorSubject.next(<ErrorState>{ error: true });
  }
  hide() {
    this.errorSubject.next(<ErrorState>{ error: false });
  }
}
