import { Component,  AfterViewInit, Renderer2, ElementRef, Input } from '@angular/core';
import { LegendPosition, LegendType } from 'ngx-charts-extended';
import { sortDataByName, sortDataByTotal, ASCENDING, DESCENDING } from '../utils';
import { SORTING_ENUMS } from 'src/app/shared/constants';

@Component({
  selector: 'app-stacked-bar-chart-advance',
  templateUrl: './stacked-bar-chart-advance.component.html',
  styleUrls: ['./stacked-bar-chart-advance.component.scss']
})

export class StackedBarChartComponentAdvance implements AfterViewInit {
  @Input() chartDimensions: [number, number] = [938, 314]; // Default view dimensions
  @Input() graphTopTitle: string = ''; // Default top title
  @Input() graphBottomTitle: string = ''; // Default bottom title
  @Input() colorScheme: any; // You may need to define the type of colorScheme based on your data structure
  @Input() data: any[] = []; // Your chart data
  @Input() yScaleMax: number = 100; // Default yScaleMax
  @Input() barPadding: number = 42; // Default bar padding
  @Input() formatDataLabel!: any; // You may need to define the type of formatDataLabel based on your data structure
  @Input() yAxisLabel: string = '';
  width: number = this.chartDimensions[0] || 0;
  height: number =  this.chartDimensions[1] || 0;
  showDataLabel: boolean = true;

  // options
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = false;
  showXAxisLabel = true;
  xAxisLabel = 'Renewable';
  showYAxisLabel = true;
  rotateXAxisTicks = false;
  legendPosition: LegendPosition = LegendPosition.Below;
  legendType: LegendType = LegendType.Legend;

  formatXAxisTickLabel(value: any): string {
    // Your custom formatting logic here
    // For example, you can format dates using a library like moment.js
    // return moment(value).format('YYYY-MM-DD');

    // or simply return the value as is
    return `${value}`;
  }

  shouldShowTotal(value: boolean) {
    this.showDataLabel = value;
  }

  applySorting(value: string) {
    switch (value) {
      case SORTING_ENUMS.HIGH_TO_LOW:
        this.data = [...sortDataByTotal(this.data, DESCENDING)];
        break;
      case SORTING_ENUMS.LOW_TO_HIGH:
        this.data = [...sortDataByTotal(this.data, ASCENDING)];
        break;
      case SORTING_ENUMS.LOCATION_NAME_AZ:
        this.data = [...sortDataByName(this.data, ASCENDING)]
        break;
      case SORTING_ENUMS.LOCATION_NAME_ZA:
        this.data = [...sortDataByName(this.data, DESCENDING)]
        break;
      default:
        break;
    }
  }

  ngAfterViewInit(): void { const labels = this.elementRef.nativeElement.querySelectorAll('.textDatalabel'); labels.forEach((element: HTMLElement) => { this.renderer.setStyle(element, 'font-size', '25px'); }); }

  constructor(private renderer: Renderer2, private elementRef: ElementRef) {}

  onSelect(event: any) {
    console.log(event);
  }

}
