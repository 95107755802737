// sort chart data by location name A-Z or Z-A

/**
* ASC => Low -> High or A-Z
* DESC => High -> Low or Z-A
*/

type OrderInterface = 'asc' | 'desc';
export const ORDER = {
  ASCENDING: 'asc',
  DESCENDING: 'desc',
};

export const ASCENDING = 'asc';
export const DESCENDING = 'desc';

export const sortDataByName = (chartData: any, order: OrderInterface = ASCENDING) => {
  let sortedData = chartData.sort((a: any, b: any) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    if (order === ORDER.ASCENDING) {
      return nameA.localeCompare(nameB);
    } else {
      return nameB.localeCompare(nameA);
    }
  });

  return sortedData;
}

export const sortDataByValue = (chartData: any, order: OrderInterface = ASCENDING) => {
  let sortedData = chartData.sort((a: any, b: any) => {
    if (order === DESCENDING) {
      return b.value - a.value;
    } else {
      return a.value - b.value;
    }
  });
  return sortedData;
}


export function sortDataByTotal(data: any[], order: OrderInterface = ASCENDING) {
  return data.sort((a, b) => {
    const totalA = a.series.reduce((acc: any, curr: any) => acc + curr.value, 0);
    const totalB = b.series.reduce((acc: any, curr: any) => acc + curr.value, 0);

    if (order === ASCENDING) {
      return totalA - totalB;
    } else {
      return totalB - totalA;
    }
  });
}

export const CHART_TYPES = {
  PIE_CHART: "pie_chart",
  BAR_CHART: "bar_chart",
  STACKED_BAR_CHART: "stacked_bar_chart",
  STACKED_BAR_CHART_ADVANCE: "stack_bar_chart_advance"
}