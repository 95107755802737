import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
@Component({
  selector: 'app-be-goals-indications',
  templateUrl: './be-goals-indications.component.html',
  styleUrls: ['./be-goals-indications.component.scss'],
})
export class BeGoalsIndicationsComponent implements OnInit {
  @Output() onConsultantSelectedBEGoal = new EventEmitter<any>();
  @Input() begoals: any;
  @Input() selectedBeGoal: any;

  constructor() {}

  ngOnInit(): void {}

  selectBEGoal(goal: Object, idx: number) {
    this.selectedBeGoal = goal;
    this.onConsultantSelectedBEGoal.emit(goal);
  }
}
