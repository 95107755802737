<div class="table-responsive">
  <form [formGroup]="tableForm">
    <table class="table table-hover table-bordered">
      <thead>
        <tr>
          <th style="position: sticky; left: 0; background-color: #f8f9fa;"> </th>
          <th *ngFor="let column of tableHeadings" style="width: 250px !important;">{{ column }}</th>
        </tr>
      </thead>
      <tbody formArrayName="array">
        <ng-container *ngFor="let row of tableForm.get('array').controls; let rowIndex = index;
        
        " [ngSwitch]="true">
          <tr [formGroupName]="rowIndex">
            <td style="position: sticky; left: 0; background-color: #f8f9fa;">{{ tableData[rowIndex].header}}</td>
            <ng-container *ngFor="let control of row.controls; let controlIndex = index">
              <td [ngClass]="{disabled: !isFormEnabled}">
                <div [formGroupName]="controlIndex">
                  <fieldset *ngSwitchCase="
                  control?.value?.TYPE === 'NUMBER' || control?.value?.TYPE === 'PERCENTAGE'
                " [disabled]="control?.value?.DISABLED">

                    <div *ngIf="!control?.value?.DISABLED; else regularNumberInput"
                      [class.number-row]="(control?.value?.ID > 55 && control?.value?.ID < 68)" [style.display]="
                  !(control?.value?.ID > 55 && control?.value?.ID < 68) ? '' : 'block'
                ">
                      <input [class.form-control]="(!(control?.value?.ID > 55 && control?.value?.ID < 68))"
                        [class.customInput]="(!(control?.value?.ID > 55 && control?.value?.ID < 68))"
                        [class.input-generic]="(!(control?.value?.ID > 55 && control?.value?.ID < 68))" [id]="control?.value?.ID"
                        (keydown.space)="utilService.restrictLeadingSpace($event)" autocomplete="off"
                        placeholder="{{ control?.value?.PLACEHOLDER }}" formControlName="ANSWERS" onlynumber
                        max="{{ control?.value?.MAX_VALUE }}" min="{{ control?.value?.MIN_VALUE }}"
                        maxlength="{{ control?.value?.MAX_VALUE?.length }}"
                        [ngClass]="{errorInputBorder: hasError(controlIndex, 'number') && isTouched(controlIndex)}" />
                      <div class="d-flex pt-2" id="error_message_{{ control?.value?.ID }}"
                        [ngClass]="hasError(controlIndex, 'number') && isTouched(controlIndex) ? 'visible' : 'invisible'"
                        [style.display]="hasError(controlIndex, 'number') && isTouched(controlIndex) ? '' : 'none !important'">
                        <img src="../../../assets/images/exclamation-circle.svg" alt="Error" class="align-self-start" />
                        <span class="error px-1" *ngIf="hasError(controlIndex, 'number')">
                          Maximale toegestane waarde is
                          {{ control?.value?.MAX_VALUE }}
                        </span>
                      </div>
                    </div>
                    <ng-template #regularNumberInput>
                      <input class="form-control customInput input-generic" [id]="control?.value?.ID"
                        (keydown.space)="utilService.restrictLeadingSpace($event)" autocomplete="off"
                        placeholder="{{ control?.value?.PLACEHOLDER }}" formControlName="ANSWERS" onlynumber
                        max="{{ control?.value?.MAX_VALUE }}" min="{{ control?.value?.MIN_VALUE }}"
                        maxlength="{{ control?.value?.MAX_VALUE?.length }}"
                        [ngClass]="{errorInputBorder: hasError(controlIndex, 'number') && isTouched(controlIndex)}" />
                      <div class="d-flex pt-2" id="error_message_{{ control?.value?.ID }}"
                        [ngClass]="hasError(controlIndex, 'number') && isTouched(controlIndex) ? 'visible' : 'invisible'"
                        [style.display]="hasError(controlIndex, 'number') && isTouched(controlIndex) ? '' : 'none !important'">
                        <img src="../../../assets/images/exclamation-circle.svg" alt="Error" class="align-self-start" />
                        <span class="error px-1" *ngIf="hasError(controlIndex, 'number')">Maximale toegestane waarde is
                          {{ control?.value?.MAX_VALUE }}</span>
                      </div>
                    </ng-template>
                  </fieldset>
                  <div *ngSwitchCase="control.value.TYPE === 'CHECKBOX'" formArrayName="answerlabel">
                    <div *ngFor="let labelValue of control.get('answerlabel').controls; let idx = index"
                      class="d-block d-md-flex">
                      <fieldset [formGroupName]="idx" class="checkbox-input-label">
                        <label class="label w-100">
                          <input type="checkbox" formControlName="ANSWER" [checked]="labelValue.get('ANSWER').value"
                            (change)="onAnsweringCheckbox(control.value, idx)" />
                          {{ labelValue.get('LABEL').value }}
                        </label>
                      </fieldset>
                    </div>
                  </div>
                  <div *ngSwitchDefault>
                    <p>

                      I am default
                    </p>
                  </div>
                </div>
              </td>
            </ng-container>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </form>
</div>