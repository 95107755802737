import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search',
})
export class SearchPipe implements PipeTransform {
  transform(selectOptions: any, searchTerm?: any): any {
    if (!searchTerm) return selectOptions;
    if (!selectOptions || !selectOptions.length) return selectOptions;
    return selectOptions.filter((option: any) => {
      return option?.NAME?.toLowerCase().includes(searchTerm?.toLowerCase())
    });
  }
}
