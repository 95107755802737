<div class="tabs-header">
  <ul ngbNav #nav="ngbNav" class="nav-pills flex-row" (navChange)="onNavChange($event)"
    [ngClass]="{ 'px-4 mx-3': userType === 'client' }">
    <li id="be-goal-tab" [ngbNavItem]="'be-goal-tab'" class="me-4 pe-2">
      <a ngbNavLink>
        <img src="../../../../../assets/images/be-goal.svg" class="me-2" alt="Goal" *ngIf="userType === 'consultant'" />
        <span>
          <span *ngIf="userType === 'consultant'">{{ utilService.padWithLeadingZeros(selectedBEGoal?.ID) }}:</span>
          {{ selectedBEGoal?.TITLE }}</span>
      </a>
      <ng-template ngbNavContent>
        <p class="mb-4 pb-3" *ngIf="userType === 'consultant'">
          <!-- <img
            src="../../../assets/images/beGoal-scan-indication.svg"
            alt="be-goal-risk"
            class="me-3"
          /> -->
          <app-risk-levels [beGoal]="selectedBEGoal" [width]="'24'"></app-risk-levels>

          <span class="scan-level"> Risico</span>
        </p>
        <div *ngIf="userType === 'client'">
          <div class="goal-info mx-4 px-3 my-4">
            <img src="../../../assets/images/{{ selectedBEGoal?.TITLE }}.svg" [alt]="selectedBEGoal?.TITLE"
              class="me-3" />
            <span class="title">{{ selectedBEGoal?.TITLE }}</span>
          </div>
          <div class="risk-result d-flex flex-column">
            <div class="goal-info" [ngClass]="{ 'mt-4 pt-3 ': userType === 'client' }">
              <p class="title">U loopt hier een
                <strong>{{
                  utilService.getRiskLevelText(selectedBEGoal?.RISK_LEVEL)
                  }}</strong>
            </div>

            <div *ngIf="automatedAnswers?.length" [ngClass]="{ 'pt-3 ': userType === 'client' }">
              <span class="w-50 me-auto mb-3 mb-md-0 title">Uw risico is
                <strong>{{utilService.getRiskLevelText(selectedBEGoal?.RISK_LEVEL)?.split('
                  ')?.[0]}}</strong> omdat:
              </span>
              <p class="title"></p>
              <ul class="list-group m-3">
                <li class="" *ngFor="let answer of automatedAnswers">{{ answer }}</li>
              </ul>
            </div>
            <!-- <button class="button-primary-orange mx-auto mx-md-0">
              Start assessment
            </button> -->
          </div>
        </div>
        <div class="consultant-comment-forClient" *ngIf="userType === 'client' && selectedBEGoal?.NOTE">
          <p class="mb-4 pb-3">Toelichting uitkomst</p>
          <span>{{ selectedBEGoal?.NOTE }}</span>
        </div>
        <div class="goal-info" [ngClass]="{ 'm-4 p-3': userType === 'client' }">
          <p class="title">Over dit doel</p>
          <markdown>
            <span class="goal-text">
              <p>
                {{ selectedBEGoal?.COMPLIANCE_DESCRIPTION }}
              </p>
            </span>
          </markdown>
        </div>
        <hr class="divider" />
        <div class="goal-info" [ngClass]="{ 'mx-4 px-3 mt-4 pt-3 ': userType === 'client' }">
          <p class="title">Hoe te voldoen?</p>
          <markdown>
            <span class="goal-text">
              <p>
                {{ selectedBEGoal?.GOAL_DESCRIPTION }}
              </p>
            </span>
          </markdown>
        </div>
        <hr class="divider" *ngIf="userType === 'consultant'" />
        <div class="goal-info consultant-comment" *ngIf="userType === 'consultant'">
          <p class="title d-flex justify-content-between align-items-center">
            <span>Toelichting uitkomst</span><span><app-context-menu
                *ngIf="selectedBEGoal?.NOTE && menuSelected?.dataKey !== 'edit'" [menuOptions]="menuOptions"
                (menuOptionSelected)="onMenuOptionSelected($event)"></app-context-menu></span>
          </p>
          <textarea class="text-area mt-2 mb-4" placeholder="Voeg een toelichting toe..." [(ngModel)]="comment"
            (ngModelChange)="addingComment($event)" *ngIf="
              !selectedBEGoal?.NOTE ||
              (menuSelected?.dataKey === 'edit' && selectedBEGoal?.NOTE)
            "></textarea>
          <span class="goal-text" *ngIf="selectedBEGoal?.NOTE && menuSelected?.dataKey !== 'edit'">{{
            selectedBEGoal?.NOTE }}</span>
        </div>
      </ng-template>
    </li>
    <li id="sdg-tab" [ngbNavItem]="'sdg-tab'" class="me-4 pe-2">
      <a ngbNavLink>
        <img src="../../../../../assets/images/sdg.svg" alt="SDG" class="me-2" *ngIf="userType === 'consultant'" />
        <span>Sustainable Development Goals</span>
      </a>
      <ng-template ngbNavContent>
        <p [ngClass]="{ 'px-3 mx-4': userType === 'client' }">
          <app-be-goal-sdg [selectedGoal]="selectedBEGoal" [userType]="userType"></app-be-goal-sdg>
        </p>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav" class="mt-4"></div>
</div>