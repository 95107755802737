import { Component, OnInit } from '@angular/core';
import { state, style, trigger } from '@angular/animations';
import { ToastService, TOAST_STATE } from 'src/app/shared/services/toast.service';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  animations: [
    trigger('toastTrigger', [
      state('open', style({ display: 'block' })),
      state('close', style({ display: 'none' })),
    ]),
  ],
})
export class ToastComponent implements OnInit {
  toastClass!: string[]; // Class lists can be added as an array
  toastMessage!: string; // This is the string the template is already bound to
  showsToast!: boolean; // This is what toggles the component to show or hide
  toastIconUrl!: string;

  constructor(public toastService: ToastService) {} // We inject the toast.service here as 'public'
  ngOnInit(): void {
    this.toastService.toastState$.subscribe((toastState) => {
      const state = toastState || TOAST_STATE.success;
      this.toastIconUrl = `../../../assets/images/${state}.svg`;
  });
  }

  // We'll add this to the dismiss button in the template
  dismiss(): void {
    this.toastService.dismissToast();
  }
}
