<div ngbDropdown  class="d-inline-block">
    <button class="btn btn-sm" id="dropdownMenuButton" ngbDropdownToggle>
        <img src="assets/images/three-dots-horizontal.svg" alt="Menu" />
    </button>
    <div ngbDropdownMenu  class="dropdown-menu" aria-labelledby="dropdownMenuButton" data-poper-placement="bottom-end">
        <label class="label p-2 dropdown-item" ngbDropdownItem (click)="onRenamePressed()">
            <img src="assets/images/rename.svg" alt="Rename" />
            Naam wijzigen
        </label>
        
        <label *ngIf="isDeleteAllowed" class="label p-2 dropdown-item" ngbDropdownItem (click)="onDeletePressed()">
            <img src="assets/images/delete.svg" alt="delete button" />
            Verwijderen
        </label>    
    </div>
</div>