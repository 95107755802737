<span class="riskImages" *ngFor="let risk of riskLevels; let id = index">
  <img
    [width]="width"
    class="risk-images"
    [ngClass]="{ 'me-2': width === '24' }"
    src="../../../assets/images/{{
      (beGoal?.RISK_LEVEL === risk?.key ? risk.key : 'risk-indication') + '.svg'
    }}"
    [alt]="risk?.key"
  />
  <img
    class="industry-risk-level"
    *ngIf="beGoal?.INDUSTRY_RISK_LEVEL === risk?.key"
    src="../../../assets/images/dot.svg"
    [alt]="risk?.key"
    [width]="width === '12' ? '4' : '8'"
    [ngClass]="{ dotPosition: width === '24' }"
  />
</span>
