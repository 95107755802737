import { Component, Input, inject, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { UtilService } from 'src/app/shared/services/util.service';
import { a4SizeInPixels } from 'src/app/shared/utilities/printable-pdf';

@Component({
  selector: 'app-printable-dashboard',
  templateUrl: './printable-dashboard.component.html',
  styleUrls: ['./printable-dashboard.component.scss']
})

export class PrintableDashboardComponent implements AfterViewInit {

  @ViewChild('blockContainer', { read: ElementRef }) blockContainer!: ElementRef;

  @Input() deepdiveId: any = '';
  @Input() previewDeepdiveDetails: any;
  @Input() charts: any;
  @Input() deepdiveName: any;
  @Input() deepdiveYear: any;
  @Input() chartTotal: any = [];
  @Input() customerId: any;
  @Input() notesTitle: any;
  @Input() chartTitle: any;
  @Input() whyActionDescription: any;
  @Input() whatActionDescription: any;
  blockId: any = 0;
  isDistributed: boolean = false;

  public utilService = inject(UtilService);
  constructor() { }


  ngOnInit(): void { }

  ngAfterViewInit(): void {

    this.handlePdfPages();
  }

  handlePdfPages(): void {
    if (!this.isDistributed) {
      this.distributeBlocksIntoPages();
      this.isDistributed = true;
    }
  }

  distributeBlocksIntoPages(): void {

    const maxPageHeight = 700; // Maximum page height in pixels
    let pageId = 1;
    let currentPage = this.createNewPage(`page-${pageId}`);
    let currentHeight = 0;
    let actionsLength = this.previewDeepdiveDetails?.ACTIONS?.length;
    const totalblocks = 13 + (actionsLength ? actionsLength - 1 : 0);
    for (let i = 1; i <= totalblocks; i++) {

      const block = document.getElementById(`block-${i}`);

      if (block) {
        const computedStyles = window.getComputedStyle(block);
        const computedHeight = parseInt(computedStyles.marginTop) +
          parseInt(computedStyles.marginBottom) +
          parseInt(computedStyles.paddingTop) +
          parseInt(computedStyles.paddingBottom) +
          parseInt(computedStyles.borderTopWidth) +
          parseInt(computedStyles.borderBottomWidth);
        const blockHeight = block.offsetHeight + computedHeight;

        if (currentHeight + blockHeight <= maxPageHeight) {
          currentPage.appendChild(block);
          currentHeight += blockHeight;
        } else {
          // Create a new page and add the block to it
          pageId = pageId + 1;
          if (i < totalblocks) {
            currentPage.style.breakAfter = 'page';
          }
          currentPage = this.createNewPage(`page-${pageId}`);
          currentPage.appendChild(block);
          currentHeight = blockHeight;
        }
      }
    }

    setTimeout(() => {
      const lastPageId = pageId;
      let currentPage = this.createNewPage(`page-${pageId+1}`);
      let currentHeight = 0;
      let actionsLength = this.previewDeepdiveDetails?.ACTIONS?.length;
      const totalblocks = 13 + (actionsLength ? actionsLength - 1 : 0);
      for (let i = 1; i <= totalblocks; i++) {
        const block = document.getElementById(`block-${i}`);
        if (block) {
          const blockHeight = block.offsetHeight;
          if (currentHeight + blockHeight <= maxPageHeight) {
            currentPage.appendChild(block);
            currentHeight += blockHeight;
          } else {
            // Create a new page and add the block to it
            pageId = pageId + 1;
            if (i < totalblocks) {
              currentPage.style.breakAfter = 'page';
            }
            currentPage = this.createNewPage(`page-${pageId}`);
            currentPage.appendChild(block);
            currentHeight = blockHeight;
          }

        }

      }

      for( let i=1; i<=lastPageId; i++){
        const elementsToDelete = document.getElementById(`page-${i}`);
        elementsToDelete?.remove();
      }
    }, 100)
  }


  // Helper function to create a new page div
  createNewPage(id: string) {
    const newPage = document.createElement("div");
    newPage.id = id;
    newPage.style.paddingLeft = '20px';
    newPage.style.paddingRight = '20px';
    newPage.style.margin = '50px';
    this.blockContainer.nativeElement.appendChild(newPage);
    return newPage;
  }

}
