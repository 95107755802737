<div ngbDropdown [open]="isDropdownOpen">
    <button class="btn btn-sm" id="dropdownMenuButton" (click)="toggleDropdown()">
        <img src="assets/images/filter.svg" alt="Descending" />
    </button>
    <div ngbDropdownMenu  display="dynamic" class="dropdown-menu dropdown-wrapper" aria-labelledby="dropdownMenuButton">
        <div class="title-wrapper">
            <h5 class="label">Filter klanten</h5>
            <button type="button" class="btn-close" aria-label="Close" (click)="closeDropdown()"></button>
        </div>
        <div class="custom-switch m-4 mt-0 mb-0">
            <label class="switch">
                <input type="checkbox" [(ngModel)]="isAssignedToMe" (ngModelChange)="onChangeSwitch($event)">
                <span class="slider round"></span>
            </label>
            <label class="label center m-4">Mijn klanten</label>
        </div>
        <form class="center-align">
            <div class="form-group me-0 pe-3 mb-2">
                <label class="label">Toegewezen aan</label>
                <searchable-dropdown [formGroup]="filterForm" [selectOptions]="consultantList" id="consultant"
                    controlName="consultant" (selectedOption)="onOptionSelected($event)" 
                    [readOnly]="isAssignedToMe" [disabled]="isAssignedToMe"
                    ></searchable-dropdown>
            </div>
            <div class="form-group me-0 pe-3 mb-2">
                <label class="label">Kring</label>
                <searchable-dropdown [formGroup]="filterForm" [selectOptions]="regionsList" id="region"
                    controlName="region" (selectedOption)="onOptionSelected($event)"></searchable-dropdown>
            </div>
            <div class="form-group me-0 pe-3">
                <label class="label">Status</label>
                <searchable-dropdown [formGroup]="filterForm" [selectOptions]="statusList" id="status"
                    controlName="status" (selectedOption)="onOptionSelected($event)"></searchable-dropdown>
            </div>
        </form>
        <div class="center-align m-4">
            <button type="button" class="button-primary-orange mb-2" (click)="applyFilters()">Filteren</button>
            <button type="button" class="btn btn-link-primary " (click)="resetFilters()">Filters wissen</button>
        </div>
    </div>
</div>