<div
  class="deepdive-dashboard"
  *ngIf="
    !(loaderService?.loaderState | async)?.show &&
    !(errorService?.errorState | async)?.error
  "
>
  <div class="consultant-deepdive-main-layout">
    <div class="header">
      <span class="title" *ngIf="!isConsultantPreview"
        >Assessment: {{ selectedBEGoal?.TITLE }}</span
      >
      <span class="title" *ngIf="isConsultantPreview"
        >Client dashboard Preview</span
      >
    </div>
    <p *ngIf="!isConsultantPreview">
      Bekijk of bewerk de antwoorden en opgegeven data van de klant. Vul daarna
      het dashboard in met de huidige situatie van de klant, waarom het anders
      moet, een verbeterdoel en voorgestelde stappen om te verbeteren. lik op
      "Dashboard publiceren" om het dashboard zichtbaar te maken voor de klant.
    </p>
    <ng-container>
      <div class="d-flex col-12 col-md-8">
        <div [ngClass]="{ shadow: !isConsultantPreview }">
          <div class="steps-block mt-5 p-5" *ngIf="!isConsultantPreview">
            <app-step-indicator [indicatorStepsType]="'consultant'">
            </app-step-indicator>
          </div>

          <ng-container
            *ngFor="
              let notesSectionDetail of notesSectionDetails;
              let index = index
            "
          >
            <div
              class="notes-container p-5"
              [style.background]="
                commentTabs[index].noteType !== 'PERFORMANCE' ? 'white' : ''
              "
            >
              <ng-container *ngIf="commentTabs[index].noteType !== 'ACTIONS'">
                <div style="text-align: center" class="mb-5">
                  <img
                    *ngIf="commentTabs[index].noteType === 'REGULATIONS'"
                    src="../../../assets/images/globe-leaves.svg"
                    alt="globe leaves icon"
                    style="display: inline-block"
                  />
                </div>
                <p class="company-name">
                  {{ getTitle(commentTabs[index].noteType) }}
                </p>
                <p class="description-text">
                  {{
                    commentTabs[index].noteType === "PERFORMANCE"
                      ? getBeDescriptionText(deepdiveYear, selectedBEGoal.ID)
                      : getDescription(commentTabs[index].noteType)
                  }}
                </p>
              </ng-container>
              <div
                *ngIf="commentTabs[index].noteType === 'ACTIONS'"
                class="horizontal-ruler"
              ></div>
              <app-deepdive-notes
                [type]="commentTabs[index].noteType"
                [notesDetails]="notesSectionDetail[index + 1]"
                [customerID]="customerId"
                [breakEvenGoal]="selectedBEGoal"
                [selectedType]="commentTabs[index]"
                [year]="deepdiveYear"
                [isPublishView]="isPublishView"
                [deepdiveCharts]="deepdiveCharts"
              ></app-deepdive-notes>
            </div>
          </ng-container>
          <div class="m-4 pb-5" *ngIf="!isConsultantPreview">
            <button
              class="mt-5 me-4 button-secondary-white"
              (click)="navigate('previous')"
            >
              <span>Terug</span>
            </button>
            <button
              class="mt-5 button-primary-orange"
              (click)="isPublishView ? publishDashboard() : navigate('next')"
              [disabled]="isPublishView && stateOfDashboard === 'PUBLISHED'"
            >
              <span>{{
                isPublishView ? "Dashboard publiceren" : "Volgende"
              }}</span>
            </button>
            <a
              *ngIf="isPublishView"
              class="cta-text"
              (click)="openDashboardPreview()"
            >
              Toon dashboard klant
              <img
                class="ps-2"
                src="../../../assets/images/open-external.svg"
                alt="open dashboard preview icon"
              />
            </a>
          </div>
        </div>
        <div
          *ngIf="
            !isConsultantPreview &&
            (recommendationList?.COMPANIES_WITH_SAME_SECTOR?.length ||
              recommendationList?.COMPANIES_WITH_SAME_ENERGY_USE?.length ||
              recommendationList?.OTHER_COMPANIES?.length)
          "
          class="shadow col-12 col-md-4 company-list-container"
        >
          <p class="related-company-header">
            Resultaten van vergelijkbare bedrijven
          </p>
          <p>
            Bekijk hier resultaten van verhelijkbare bedrijven die nuttig zouden
            kunnen zijn. Hieronder zie je of het bedrijf een match heeft op
            (sub)-sector, energiegebruik, of anders. Bij 'andere klanten' worden
            de meest recent gepubliceerde dashboards gedeeld. 
            <br/>
            Deel geen namen of gevoelige informatie van andere bedrijven met uw klant.
          </p>
          <ng-container
            *ngIf="recommendationList?.COMPANIES_WITH_SAME_SECTOR?.length"
          >
            <p class="related-company-title">
              Klanten in dezelfde (sub)-sector
            </p>
            <div class="company-container">
              <p
                *ngFor="
                  let company of recommendationList?.COMPANIES_WITH_SAME_SECTOR
                "
                class="regulation-border"
                (click)="onCompanyNameClick(company)"
              >
                <a class="related-company-name">
                  {{ company?.COMPANY_NAME }}
                  <img
                    src="../../../assets/images/open-link.svg"
                    alt="open-link icon"
                  />
                </a>
              </p>
            </div>
          </ng-container>
          <ng-container
            *ngIf="recommendationList?.COMPANIES_WITH_SAME_ENERGY_USE?.length"
          >
            <p class="related-company-title">
              Klanten met vergelijkbaar energiegebruik
            </p>
            <div class="company-container">
              <p
                *ngFor="
                  let company of recommendationList?.COMPANIES_WITH_SAME_ENERGY_USE
                "
                class="regulation-border"
                (click)="onCompanyNameClick(company)"
              >
                <a class="related-company-name">
                  {{ company?.COMPANY_NAME }}

                  <img
                    src="../../../assets/images/open-link.svg"
                    alt="open-link icon"
                  />
                </a>
              </p>
            </div>
          </ng-container>
          <ng-container *ngIf="recommendationList?.OTHER_COMPANIES?.length">
            <p class="related-company-title">Andere klanten</p>
            <div class="company-container">
              <p
                *ngFor="let company of recommendationList?.OTHER_COMPANIES"
                class="regulation-border"
                (click)="onCompanyNameClick(company)"
              >
                <a class="related-company-name">
                  {{ company?.COMPANY_NAME }}
                  <img
                    src="../../../assets/images/open-link.svg"
                    alt="open-link icon"
                  />
                </a>
              </p>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
</div>
<app-loader></app-loader>
<app-error [errorType]="'consultant'"></app-error>
