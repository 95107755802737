import { Component, inject, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { cloneDeep } from 'lodash';
import { ConsultantService } from 'src/app/shared/services/consultant.service';
import { ModalService } from 'src/app/shared/services/modal.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { UtilService } from 'src/app/shared/services/util.service';
import { COMMENT_MENU_OPTIONS } from 'src/app/shared/utilities/constants';
import { ModalComponent } from '../modals/modal/modal.component';

@Component({
  selector: 'app-deepdive-action',
  templateUrl: './deepdive-action.component.html',
  styleUrls: ['./deepdive-action.component.scss'],
})
export class DeepdiveActionComponent implements OnInit {
  firstEditor: any = '';
  firstEditorSection: any = '';
  modal: any;
  regulationsForm!: FormGroup;
  formBuilder = inject(FormBuilder);
  menuOptions: any = cloneDeep(COMMENT_MENU_OPTIONS);
  @Input() type: any;
  @Input() breakEvenGoal: any;
  private modalService = inject(ModalService);
  private toastService = inject(ToastService);
  private utilService = inject(UtilService);
  private consultantService = inject(ConsultantService);

  constructor() {}

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.regulationsForm = this.formBuilder.group({
      regulationSections: this.formBuilder.array([this.addSectionEditor()]),
    });
  }

  addSectionEditor() {
    return this.formBuilder.group({
      TEXT: this.formBuilder.control(''),
      ADDED: this.formBuilder.control(false),
      SAVED: this.formBuilder.control(false),
    });
  }

  addRegulationSection() {
    this.regulationsSections.push(this.addSectionEditor());
  }

  get regulationsSections() {
    return this.regulationsForm.controls['regulationSections'] as FormArray;
  }

  saveSection(section: any, index: number) {
    this.regulationsSections?.at(index)?.get('ADDED')?.setValue(true);
  }

  onMenuOptionSelected(option: any, section: any, index: number) {
    if (option?.dataKey === 'edit') {
      this.regulationsSections?.at(index)?.get('ADDED')?.setValue(false);
    } else {
      let title = 'Toelichting verwijderen';
      let description =
        'Weet u zeker dat u de toelichting wilt verwijderen? Deze actie kan niet ongedaan gemaakt worden.';
      let buttonLabel = 'Verwijderen';
      this.openModal(title, description, buttonLabel, 'deleteText');
      return;
    }
  }

  /**
   * It opens the confirmation modal
   */
  openModal(
    title?: string,
    description?: string,
    buttonLabel?: string,
    modalType?: string,
    section?: any
  ) {
    this.modal = this.modalService.open(ModalComponent, {
      backdrop: 'static',
      windowClass: 'submitAnswersModal',
      centered: true,
    });
    this.modal.componentInstance.data = {
      title,
      description,
      buttonLabel,
      showCancelButton: true,
    };
    this.modal.componentInstance.actionButtonClicked.subscribe(
      (actionType: string) => {
        if (actionType === 'Verwijderen') {
          this.deleteTextInfo(section);
          return;
        }
      }
    );
  }

  deleteTextInfo(section?: any) {}
}
