import { Component, inject, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { cloneDeep } from 'lodash';
import { ConsultantService } from 'src/app/shared/services/consultant.service';
import { ErrorService } from 'src/app/shared/services/error.service';
import { ModalService } from 'src/app/shared/services/modal.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { UtilService } from 'src/app/shared/services/util.service';
import { COMMENT_MENU_OPTIONS } from 'src/app/shared/utilities/constants';
import { ModalComponent } from '../modals/modal/modal.component';

@Component({
  selector: 'app-deepdive-goal',
  templateUrl: './deepdive-goal.component.html',
  styleUrls: ['./deepdive-goal.component.scss'],
})
export class DeepdiveGoalComponent implements OnInit {
  deepdiveGoalsForm!: FormGroup;
  menuOptions: any = cloneDeep(COMMENT_MENU_OPTIONS);
  menuSelected: any = {};
  modal: any;
  @Input() type: any;
  @Input() breakEvenGoal: any;
  @Input() customerID: any;
  formBuilder = inject(FormBuilder);
  private errorService = inject(ErrorService);
  private modalService = inject(ModalService);
  private toastService = inject(ToastService);
  public utilService = inject(UtilService);
  private consultantService = inject(ConsultantService);

  constructor() {}

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.deepdiveGoalsForm = this.formBuilder.group({
      GOALNUMBER: new FormControl('', [Validators.max(0), Validators.min(100)]),
      TEXT: new FormControl(''),
      ADDED: this.formBuilder.control(false),
      SAVED: this.formBuilder.control(false),
      LOADER: this.formBuilder.control(false),
    });
  }

  deleteTextInfo(section?: any) {
    this.errorService.hide();
    this.deepdiveGoalsForm?.get('LOADER')?.setValue(true);
    const postObj = {
      [this.type]: [
        {
          ID: 1,
        },
      ],
    };
    this.consultantService
      .addOrEditDeepdiveNotes(
        this.customerID,
        this.breakEvenGoal.ID,
        this.type,
        postObj
      )
      .subscribe({
        next: (response: any) => {
          this.deepdiveGoalsForm?.get('TEXT')?.reset();
          this.deepdiveGoalsForm?.get('ADDED')?.setValue(false);
        },
        error: (error) => {
          // handle error
          this.deepdiveGoalsForm?.get('LOADER')?.setValue(false);
          this.errorService.show();
        },
        complete: () => {
          //Request completed
          this.deepdiveGoalsForm?.get('LOADER')?.setValue(false);
        },
      });
  }

  addGoalSection(section: any) {
    this.errorService.hide();
    this.deepdiveGoalsForm?.get('LOADER')?.setValue(true);
    const postObj = {
      [this.type]: [
        {
          ID: 1,
          TEXT: this.deepdiveGoalsForm?.get('TEXT')?.value,
          NUMBER: this.deepdiveGoalsForm?.get('GOALNUMBER')?.value,
        },
      ],
    };
    this.consultantService
      .addOrEditDeepdiveNotes(
        this.customerID,
        this.breakEvenGoal.ID,
        this.type,
        postObj
      )
      .subscribe({
        next: (response: any) => {
          this.deepdiveGoalsForm?.get('ADDED')?.setValue(true);
        },
        error: (error) => {
          // handle error
          this.deepdiveGoalsForm?.get('LOADER')?.setValue(false);
          this.errorService.show();
        },
        complete: () => {
          //Request completed
          this.deepdiveGoalsForm?.get('LOADER')?.setValue(false);
        },
      });
  }

  onMenuOptionSelected(option: any, section: any) {
    this.menuSelected = option;
    if (option?.dataKey === 'edit') {
      this.deepdiveGoalsForm?.get('ADDED')?.setValue(false);
      this.addGoalSection(section);
    } else {
      let title = 'Toelichting verwijderen';
      let description =
        'Weet u zeker dat u de toelichting wilt verwijderen? Deze actie kan niet ongedaan gemaakt worden.';
      let buttonLabel = 'Verwijderen';
      this.openModal(title, description, buttonLabel, 'deleteText', section);
      return;
    }
  }

  /**
   * It opens the confirmation modal
   */
  openModal(
    title?: string,
    description?: string,
    buttonLabel?: string,
    modalType?: string,
    section?: any
  ) {
    this.modal = this.modalService.open(ModalComponent, {
      backdrop: 'static',
      windowClass: 'submitAnswersModal',
      centered: true,
    });
    this.modal.componentInstance.data = {
      title,
      description,
      buttonLabel,
      showCancelButton: true,
    };
    this.modal.componentInstance.actionButtonClicked.subscribe(
      (actionType: string) => {
        if (actionType === 'Verwijderen') {
          this.deleteTextInfo(section);
          return;
        }
      }
    );
  }
}
