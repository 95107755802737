import { Component, inject, Input, OnInit } from '@angular/core';
import { cloneDeep } from 'lodash';
import { UtilService } from 'src/app/shared/services/util.service';
import { RISK_LEVELS } from 'src/app/shared/utilities/constants';

@Component({
  selector: 'app-risk-scan-legenda',
  templateUrl: './risk-scan-legenda.component.html',
  styleUrls: ['./risk-scan-legenda.component.scss'],
})
export class RiskScanLegendaComponent implements OnInit {
  @Input() riskType!: string;
  @Input() beGoalsList!: [];
  public utilService = inject(UtilService);
  riskLevels: any = cloneDeep(RISK_LEVELS);

  constructor() {}

  ngOnInit(): void {}
}
