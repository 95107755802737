import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { clone, cloneDeep, filter, find, has, set } from 'lodash';
import { AuthService } from 'src/app/core/services/auth.service';
import { ConsultantService } from 'src/app/shared/services/consultant.service';
import { ErrorService } from 'src/app/shared/services/error.service';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { UtilService } from 'src/app/shared/services/util.service';
import {
  REGEX,
  SELECTED_CLIENT_MENU,
} from 'src/app/shared/utilities/constants';
import { regions } from 'src/app/shared/constants';
@Component({
  selector: 'new-app-client-form',
  templateUrl: './new-client-form.component.html',
  styleUrls: ['./new-client-form.component.scss'],
})
export class NewClientFormComponent implements OnInit {
  @Input() form: FormGroup | AbstractControl | any;
  @Input() formData!: any;
  @Input() editClient!: boolean;
  @Input() sectorData!: any;
  @Input() consultantList: any;
  @Input() isAnyDeepDiveInProgress!: boolean;

  @Output() onRequestSuccess = new EventEmitter<any>();
  @Output() deleteClient = new EventEmitter<any>();

  menuOptions: any = cloneDeep(SELECTED_CLIENT_MENU);
  sectorList: any = [];
  selectedSector: any = {};
  selectedSubSector: any = {};
  selectedBusinessActivity: any = {};
  subSectorList: any = [];
  businessActivityList: any = [];
  selectedMenu: any = '';
  clientRegionsList: any = [];
  selectedClientRegion: any = {};
  assignedConsultant: any = {};
  locationAnswer: boolean = false; 
  noOfLocationsInvalid: boolean = false;
  noOfLocationsInitialValue: any;
  noOfLocationsInvalidDec: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    public utilService: UtilService,
    private consultantService: ConsultantService,
    public authService: AuthService,
    private toastService: ToastService,
    public loaderService: LoaderService,
    public errorService: ErrorService
  ) { }

  validateNoOfLocations() {
    if (this.form.get('noOfLocations').value == '') {
      return;
    }
    const noOfLocations = Number(this.form.get('noOfLocations').value);
    if (noOfLocations < 2 || noOfLocations > 100) {
      this.noOfLocationsInvalid = true;
    } else {
      this.noOfLocationsInvalid = false;
    }
    if(this.editClient){
      if(this.isAnyDeepDiveInProgress && noOfLocations < this.noOfLocationsInitialValue){
        this.noOfLocationsInvalidDec = true;
      } else {
        this.noOfLocationsInvalidDec = false;
      }
    }
  }

  handleLocationAnswer(value: boolean) {
    this.locationAnswer = value;
  }
  
  ngOnInit(): void {
    //Edit client form
    this.clientRegionsList = clone(regions);
    this.sectorList = clone(this.sectorData)
    this.locationAnswer = this.form?.controls?.['hasMultipleLocations']?.value;
    this.validateNoOfLocations();
    if (this.editClient) {
      this.selectedSectorInfo();
      this.noOfLocationsInitialValue = this.form.get('noOfLocations').value;
    } else {
      this.clientRegionsList.forEach((option: any) => delete option.isSelected);
    }

  }

  selectedSectorInfo() {
    if (!this.sectorList?.length) {
      return;
    }
    this.sectorList = filter(this.sectorList, [
      'NAME',
      this.form?.controls?.['sector']?.value,
    ]);
    this.sectorList[0].isSelected = true;
    this.authService.setSector(this.sectorList?.[0]);
    this.subSectorList = filter(this.sectorList?.[0]?.SUB_SECTORS, [
      'NAME',
      this.form?.controls?.['subSector']?.value,
    ]);
    this.subSectorList[0].isSelected = true;
    this.authService.setSubsector(this.subSectorList?.[0]);
    this.businessActivityList = filter(
      this.subSectorList?.[0]?.BUSINESS_ACTIVITIES,
      ['NAME', this.form?.controls?.['businessActivity']?.value]
    );
    this.businessActivityList[0].isSelected = true;
    this.authService.setBusinessActivity(this.businessActivityList?.[0]);
    this.selectedBusinessActivity = this.businessActivityList[0];

    const clientRegion = this.form?.controls?.['clientRegion']?.value;
    if (clientRegion) {
      this.clientRegionsList.forEach((region: any) => {
        if (region.REGION_ID === clientRegion || region.NAME === clientRegion) {
          region.isSelected = true;
          this.authService.setClientRegion(region);
          this.selectedClientRegion = region;
        }
      })

    }
    const assignedConsultantValue = this.form?.controls?.['assignedConsultant']?.value;
    if (assignedConsultantValue) {
      this.consultantList.forEach((consultant: any) => {
        if (consultant.EMAIL === assignedConsultantValue) {
          consultant.isSelected = true;
          this.authService.setAssignedConsultant(consultant);
          this.assignedConsultant = consultant;
        }
      })
    }
  }

  //Resetting the form
  resetForm() {
    this.form.reset();
    this.authService.setSector(null);
    this.authService.setSubsector(null);
    this.authService.setBusinessActivity(null);
    this.authService.setClientRegion(null);
    this.authService.setAssignedConsultant(null);

    this.selectedSector = {};
    this.selectedSubSector = {};
    this.selectedBusinessActivity = {};
    this.selectedClientRegion = {};
    this.sectorData.forEach((option: any) => delete option.isSelected);
    this.sectorList = [];
    this.subSectorList = [];
    this.businessActivityList = [];
    // this.clientRegionsList = [];
    this.clientRegionsList.forEach((option: any) => delete option.isSelected);
    // this.consultantList = [];
  }

  getSectorList() {
    this.authService.getSector().subscribe((subsectors: any) => {
      this.subSectorList = subsectors?.SUB_SECTORS;
    });
  }

  getBussinessActivityList() {
    this.authService.getSubSector().subscribe((bussActivities: any) => {
      this.businessActivityList = bussActivities?.BUSINESS_ACTIVITIES;
    });
  }

  /**
   * Selected option
   * @param option - Selected option
   */
  onOptionSelected(option: any) {
    this.selectedSector = has(option, 'SUB_SECTORS') ? option : {};
    this.selectedSubSector = has(option, 'BUSINESS_ACTIVITIES') ? option : {};
    if (has(option, 'ID')) {
      this.selectedBusinessActivity = option;
    } else if (has(option, 'REGION_ID')) {
      this.selectedClientRegion = option;
    } else if (has(option, 'EMAIL')) {
      this.assignedConsultant = option;
    }

    if (this.editClient && option === 'sector') {
      this.sectorList = this.sectorData;
    }
    this.getSectorList();
    this.getBussinessActivityList();
    this.form.value.sector
      ? this.form.get('subSector')?.enable()
      : this.form.get('subSector')?.disable();
    this.form.value.subSector
      ? this.form.get('businessActivity')?.enable()
      : this.form.get('businessActivity')?.disable();
  }

  selectMenuOption(option: any) {
    this.selectedMenu = option?.dataKey;
    if (option?.dataKey === 'edit') {
      this.form.get('sector')?.enable();
      this.form.get('subSector')?.enable();
      this.form.get('businessActivity')?.enable();
      this.form.get('clientRegion')?.enable();
      this.form.get('assignedConsultant')?.enable();
      if(!this.isAnyDeepDiveInProgress){
        this.form.get('hasMultipleLocations')?.enable();
      }
      this.form.get('noOfLocations')?.enable();
      this.form.get('year')?.enable();
    } else {
      this.deleteClient.emit();
    }
  }
}
