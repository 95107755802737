import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  public customerInfo = new BehaviorSubject(null);
  public riskScanDashboardState = new BehaviorSubject(null);
  public consultantOverviewFilters = new BehaviorSubject(null);
  public clientLocations = new BehaviorSubject(null);
  public csrdTopics = new BehaviorSubject(null);
  public deepDiveYear = new BehaviorSubject(null);
  public customerDashaboardState = new BehaviorSubject(null);

  setCustomerDashaboardState(value: any) {
    this.customerDashaboardState.next(value);
  }

  getCustomerDashaboardState(): Observable<any> {
    return this.customerDashaboardState.asObservable();
  }

  setDeepDiveYear(value: any) {
    this.deepDiveYear.next(value);
  }

  getDeepDiveYear(): Observable<any> {
    return this.deepDiveYear.asObservable();
  }

  setClientLocation(value: any) {
    this.clientLocations.next(value);
  }

  getClientLocation(): Observable<any> {
    return this.clientLocations.asObservable();
  }

  setCSRDTopics(value: any) {
    this.csrdTopics.next(value);
  }

  getCSRDTopics(): Observable<any> {
    return this.csrdTopics.asObservable();
  }

  setConsultantOverviewFilters(value: any) {
    this.consultantOverviewFilters.next(value);
  }

  getConsultantOverviewFilters(): Observable<any> {
    return this.consultantOverviewFilters.asObservable();
  }

  setCustomerInfo(value: any) {
    this.customerInfo.next(value);
  }

  getCustomerInfo(): Observable<any> {
    return this.customerInfo.asObservable();
  }

  setRiskScanDashboardState(value: any) {
    this.riskScanDashboardState.next(value);
  }

  getRiskScanDashboardState(): Observable<any> {
    return this.riskScanDashboardState.asObservable();
  }
}
