import { Component, VERSION } from '@angular/core';

@Component({
  selector: 'markdown-it',
  templateUrl: './markdown-it.component.html',
  styleUrls: ['./markdown-it.component.scss'],
})
export class MarkdownIt {
  angularVersion = VERSION.full;
  ngxMarkdownVersion = '16.0.0';

  markdown = `## Markdown __rulez__!
---

### Syntax highlight
\`\`\`typescript
const language = 'typescript';
\`\`\`

### Lists
1. Ordered list
2. Another bullet point
   - Unordered list
   - Another unordered bullet

### Blockquote
> Blockquote to the max`;
}
