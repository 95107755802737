<div class="be-goals-list mb-4"  #begoalsList>
  <div
    *ngFor="let goal of begoals; let idx = index"
    class="goal-block cursor"
    [ngClass]="{ active: goal.isSelected }"
    (click)="onSelectingBEGoal(goal)"
  >
    <div class="goal-info">
      <img
        src="../../../assets/images/{{ goal?.TITLE }}.svg"
        [alt]="goal?.TITLE"
        class="me-3"
      />
      <span class="title">{{ goal?.TITLE }}</span>
    </div>
    <div class="goal-indication">
      <app-risk-levels [beGoal]="goal" [width]="'24'"></app-risk-levels>
    </div>
  </div>
</div>
