<div class="current-performance-layout">
  <form [formGroup]="performanceForm">
    <div formArrayName="performanceSections">
      <ng-container
        *ngFor="
          let performanceSection of performanceSections?.controls;
          let index = index
        "
      >
        <div [formGroupName]="index">
          <p class="title d-flex justify-content-between align-items-center">
            <span> {{ performanceSection?.get("TITLE")?.value }}</span>
            <app-context-menu
              *ngIf="performanceSection?.get('ADDED')?.value"
              [menuOptions]="menuOptions"
              (menuOptionSelected)="
                onMenuOptionSelected($event, performanceSection, index)
              "
            ></app-context-menu>
          </p>
          <p
            class="deepdive-text-info"
            *ngIf="performanceSection?.get('ADDED')?.value"
            [innerHTML]="performanceSection?.get('TEXT')?.value"
          ></p>
          <app-text-editor
            *ngIf="!performanceSection?.get('ADDED')?.value"
            id="performanceSection_{{ index }}"
            [editorFormGroup]="performanceSection"
            controlName="TEXT"
          ></app-text-editor>

          <div
            class="add-buttons-block"
            *ngIf="!performanceSection?.get('ADDED')?.value"
          >
            <button
              class="cancel-button bg-white border-0"
              (click)="cancel(performanceSection, index)"
            >
              Cancel
            </button>
            <button
              class="button-primary-white"
              (click)="addPerformanceText(performanceSection, index)"
              [disabled]="!performanceSection?.get('TEXT')?.value"
              id="button_{{ index }}"
            >
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
                *ngIf="performanceSection?.get('LOADER')?.value"
              ></span>
              <span
                *ngIf="
                  !performanceSection?.get('LOADER')?.value &&
                  menuSelected?.dataKey !== 'edit'
                "
                >Toevoegen</span
              >
              <span
                *ngIf="
                  !performanceSection?.get('LOADER')?.value &&
                  menuSelected?.dataKey === 'edit'
                "
                >Opslaan</span
              >
            </button>
          </div>
          <hr class="ruler" *ngIf="index === 0" />
        </div>
      </ng-container>
    </div>
  </form>
</div>
