import { DeepdiveAssessmentTypes } from '../actionTypes';

const actions = {
  saveGroupDeepDiveAssessmentAnswers: (payload: any) => {
    return {
      type: DeepdiveAssessmentTypes.DEEPDIVE_ASSESSMENT,
      payload,
    };
  },
};

export default actions;
