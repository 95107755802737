import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NgbNav } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss'],
})
export class TabComponent implements OnInit {
  @ViewChild(NgbNav, { static: true })
  ngbNav!: NgbNav;
  @Input() links!: any[];
  tab: any = '';
  @Input()
  tabContent!: any[];
  @Output()
  selectedTab = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  setTab(tab: any) {
    this.tab = tab;
    this.selectedTab.emit(tab);
  }
}
