import { Component, OnInit } from '@angular/core';
import { waterReuseChartData, GHGEmissionChartData, GHGEmissionChartDataMultipleLocations, pieChartData, pieChartDataSingle, stackedBarChartData } from './data';
@Component({
  selector: 'app-chart-demo',
  templateUrl: './chart-demo.component.html',
  styleUrls: ['./chart-demo.component.scss']
})
export class ChartDemoComponent implements OnInit {

  view: [number, number] = [954, 318];
  graphTopTitle: string = "";
  graphBottomTitle: string = "";
  colorSceme: any = [];
  data: any = [];
  waterReuseChartData: any = waterReuseChartData;

  yScaleMax: number = 100;
  barPadding: number = 42;
  colorScheme: any = { domain: ['#0058FF'], }
  waterGraph: any = {
    chartDimensions:[954, 318],
    graphTopTitle: 'Water reuse efficiency per location',
    data: waterReuseChartData,
    colorScheme: { domain: ['#0058FF'], },
    yScaleMax: 100,
    barPadding: 42,
    graphBottomTitle: "Renewable",
    yAxisLabel: 'Ratio of renewable energy (%)',
    formatDataLabel: (value: any)=> `${value}%`
  }

  ghgGraph: any = {
    chartDimensions:[450, 318],
    graphTopTitle: 'GHG emission  — entire company',
    data: GHGEmissionChartData,
    colorScheme: { domain: ['#fff','#0058FF', '#fff',], },
    yScaleMax: 1000,
    barPadding: 42,
    graphBottomTitle: "GHG emission",
    yAxisLabel: 'Greenhouse gas (t)',
    formatDataLabel: (value: any)=> {
      if(value === 0.01010) {
        return ' ';
      }
      return   `${value} t`
    }
  }

  ghgGraphMultipleLocations: any = {
    chartDimensions:[954, 318],
    graphTopTitle: 'Greenhouse gas emissions per location',
    data: GHGEmissionChartDataMultipleLocations,
    colorScheme: { domain: ['#0058FF'], },
    yScaleMax: 100,
    barPadding: 42,
    graphBottomTitle: "GHG emission",
    yAxisLabel: 'Greenhouse gas (t)',
    formatDataLabel: (value: any)=> {
      if(value === 0.01010) {
        return ' ';
      }
      return   `${value} t`
    }
  }

  pieChart: any = {
    chartDimensions:[450, 348],
    graphTopTitle: 'Energy mix - entire company',
    data: pieChartData,
    colorScheme: { domain: ['#0058FF', '#FF6700', '#000060'], },
    yScaleMax: 100,
    barPadding: 42,
    legend: true,
    labels: false,
    yAxisLabel: 'Greenhouse gas (t)',
    formatDataLabel: (value: any, name: string)=>{
console.log('@@X@ value is', value, name)
// return `${value} (150000 L)`;
return value;
    }
    // formatDataLabel: (series: any[], name: string)=> {
    //   const item = series?.filter((data) => data.name === name);
    //   if (item?.length > 0) {
    //     return item[0]?.label;
    //   }
    //   return `12`;
    // },
  }

  pieChartSingle: any = {
    chartDimensions:[450, 348],
    graphTopTitle: 'Ratio of reusable energy usage — entire company',
    data: pieChartDataSingle,
    colorScheme: { domain: ['#0058FF', '#E0E0E1'], },
    yScaleMax: 100,
    barPadding: 42,
    graphBottomTitle: "",
    yAxisLabel: 'Greenhouse gas (t)',
    formatDataLabel: (series: any[], name: string)=> {
      const item = series.filter((data) => data.name === name);
      if (item.length > 0) {
        return item[0].label;
      }
      return `${name}%`;
    },
    legend: true,
    labels: false,
  }

  stakedBarChart: any = {
    chartDimensions:[954, 318],
    graphTopTitle: 'Energy mix per location',
    data: stackedBarChartData,
    colorScheme: { domain:  ['#0058FF', '#FF6700', '#000060'], },
    yScaleMax: 100,
    barPadding: 52,
    graphBottomTitle: "",
    yAxisLabel: 'Energy comsumption (GJ)',
    formatDataLabel: (value: any)=> {
      if(value === 0.01010) {
        return ' ';
      }
      return   `${value} GJ`
    }
  }

  formatDataLabel(value: any): string {
    // Your custom formatting logic here
    // For example, you can format dates using a library like moment.js
    // return moment(value).format('YYYY-MM-DD');

    // or simply return the value as is
    return `${value}%`;
  }

  constructor() { }

  ngOnInit(): void {
  }

}
