<div class="tabs-header">
  <ul ngbNav #tabNav="ngbNav" class="nav-pills flex-row">
    <li
      id="{{ link.route }}"
      [ngbNavItem]="link.route"
      *ngFor="let link of links"
      class="me-4 pe-2"
      (click)="setTab(link)"
    >
      <a ngbNavLink>{{ link.title }}</a>
      <ng-template ngbNavContent></ng-template>
    </li>
  </ul>
</div>
