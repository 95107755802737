import { Component } from '@angular/core';
import {
  NgbDateParserFormatter,
  NgbDate,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';

function padNumber(value: any) {
  if (!isNaN(value) && value !== null) {
    return `0${value}`.slice(-2);
  }
  return '';
}

@Injectable()
export class NgbDateCustomParserFormatter extends NgbDateParserFormatter {
  parse(value: any): NgbDateStruct | null {
    if (value) {
      const dateParts = value.trim().split('-');

      let dateObj: any = {
        day: <any>null,
        month: <any>null,
        year: <any>null,
      };
      const dateLabels = Object.keys(dateObj);

      dateParts.forEach((datePart: any, idx: any) => {
        dateObj[dateLabels[idx]] = parseInt(datePart, 10) || <any>null;
      });
      return dateObj;
    }
    return null;
  }

  static formatDate(date: NgbDateStruct | NgbDate | null): string {
    return date
      ? `${padNumber(date.day)}-${padNumber(date.month)}-${date.year || null}`
      : '';
  }

  format(date: NgbDateStruct | null): string {
    return NgbDateCustomParserFormatter.formatDate(date);
  }
}
