import { GHGEmissionChartData, GHGEmissionChartDataMultipleLocations, pieChartData, pieChartDataSingle, stackedBarChartData, waterReuseChartData, multiExample } from 'src/app/chart-demo/data';
export const CHART_DATA = {
    pieChartSingle: {
        chartDimensions: [450, 300],
        graphTopTitle: 'Ratio of reusable energy usage — entire company',
        data: pieChartDataSingle,
        colorScheme: { domain: ['#0058FF', '#E0E0E1'], },
        // yScaleMax: 100,
        // barPadding: 42,
        // graphBottomTitle: "",
        // yAxisLabel: 'Greenhouse gas (t)',
        formatDataLabel: (series: any[], name: string) => {
            const item = series.filter((data) => data.name === name);
            if (item.length > 0) {
                return item[0].label;
            }
            return `${name}%`;
        },
        legend: true,
        labels: false,
    },
    waterGraph: {
        chartDimensions: [954, 318],
        graphTopTitle: 'Water reuse efficiency per location',
        data: waterReuseChartData,
        colorScheme: { domain: ['#0058FF'], },
        yScaleMax: 100,
        barPadding: 42,
        graphBottomTitle: "Renewable",
        yAxisLabel: 'Ratio of renewable energy (%)',
        formatDataLabel: (value: any) => `${value}%`
    },
    ghgGraph: {
        chartDimensions: [450, 318],
        graphTopTitle: 'GHG emission  — entire company',
        data: multiExample,
        colorScheme: { domain: ['#fff', '#0058FF', '#fff',], },
        yScaleMax: 1000,
        barPadding: 42,
        graphBottomTitle: "GHG emission",
        yAxisLabel: 'Greenhouse gas (t)',
        formatDataLabel: (value: any) => {
            if (value === 0.01010) {
                return ' ';
            }
            return `${value} t`
        }
    }
    ,
    ghgGraphMultipleLocations: {
        chartDimensions: [954, 318],
        graphTopTitle: 'Greenhouse gas emissions per location',
        data: GHGEmissionChartDataMultipleLocations,
        colorScheme: { domain: ['#0058FF'], },
        yScaleMax: 100,
        barPadding: 42,
        graphBottomTitle: "GHG emission",
        yAxisLabel: 'Greenhouse gas (t)',
        formatDataLabel: (value: any) => {
            if (value === 0.01010) {
                return ' ';
            }
            return `${value} t`
        }
    },
    pieChart: {
        chartDimensions: [450, 348],
        graphTopTitle: 'Energy mix - entire company',
        data: pieChartData,
        colorScheme: { domain: ['#0058FF', '#FF6700', '#000060'], },
        yScaleMax: 100,
        barPadding: 42,
        legend: true,
        labels: false,
        yAxisLabel: 'Greenhouse gas (t)',
        formatDataLabel: (value: any, name: string) => {
            // return `${value} (150000 L)`;
            return value;
        }
        // formatDataLabel: (series: any[], name: string)=> {
        //   const item = series?.filter((data) => data.name === name);
        //   if (item?.length > 0) {
        //     return item[0]?.label;
        //   }
        //   return `12`;
        // },
    },
    stakedBarChart: {
        chartDimensions: [954, 318],
        graphTopTitle: 'Energy mix per locationXC',
        data: stackedBarChartData,
        colorScheme: { domain: ['#0058FF', '#FF6700', '#000060'], },
        yScaleMax: 100,
        barPadding: 52,
        graphBottomTitle: "",
        yAxisLabel: 'Energy comsumption (GJ)',
        formatDataLabel: (value: any) => {
            if (value === 0.01010) {
                return ' ';
            }
            return `${value} GJ`
        }
    }
}

/*
 * Chart names map to get name of the chart
 * 
 * 
 * {
    [sectionType]: {
        [BreakEvenGoalID]: {
            [index]: {
                [type]: [chartName]
            }
        }

    }
    }
 */


export const CHART_NAME_MAP = {
    GOALS: {},
    PERFORMANCE: {
        1: {
            0: {
                HALF: "RENEWABLE_RATES",
                FULL: "RENEWABLE_RATES_PER_LOCATION"
            },
            1: {
                HALF: "TOTAL_USAGES",
                FULL: "USAGES_PER_LOCATION"
            }
        },
        6: {
            0: {
                HALF: "TOTAL_GHG",
                FULL: "GHG_PER_LOCATION"
            },
        },
        2: {
            0: {
                HALF: "TOTAL_WATER_INPUT",
                FULL: "WATER_INPUT_PER_LOCATION"
            },
            1: {
                HALF: "TOTAL_WATER_SAVED",
                FULL: "WATER_SAVED_PER_LOCATION"
            },
            2: {
                HALF: "TOTAL_WATER_DISCHARGED",
                FULL: "WATER_DISCHARGED_PER_LOCATION"
            },
        },
        7: {
            0: {
                HALF: "TOTAL_WASTE",
                FULL: "Afval"
            },
        }
    },
}
export const getTitle = (type: string, beGoalId: number, index: number): string => {
    // console.log("note title", type, beGoalId,index );
    if (type === "PERFORMANCE") {
        switch (beGoalId) {
            case 1:
                return index === 0 ? "Hernieuwbare energie" : "Energieverbruik"
            case 2:
                if (index == 0) {
                    return "Waterinvoer"
                } else if (index == 1) {
                    return "Waterbesparing"
                }
                return "Waterafvoer";
            case 6:
                return "Broeikasemissies";
            case 7:
                return "Afval";
            default:
                return "";
        }
    }
    return "";
}


export const getSubTitle = (beGoalId: number): string => {

    switch (beGoalId) {
        case 1:
            return "Voorgestelde doel voor de klant is om"
        case 6:
            return "Voorgestelde doel voor de klant is om zijn broeikasemissies te reduceren tot";
        case 7:
            return "Voorgestelde doel voor de klant is om";
        default:
            return "";
    }

}