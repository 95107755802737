const FORBIDDEN_WORDS: string[] = [
  'advies', 'adviseren', 'geadviseerd',
];


interface ForbiddenWordsResult {
  isError: boolean;
  markedWords: string[];
}

export const forbiddenWordsChecker = (inputText: string | undefined): ForbiddenWordsResult => {
  const input: string | undefined = inputText?.toLowerCase();
  const markedWords: string[] = [];
  for (let i = 0; i < FORBIDDEN_WORDS.length; i++) {
    const markedWord = FORBIDDEN_WORDS[i]?.toLowerCase();
    if (input?.includes(markedWord)) {
      markedWords.push(FORBIDDEN_WORDS[i]);
    }
  }

  return {
    isError: markedWords.length > 0,
    markedWords,
  };
};

export const replaceUnmarkedOccurrences = (text: string, markedWord: string) => {
  // Create a regular expression to match the word that is not already marked
  const regex = new RegExp(`([^<]|^)${markedWord}(?![^<]*<\/span>)`, 'gi');

  // Replace unmarked occurrences of the word
  const replacedText = text.replace(regex, `$1<span class="highlight">${markedWord}</span>&nbsp;`);

  return replacedText;
}

export const replaceFalseMarkedOccurrences = (text: string, markedWord: string) => {
  // Create a regular expression to match the word that is not already marked
  const regex = new RegExp(`/<span\s+class="highlight"[^>]*>([^<]+)<\/span>|<span\s+class="highlight"[^>]*>([^<]+)(?=<\/span>)|<span\s+class="highlight">([^<]+)(?=<\/span>)/gi`, 'gi');

  // Replace unmarked occurrences of the word
  const replacedText = text.replace(regex, `$1<span class="highlight">${markedWord}</span>&nbsp;`);

  return replacedText;
}

export const removeUnallowedHighlightSpans = (text: string, forbiddenWords: any) => {
  // Create a regular expression to match <span class="highlight"> ... </span>
  const spanRegex = /<span\s+class="highlight">([^<]+)<\/span>/gi;
  // Replace <span>...</span> with the inner content if it's in the allowedWords list, or with the original span if not.
  const cleanedText = text.replace(spanRegex, (match, word) => {
    if (FORBIDDEN_WORDS.includes(word)) {
      return match;
    } else {
      return word;
    }
  });

  return cleanedText;
}
