export var waterReuseChartData = [
  {
    name: 'Zero Bar',
    value: 0,
  },
  {
    name: 'Amsterdam',
    value: 80,
  },
  {
    name: 'Utrecht',
    value: 78,
  },
  {
    name: 'Ouderkerkaan den Ijssel',
    value: 75,
  },
  {
    name: 'Hendrik Ido Ambacht',
    value: 72,
  },
  {
    name: 'Westerhaar Vriezenveensewijk',
    value: 65,
  },
  {
    name: 'Hoogezand',
    value: 47,
  },
  {
    name: 'Gasselterboerveenschemond',
    value: 18,
  },
  {
    name: 'Westerhaar Vriezenveensewijk (max40char)',
    value: 16,
  },

];



export var GHGEmissionChartData = [
  {
    name: '',
    value: 0.01010,
  },
  {
    name: 'Company',
    value: 821,
  },
  {
    name: ' ',
    value: 0.01010,
  },
];


export var GHGEmissionChartDataMultipleLocations = [
  {
    name: 'Zero Bar',
    value: 0,
  },
  {
    name: 'Amsterdam',
    value: 80,
  },
  {
    name: 'Utrecht',
    value: 78,
  },
  {
    name: 'Ouderkerkaan den Ijssel',
    value: 75,
  },
  {
    name: 'Hendrik Ido Ambacht',
    value: 72,
  },
  {
    name: 'Westerhaar Vriezenveensewijk',
    value: 65,
  },
  {
    name: 'Hoogezand',
    value: 47,
  },
  {
    name: 'Gasselterboerveenschemond',
    value: 18,
  },
  {
    name: 'Westerhaar Vriezenveensewijk (max40char)',
    value: 16,
  },

];


export const pieChartData = [
  {
    "name": "Elektriciteit",
    "value": 65,
    "label": "65%"
  },
  {
    "name": "Brandstof",
    "value": 15,
    "label": "15%"
  },
  {
    "name": "Aardgas",
    "value": 20,
    "label": "20%"
  }
];

export const pieChartDataSingle = [
  {
    "name": "Renewable",
    "value": 15,
    "label": "15%"
  },
  {
    "name": "Not Renewable",
    "value": 85,
    "label": "85%"
  }
];


export const pieChartWater = [
  {
    "name": "Other",
    "value": 14,
    "label": "15%"
  },
  {
    "name": "Tap",
    "value": 85,
    "label": "85%"
  },

  {
    "name": "Purified Water",
    "value": 14,
    "label": "15%"
  },
  {
    "name": "Ground",
    "value": 85,
    "label": "85%"
  },
  {
    "name": "Surface",
    "value": 14,
    "label": "15%"
  },
];


export var stackedBarChartData = [
    {
      "name": "New YorkX",
      "series": [
        { "name": "Electricity", "value": 7300000 },
        { "name": "Fuel", "value": 8940000 },
        { "name": "Natural Gas", "value": 5360000 }
      ]
    },
    {
      "name": "Tokyo",
      "series": [
        { "name": "Electricity", "value": 6800000 },
        { "name": "Fuel", "value": 9200000 },
        { "name": "Natural Gas", "value": 6000000 }
      ]
    },
    {
      "name": "Sydney",
      "series": [
        { "name": "Electricity", "value": 6500000 },
        { "name": "Fuel", "value": 8500000 },
        { "name": "Natural Gas", "value": 5000000 }
      ]
    },
    {
      "name": "Shanghai",
      "series": [
        { "name": "Electricity", "value": 7200000 },
        { "name": "Fuel", "value": 9100000 },
        { "name": "Natural Gas", "value": 5500000 }
      ]
    },
    {
      "name": "Berlin",
      "series": [
        { "name": "Electricity", "value": 7000000 },
        { "name": "Fuel", "value": 8800000 },
        { "name": "Natural Gas", "value": 5200000 }
      ]
    },
    {
      "name": "Rio de Janeiro",
      "series": [
        { "name": "Electricity", "value": 6000000 },
        { "name": "Fuel", "value": 9200000 },
        { "name": "Natural Gas", "value": 5800000 }
      ]
    },
    {
      "name": "Cape Town",
      "series": [
        { "name": "Electricity", "value": 6800000 },
        { "name": "Fuel", "value": 8700000 },
        { "name": "Natural Gas", "value": 5100000 }
      ]
    },
    {
      "name": "Moscow",
      "series": [
        { "name": "Electricity", "value": 7100000 },
        { "name": "Fuel", "value": 9000000 },
        { "name": "Natural Gas", "value": 5400000 }
      ]
    }
  ]

  export const stakedBarChart = {
    chartDimensions:[954, 318],
    graphTopTitle: 'Energy mix per location',
    data: stackedBarChartData,
    colorScheme: { domain:  ['#0058FF', '#FF6700', '#000060'], },
    yScaleMax: 100,
    barPadding: 52,
    graphBottomTitle: "",
    yAxisLabel: 'Energy comsumption (GJ)',
    formatDataLabel: (value: any)=> {
      if(value === 0.01010) {
        return ' ';
      }
      return   `${value} GJ`
    }
  }



  export var multiExample = [
    {
      name: 'Zero Bar',
      series: [
        {
          name: 'none1',
          value: 0
        },
        {
          name: 'none',
          value: 100
        },
      ]
    },
    {
      name: 'Amsterdam',
      series: [
        {
          name: 'none1',
          value: 20
        },
        {
          name: 'none',
          value: 80
        },
      ]
    },
    {
      name: 'Utrecht',
      series: [
        {
          name: 'none1',
          value: 22
        },
        {
          name: 'none',
          value: 78
        },
      ]
    },
    {
      name: 'Ouderkerkaan den Ijssel',
      series: [
        {
          name: 'none1',
          value: 25
        },
        {
          name: 'none',
          value: 75
        },
      ]
    },
    {
      name: 'Hendrik Ido Ambacht',
      series: [
        {
          name: 'none1',
          value: 28
        },
        {
          name: 'none',
          value: 72
        },
      ]
    },
    {
      name: 'Westerhaar Vriezenveensewijk',
      series: [
        {
          name: 'none1',
          value: 35
        },
        {
          name: 'none',
          value: 65
        },
      ]
    },
    {
      name: 'Hoogezand',
      series: [
        {
          name: 'none1',
          value: 53
        },
        {
          name: 'none',
          value: 47
        },
      ]
    },
    {
      name: 'Gasselterboerveenschemond',
      series: [
        {
          name: 'none1',
          value: 82
        },
        {
          name: 'none',
          value: 18
        },
      ]
    },
    {
      name: 'Westerhaar Vriezenveensewijk (max40char)',
      series: [
        {
          name: 'none1',
          value: 84
        },
        {
          name: 'none',
          value: 16
        },
      ]
    },
  
  ];
  