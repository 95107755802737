import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'csrd-box',
  templateUrl: './csrd-box.component.html',
  styleUrls: ['./csrd-box.component.scss'],
})
export class CSRDBox implements OnInit {
  @Output() payload = new EventEmitter<string>();
  @Input() csrdBasis!: any;
  @Input() csrdState!: any;
  @Input() editMode: any;
  showButtonLoader: boolean = false;
  editCSRDform!: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    public route: ActivatedRoute) {}

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.editCSRDform = this.formBuilder.group({
      csrdBasis: new FormControl(
        {
          value: this.csrdBasis,
          disabled: true
        },
      ),
    });
  }

  enableEditMode(): void {
    if (this.csrdState === 'CSRD_NOT_STARTED') {
      this.editMode = true;
      this.editCSRDform.enable();
    }
  }

  updateCustomerDetails(): void {
    this.editMode = false;
    this.editCSRDform.disable();
    this.payload.emit(this.editCSRDform.value.csrdBasis);
  }

  cancelChanges() {
    this.editMode = false;
    this.editCSRDform.disable();
  }

}
