import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
})
export class AccordionComponent implements OnInit {
  @Input() accordionData!: any[];
  @Input() disabled: any;
  @Output() onAnswered = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  /**
   * Triggers this function when user answers the question
   * @param questionObject - Object - Selection question object
   * @param value - Boolean value
   */
  onAnswering(questionObject: any, value: boolean) {
    this.onAnswered.emit({ questionObject, value });
  }
}
