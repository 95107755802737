import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-risk-scan-goal-details',
  templateUrl: './risk-scan-goal-details.component.html',
  styleUrls: ['./risk-scan-goal-details.component.scss']
})
export class RiskScanGoalDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
