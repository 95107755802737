<div class="sdg-layout">
  <p class="title mt-4 pt-3">Samenhang SDG's</p>
  <p class="description">
    De 23 Future-Fit doelen hangen samen met de Sustainable Development Goals
    (SDG's) die de United Nations (UN) heeft opgesteld. Hieronder zie je met
    welke SDG's dit doel samenhangt.
  </p>
  <table class="sdg-table">
    <tr>
      <ng-container *ngFor="let sdg of [].constructor(9); let i = index"
        ><td
          class="sdg-ids"
          [ngStyle]="{
            'background-color': i !== 8 && getBackGroundColor(i + 1),
            color: i !== 8 && getBackGroundColor(i + 1) ? 'white' : ''
          }"
        >
          <span *ngIf="i < 8">{{ i + 1 }}</span>
          <span *ngIf="i === 8" class="sdg">SDG</span>
        </td>
      </ng-container>
    </tr>
    <tr>
      <ng-container
        *ngFor="let sdgNumber of [9, 10, 11, 12, 13, 14, 15, 16, 17]"
        ><td
          class="sdg-ids"
          [ngStyle]="{
            'background-color': getBackGroundColor(sdgNumber),
            color: getBackGroundColor(sdgNumber) ? 'white' : ''
          }"
        >
          {{ sdgNumber }}
        </td></ng-container
      >
    </tr>
  </table>
  <div class="sdg-images">
    <img
      *ngFor="
        let image of selectedGoal?.SUSTAINABLE_DEVELOPMENT_GOALS;
        let i = index
      "
      src="../../../assets/images/sdg-image-{{ image }}.png"
      alt="SDG-Images"
    />
  </div>
</div>
