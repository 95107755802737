import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'sbaa';
  isLandingPage: any;

  ngOnInit() {

    const currentUrl = window.location.href;
    const baseUrl = `${window.location.origin}/`;
    const landingPageUrl = `${window.location.origin}/home`;

    if (currentUrl === baseUrl || currentUrl === landingPageUrl) {
      this.isLandingPage = true;
    } else {
      this.isLandingPage = false;
    }
  }


}
