<div [id]="id" class="chart-container" [class.center-align]="isCenterAligned" [class.dashboard]="isDashboard">
  <canvas baseChart [type]="chartDetails?.chartType" [data]="chartData" [options]="options">
  </canvas>
  <div *ngIf="legendHtml" id="custom-legend" class="custom-legend">
    <ul>
      <div class="legend" *ngFor="let legendItem of legendHtml; let i = index">
        <li class="lagend-item">
          <div class="legend-rect" [style.background-color]="legendItem.backgroundColor"> </div>
          <div class="lagend-label">{{legendItem.label}}</div>
        </li>
      </div>
    </ul>
  </div>
</div>