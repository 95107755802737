<div class="error-container" [class.error-hidden]="!error" *ngIf="error">
  <p class="d-flex justify-content-center mb-3">
    <img src="../../../assets/images/exclamation-triangle.svg" alt="Error" />
  </p>
  <div class="text">
    <p *ngIf="errorType === 'client'">
      Er ging iets mis. We adviseren je om de pagina te vernieuwen. Als je dit
      bericht blijft zien, neem dan contact op met
      <app-generic-consultant-email></app-generic-consultant-email>
    </p>
    <p *ngIf="errorType === 'consultant'">
      Er ging iets mis. Vernieuw de pagina of neem contact op met het website
      ondersteuningsteam.
    </p>
  </div>
</div>
