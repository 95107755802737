import { Component, inject, Input, OnInit } from '@angular/core';
import { cloneDeep, find, includes } from 'lodash';
import { UtilService } from 'src/app/shared/services/util.service';
import { SUSTAINABLE_DEVELOPMENT_GOALS } from 'src/app/shared/utilities/constants';

@Component({
  selector: 'app-be-goal-sdg',
  templateUrl: './be-goal-sdg.component.html',
  styleUrls: ['./be-goal-sdg.component.scss'],
})
export class BeGoalSdgComponent implements OnInit {
  @Input() userType!: string;
  @Input() selectedGoal!: any;
  sdgList: any = cloneDeep(SUSTAINABLE_DEVELOPMENT_GOALS);

  //Injecting dependencies
  public utilService = inject(UtilService);

  constructor() {}

  ngOnInit(): void {}

  /**
   * Returns the background color for the BE Goal's SDG
   * @param id - number
   * @returns sdg color
   */
  getBackGroundColor(id: Number) {
    if (
      !includes(this.selectedGoal?.SUSTAINABLE_DEVELOPMENT_GOALS, id.toString())
    ) {
      return;
    }
    let foundObject = find(this.sdgList, ['ID', id]);
    return foundObject ? foundObject?.color : '#FFFFFF';
  }
}
