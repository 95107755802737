<span ngbDropdown class="cursor" placement="bottom-end">
  <img
    src="../../../assets/images/context-menu.svg"
    alt="Context-menu"
    data-toggle="dropdown"
    aria-haspopup="true"
    aria-expanded="false"
    autocomplete="off"
    ngbDropdownToggle
    id="menu"
  />
  <div class="menu-dropdown" ngbDropdownMenu aria-labelledby="menu">
    <ul>
      <li
        class="select-options"
        *ngFor="let option of menuOptions"
        ngbDropdownItem
        role="option"
        (click)="selectMenuOption(option)"
        attr.value="{{ option?.dataKey }}"
        [disabled]="option?.disabled"
        [ngClass]="{ active: option?.key === selectedMenu }"
      >
        <span>{{ option?.label }}</span>
      </li>
    </ul>
  </div>
</span>
