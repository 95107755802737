<div class="d-flex flex-wrap be-goals-risk-indications">
  <div
    class="each-block cursor"
    *ngFor="let goal of begoals; let idx = index"
    [ngClass]="{ active: selectedBeGoal?.ID == goal?.ID }"
    (click)="selectBEGoal(goal, idx)"
  >
    <span class="each-be-goal">
      <p class="be-goal-number m-0">{{goal?.ID}}</p>
      <app-risk-levels [beGoal]="goal" [width]="'12'"></app-risk-levels>
    </span>
  </div>
</div>
