<div class="table-responsive">
  <table class="table">
    <thead>
      <th class="collapsableIcon">
      </th>
      <th *ngFor="let header of tableHeadings; let idx = index" class="ellipsis">
        <span *ngIf="header.fieldType == 'text' && header.displayLabel">{{
          header?.label
          }}</span>
        <span *ngIf="header.sorting" class="ps-2" (click)="toggleSorting(header?.key)">
          <img src="assets/images/sort-desc.svg" class="pb-3 cursor" alt="Descending" />
          <img src="assets/images/sort-asc.svg" class="pt-3 cursor ascending-icon" alt="Ascending" />
        </span>
      </th>
    </thead>
    <tbody>
      <ng-container *ngIf="!tableData.length">
        <td colspan="5">
          <div>Er zijn geen resultaten gevonden</div>
        </td>
      </ng-container>

      <ng-container *ngFor="let value of tableData; let i = index">
        <tr class="cursor" [ngClass]="{ active: value?.isSelected }">
          <td (click)="toggleRow(i, value)">
            <img [ngClass]="{'active': value.collapsed}" src="assets/images/down-arrow.svg" class="icon"
              alt="Descending" />
          </td>
          <td *ngFor="let header of tableHeadings" (click)="selectedRow(value)">
            <span *ngIf="!header.getIconPath">{{
              getValue(value[header.key], header)
              }}</span>
            <p *ngIf="header.key === 'STATE' && header.getIconPath" [class]="getStatusClass(value?.statusHeading)"
              class="status">
              {{value?.statusHeading}}
            </p>
          </td>
        </tr>
        <ng-container *ngFor="let state of value?.currentStates; let i = index">
          <tr [class]="getCursorClass(state?.status)" [ngClass]="{ active: value?.isSelected }"
            [class.collapse]="!value.collapsed">
            <td>
              <img *ngIf="state?.id" [src]="getIconSource(state?.id)" class="state-icon" />
            </td>
            <td [attr.colspan]="4" (click)="openNewTab(state?.id, value, state?.year)">
              <p class="state-name">{{ state?.stateName }}</p>
            </td>
            <td>
              <p [class]="getStatusClass(state?.status)" class="status">{{state?.status}}</p>
            </td>
          </tr>
        </ng-container>
      </ng-container>
    </tbody>
  </table>
</div>