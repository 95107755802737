import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  NgbCalendar,
  NgbDateAdapter,
  NgbDateParserFormatter,
} from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import { cloneDeep, every, find, set } from 'lodash';
import { Observable, take } from 'rxjs';
import { UtilService } from 'src/app/shared/services/util.service';
import { DeepdiveAssessmentActions } from 'src/app/store/actions';
import { IAppState } from 'src/app/store/state';
import { deepdiveGroup8 } from 'src/app/shared/deepdive-group8-init';
import { getFormGroupStyle, parseRefYear, ENERGY_GRADES, getLabel, shouldRenderCustomLabel, getCustomLabel, getColumnHeaders, createQuestionTableTab10, createQuestionTableTab04 } from './utils';


@Component({
  selector: 'app-deepdive-questions-multiple-locations',
  templateUrl: './deepdive-questions-multiple-locations.component.html',
  styleUrls: ['./deepdive-questions-multiple-locations.component.scss'],
})
export class DeepdiveQuestionsMultipleLocationsComponent implements OnInit, OnChanges {
  @Input() deepDiveQuestions!: any[];
  @Input() selectedTab!: any;
  @Input() groupDeepDiveDetails: any = {};
  @Input() tabsLength!: number;
  @Input() showDescription: boolean = true;
  @Input() deepdiveStatus!: any;
  @Input() refYear: any;

  storeValue: any;
  questionsForm!: FormGroup;
  dropDownForm!: FormGroup;
  dropDownOptions: any;
  showLoader: boolean = false;
  formBuilder = inject(FormBuilder);
  utilService = inject(UtilService);
  private ngbCalendar = inject(NgbCalendar);
  private dateAdapter = inject(NgbDateAdapter);
  private dateFormatter = inject(NgbDateParserFormatter);
  @Output() onSelectingPreviousTab = new EventEmitter<any>();
  @Output() onSubmittingGroupAnswers = new EventEmitter<any>();
  state!: Observable<IAppState>;
  allDependentQuestionsHidden: boolean = true;
  hideEnergyLabelCQuestion: boolean = true;
  initialDeepDiveQuestions: any;
  private cdRef = inject(ChangeDetectorRef);
  selectedEnergyOption: any;
  shouldRenderCustomLabel: any = shouldRenderCustomLabel;
  customLabel: any = getCustomLabel;
  columnHeaders!: any;
  questionTable!: any;
  tableForm1!: any;
  tableForm2!: any;
  isFormEnabled: any = false;
  noOfLocations: any = 1;
  isq28TitleRendered: boolean = false;

  constructor(private store: Store<IAppState>) { }

  ngOnInit(): void {
    this.store.subscribe((state) => {
      this.storeValue = state;
    });


    const ddQuestions = cloneDeep(this.deepDiveQuestions);
    this.initialDeepDiveQuestions = this.deepDiveQuestions;
    var isQ28TitleRenderedIndex = 0;
    var shouldRenderTitleq28 = false;
    this.deepDiveQuestions = this.deepDiveQuestions.flatMap((question: any, questionNo: any) => {
      if (question.LOCATION === "GENERIC") {
        return [{
          PREVIOUSLY_RENDERED: false,
          questionNo,
          ...question,
        }];
      } else {
        this.noOfLocations = question?.LOCATION_LIST.length;
        return question?.LOCATION_LIST?.map((location: any, index: any) => {
          shouldRenderTitleq28 = false;
          if(question.ID == 28 && !location.DISABLED){
            isQ28TitleRenderedIndex += 1; 
            if(isQ28TitleRenderedIndex === 1){
              shouldRenderTitleq28 = true;
              this.isq28TitleRendered = true;
              isQ28TitleRenderedIndex += 1;
            }
        
          }
          return {
            PREVIOUSLY_RENDERED:question.ID == 28 ? !shouldRenderTitleq28 :index != 0,
            questionNo,
            ...question,
            ...location,
          }
        }) ?? [];
      }
    })

    this.initForm();
    this.questionsForm?.disable();
    this.calculateAllDependentQuestionsHidden();
    this.parseRefYear();
    if (this.selectedTab.ID == 10 && this.deepDiveQuestions[0]?.LOCATION_LIST?.length >= 1) {
      // this.columnHeaders = getColumnHeaders(this.deepDiveQuestions);
      this.questionTable = createQuestionTableTab10(this.deepDiveQuestions, this.refYear);

      this.tableForm1 = this.initializeTableForm(this.questionTable[0].tableData)
      this.tableForm2 = this.initializeTableForm(this.questionTable[2].tableData)
    } else if (this.selectedTab.ID == 4) {
      this.questionTable = createQuestionTableTab04(this.deepDiveQuestions, this.refYear);
      this.tableForm1 = this.initializeTableForm(this.questionTable[1].tableData)
      this.tableForm2 = this.initializeTableForm(this.questionTable[2].tableData)
    }
  }

  toggleForm() {
    if (this.questionsForm.enabled) {
      this.questionsForm.disable();
      this.isFormEnabled = false;
    } else {
      this.questionsForm.enable();
      this.isFormEnabled = true;
    }
  }

  getQuestionFormState() {
    return this.questionsForm.enabled;
  }

  initializeTableForm(questionTable: any) {
    return this.formBuilder.group({
      array: this.formBuilder.array(
        this.getTableQuestionsArray(questionTable)
      )
    });
  }

  getTableQuestionsArray(questionTable: any): any {
    return (questionTable || []).map((locationData: any) => {
      const dataFields = Object.keys(locationData).filter((key: any) => key !== 'header');
      return this.formBuilder.array(
        dataFields.map((key: any) => {
          const value = locationData[key];
          const answerLabelsArray = value?.ANSWER_LABELS || [];
          const answerArray = value?.ANSWER?.ANSWERS || [];
          const defaultAnswer = answerArray.length > 0 ? answerArray[0]?.ANSWER : '';

          return this.formBuilder.group({
            ID: this.formBuilder.control(value?.ID || ''),
            TEXT: this.formBuilder.control(value?.TEXT || ''),
            TYPE: this.formBuilder.control(value?.TYPE || ''),
            answerlabel: this.formBuilder.array(
              answerLabelsArray.map((eachValue: any) =>
                this.formBuilder.group({
                  LABEL: this.formBuilder.control(eachValue || ''),
                  ANSWER: this.formBuilder.control(
                    // answerArray.find((answer: any) => answer?.LABEL === eachValue)?.ANSWER || false //TODO issue is here?
                    JSON.parse(answerArray.find((answer: any) => answer?.LABEL === eachValue)?.ANSWER || "false")
                  )
                })
              )
            ),
            DISABLED: this.formBuilder.control(value?.DISABLED || ''),
            UNIT: this.formBuilder.control(value?.UNIT || ''),
            LOCATION: this.formBuilder.control(value?.LOCATION || ''),
            ANSWERS: this.formBuilder.control(
              defaultAnswer,
              [
                Validators.max(value?.MAX_VALUE || null),
                Validators.min(value?.MIN_VALUE || null)
              ]
            )
          });
        })
      )

    })
  }

  getFormGroupStyle(value: any): any {
    return getFormGroupStyle(value);
  }

  parseRefYear() {
    this.deepDiveQuestions = parseRefYear(this.deepDiveQuestions, this.refYear);
  }

  get today() {
    return this.dateFormatter.format(this.ngbCalendar.getToday())!;
  }

  getLabel(value: any) {
    return getLabel(value, this.refYear);
  }

  ngOnChanges() {
    this.deepDiveQuestions = this.deepDiveQuestions;
    this.initForm();
  }

  returnControl(array: any, label: any, value: any) {
    array?.map(
      (eachLabelValue: any) =>
        this.formBuilder.control(
          eachLabelValue?.ANSWER
            ? eachLabelValue?.LABEL === label
              ? eachLabelValue?.ANSWER
              : ''
            : ''
        ),
      [Validators.max(value?.MAX_VALUE), Validators.min(value?.MIN_VALUE)]
    );
  }

  enableEnergyLabelRelatedQuestion(selectedOption: any, currentQuestion: any) {
    let selectedLabel = selectedOption?.selection?.NAME;
    let isDisabled = ENERGY_GRADES.indexOf(selectedLabel) !== -1;
    this.selectedEnergyOption = {
      selectedLabel,
      question: currentQuestion,
    };

    const notDisabledQuestion = this.deepDiveQuestions?.find((question: any)=>{
        return question.ID == 28 && !question.DISABLED
    })

    for (let i = 0; i < this.deepDiveQuestions.length; i++) {
      if (this.deepDiveQuestions[i].ID == 28) {
        // Change the property here (e.g., answer)
        this.deepDiveQuestions[i].PREVIOUSLY_RENDERED = !Boolean(notDisabledQuestion);
        break; // Exit the loop after finding the first occurrence
      }
    }
  

    this.deepDiveQuestions.map((question, index) => {
      currentQuestion?.RELATED_QUESTIONS?.filter((id: any) => {
        if (Number(id) === Number(question?.ID)) {
          if (currentQuestion?.LOCATION == question?.LOCATION) {
            question.DISABLED = isDisabled;
            //Disabling the answers for the dependent question if the answer is NO
            this.getArrayControls()
              ?.at(index)
              ?.get('DISABLED')
              ?.setValue(question.DISABLED);
            //clearing the answers for the dependent question if the answer is NO
            if (question?.TYPE === 'MULTI_NUMBER') {
              question?.ANSWER?.ANSWERS?.map((eachValue: any, i: any) => {
                this.getAnswerLabelControls(index)[i].get('ANSWER')?.reset();
                this.getAnswerLabelControls(index)[i].get('ANSWER')?.setValue('');
              });
            }
            this.getArrayControls()?.at(index)?.get('ANSWERS')?.reset();
            this.getArrayControls()?.at(index)?.get('ANSWERS')?.setValue('');
          }
        }
      });
    });
  }


  onOptionSelected(event: any, value: any, index:any) {
    const itemsArray = this.questionsForm.get('array') as FormArray;
    const formGroup = itemsArray.at(index) as FormGroup; // Assume value has an index property
    formGroup.patchValue({ ANSWERS: event?.selection?.NAME });



    this.getQuestionsFormArray();
    this.enableEnergyLabelRelatedQuestion(event, value);
  }

  initForm() {
    // const deepDiveQuestions = this.deepDiveQuestions;
    if (!this.deepDiveQuestions?.length) {
      return;
    }
    this.buildDropDown();
    this.questionsForm = this.formBuilder.group({
      array: this.formBuilder.array(this.getQuestionsArray())
    });
    if (this.deepdiveStatus === 'DEEPDIVE_SUBMITTED') {
      this.questionsForm?.disable();
      return;
    }

    this.questionsForm.valueChanges.subscribe((res) => {
      const formArray: FormArray = this.questionsForm.get('array') as FormArray;
      this.store.dispatch(
        DeepdiveAssessmentActions.saveGroupDeepDiveAssessmentAnswers({
          value: formArray?.value,
          groupId: this.selectedTab?.ID,
        })
      );
    });
  }

  getQuestionsArray(): any {
    return this.deepDiveQuestions?.map((value: any) => {
      let questionControl;
  
      // Create the base structure of the form control
      const baseControl = {
        ID: this.formBuilder.control(value?.ID ?? ''),
        TEXT: this.formBuilder.control(value?.TEXT ?? ''),
        TYPE: this.formBuilder.control(value?.TYPE ?? ''),
        answerlabel: this.buildAnswerLabel(value),
        DISABLED: this.formBuilder.control(value?.DISABLED ?? ''),
        UNIT: this.formBuilder.control(value?.UNIT ?? ''),
        ANSWERS: this.getAnswerControl(value),
        LOCATION: this.formBuilder.control(value.LOCATION ?? '')
      };
  
      // Add the dropdown specific control
      const answer = this.getFormatedValue(value)
      if (value?.TYPE === 'DROPDOWN') {
        questionControl = {
          ...baseControl,
          dropDown: this.formBuilder.control({
            value: answer,
            disabled:  value?.DISABLED,
          })
        };
      } else {
        questionControl = baseControl;
      }
  
      return this.formBuilder.group(questionControl);
    });
  }

  buildDropDown() {
    let dropDownQuestion = this.deepDiveQuestions?.filter((question) => question.TYPE === "DROPDOWN")[0];
    if (dropDownQuestion) {
      let dropDownAnswer = dropDownQuestion?.ANSWER?.ANSWERS[0]?.ANSWER ?? '';
      this.dropDownForm = this.formBuilder.group({
        dropDown: this.formBuilder.control(
          {
            value: dropDownAnswer,
            disabled: false,
          },
        ),
      });
      this.dropDownOptions = dropDownQuestion?.ANSWER_LABELS?.map((label: string) => ({
        NAME: label,
        isSelected: dropDownAnswer === label,
      }))
    }
  }
  getFormatedValue(value: any) {
    if (value?.TYPE === 'DATE') {
      let date = this.utilService.toDate(
        value?.ANSWER?.ANSWERS?.[0]?.ANSWER
      ) ?? null;
      return date;
    }
    return value?.ANSWER?.ANSWERS?.[0]?.ANSWER ?? '';
  }

  getValidators(value: any) {
    if (value?.TYPE === 'TEXT' || value?.TYPE === 'TEXT_AREA') {
      return [
        Validators.maxLength(value?.MAX_VALUE),
        Validators.minLength(value?.MIN_VALUE),
      ];
    }
    return [
      Validators.max(value?.MAX_VALUE),
      Validators.min(value?.MIN_VALUE),
    ]
  }


  getAnswerControl(value: any) {
    let formatedValue = this.getFormatedValue(value)
    let validators = this.getValidators(value);
    return this.formBuilder.control(
      formatedValue,
      validators,
    )
  }




  buildAnswerLabel(value: any) {
    return this.buildAnswerLabelGeneric(value);
  }

  buildAnswerLabelGeneric(value: any) {
    if (value?.TYPE === 'MULTI_NUMBER') {
      return this.formBuilder.array(value?.ANSWER_LABELS?.map((eachValue: any) =>
        this.formBuilder.group({
          LABEL: this.formBuilder.control(eachValue ?? ''),
          ANSWER: this.formBuilder.control(
            find(value?.ANSWER?.ANSWERS, ['LABEL', eachValue])?.ANSWER ?? '',
            [
              Validators.max(value?.MAX_VALUE),
              Validators.min(value?.MIN_VALUE),
            ]
          ),
        })
      ))
    } else if (value?.TYPE === 'CHECKBOX') {
      return this.formBuilder.array(value?.ANSWER_LABELS?.map((eachValue: any) =>
        this.formBuilder.group({
          LABEL: this.formBuilder.control(eachValue ?? ''),
          ANSWER: this.formBuilder.control(
            find(value?.ANSWER?.ANSWERS, ['LABEL', eachValue])?.ANSWER === 'true' ?? '',
          ),
        })
      ))
    } else {
      return false;
    }
  }

  getArrayControls() {
    return (this.questionsForm.get('array') as FormArray).controls;
  }

  getAnswerLabelControls(index: any) {
    return (
      (this.questionsForm.get('array') as FormArray)
      ['at'](index)
        .get('answerlabel') as FormArray
    ).controls;
  }

  getFormArrayControl(index: number): FormGroup {
    return (this.questionsForm.get('array') as FormArray).at(index) as FormGroup;
  }
  
  getQuestionsFormArray() {
    const formArray: FormArray = this.questionsForm?.get('array') as FormArray;
    let answers = cloneDeep(formArray?.value);
return answers;
  }

  onChangeCheckbox(checkboxValue: any) {
    if(this.selectedTab.ID == 10){
          // Find and update the control based on checkboxValue id
          const control = this.arrayControls.find(item => {
            return item?.get('ID')?.value === checkboxValue.ID && item?.get('LOCATION')?.value === checkboxValue.LOCATION
          });
          if (control) {
            control.patchValue({
              ANSWERS: `${checkboxValue.answerlabel[0].ANSWER}`,
              answerlabel: [{LABEL:'',  ANSWER: checkboxValue.answerlabel[0].ANSWER}] 
            });
          
            if(checkboxValue.ID === '54'){
              const textControl = this.arrayControls.find(item => {
                return item?.get('ID')?.value == '55' && item?.get('LOCATION')?.value === checkboxValue.LOCATION
              });
              textControl?.patchValue({
                DISABLED: !checkboxValue.answerlabel[0].ANSWER
              });
              this.deepDiveQuestions.forEach((question:any)=>{
                if(question.ID === '55' && question.LOCATION === checkboxValue.LOCATION){
                  question.DISABLED =  !checkboxValue.answerlabel[0].ANSWER;
                }
              })
            }
          } else {
            console.warn(`Control with id ${checkboxValue.ID} not found`);
          }
    }

  }

  // Getter for easier access to the FormArray controls
    get arrayControls() {
      return (this.questionsForm.get('array') as FormArray).controls;
    }

  getTableQuestionsFormArray() {
    const tableFormArray1: FormArray = this.tableForm1?.get('array') as FormArray;
    const tableFormArray2: FormArray = this.tableForm2?.get('array') as FormArray;
    let answers = cloneDeep(tableFormArray1?.value);
    answers.push(cloneDeep(tableFormArray2?.value));
    return answers;
  }

  getTextForm() {
    const textForm: FormArray = this.questionsForm?.get('array') as FormArray;
    return textForm?.value.filter((question:any)=> question.ID == '55');
  }

  getTableQuestionsFormArray1() {
    const tableFormArray1: FormArray = this.tableForm1?.get('array') as FormArray;
    let answers = cloneDeep(tableFormArray1?.value);
    return answers;
  }

  getTableQuestionsFormArray2() {
    const tableFormArray2: FormArray = this.tableForm2?.get('array') as FormArray;
    let answers = cloneDeep(tableFormArray2?.value);
    return answers;
  }

  clearDate(index: number, controlName: any) {
    this.getArrayControls()?.at(index)?.get(controlName)?.reset();
    this.getArrayControls()?.at(index)?.get(controlName)?.setValue('');
  }

  backSpace(event: any, index: number, controlName: any) {
    if (event.keyCode == 8 || event.keyCode == 46) {
      this.clearDate(index, controlName);
    }
  }

  getPreviousTab() {
    this.onSelectingPreviousTab.emit();
  }

  //Destroying the toast
  ngOnDestroy() {
    const formArray: FormArray = this.questionsForm?.get('array') as FormArray;
    formArray.clear();
  }

  //Disabling or enabling the dependent questions based on radio button
  onAnsweringRadioButton(relatedQuestionArray: any, answer: any) {
    this.deepDiveQuestions.map((question, index) => {
      relatedQuestionArray.filter((id: any) => {
        if (Number(id) === Number(question?.ID)) {
          question.DISABLED = answer === 'Ja' ? false : true;
          //Disabling the answers for the dependent question if the answer is NO
          this.getArrayControls()
            ?.at(index)
            ?.get('DISABLED')
            ?.setValue(question.DISABLED);
          //clearing the answers for the dependent question if the answer is NO
          if (question?.TYPE === 'MULTI_NUMBER') {
            question?.ANSWER?.ANSWERS?.map((eachValue: any, i: any) => {
              this.getAnswerLabelControls(index)[i].get('ANSWER')?.reset();
              this.getAnswerLabelControls(index)[i].get('ANSWER')?.setValue('');
            });
          }
          this.getArrayControls()?.at(index)?.get('ANSWERS')?.reset();
          this.getArrayControls()?.at(index)?.get('ANSWERS')?.setValue('');
        }
      });
    });
  }

  onAnsweringCheckbox(value: any, idx: any) {
    let currentAnswers = value?.ANSWER?.ANSWERS;
    if (currentAnswers == undefined) {
      let currentAnswer = deepdiveGroup8.find((element: { ID: string; }) => element.ID === value.ID)
      currentAnswers = currentAnswer?.ANSWER?.ANSWERS;
    }
    currentAnswers[idx].ANSWER = currentAnswers[idx].ANSWER === 'true' ? 'false' : 'true';

    this.deepDiveQuestions.map((question, index) => {
      value?.RELATED_QUESTIONS?.filter((id: any) => {
        if (Number(id) === Number(question?.ID)) {
          question.DISABLED = !this.calculateShouldShowDependentQuestions(currentAnswers);
          //Disabling the answers for the dependent question if a checkbox is checked
          this.getArrayControls()
            ?.at(index)
            ?.get('DISABLED')
            ?.setValue(question.DISABLED);
          this.getArrayControls()?.at(index)?.get('ANSWERS')?.reset();
          this.getArrayControls()?.at(index)?.get('ANSWERS')?.setValue('');
        }
      });
    });

    this.calculateAllDependentQuestionsHidden();
  }

  calculateShouldShowDependentQuestions(currentAnswers: any,) {
    return currentAnswers.find((element: { ANSWER: string; }) => element.ANSWER === 'true') ? true : false;
  }

  calculateAllDependentQuestionsHidden() {
    this.allDependentQuestionsHidden = this.deepDiveQuestions.find((value: { DISABLED: boolean; ID: string; }) => value.DISABLED == false && Number(value.ID) > 55 && Number(value.ID) < 68) ? false : true;
  }

  hasError(index: any, type: any) {
    let validators: any = type === 'text' ? ['minLength', 'maxLength'] : ['min', 'max'];
    return Boolean((this.getArrayControls()[index].get('ANSWERS')?.hasError(validators[0]) ||
      this.getArrayControls()[index].get('ANSWERS')?.hasError(validators[1])))
  }

  isTouched(index: any) {
    return Boolean(this.getArrayControls()[index].get('ANSWERS')?.touched)
  }
}
