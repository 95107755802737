<div class="deepdive-goals-layout">
  <form [formGroup]="deepdiveGoalsForm">
    <p class="deepdive-notes-title">Titel Doelstelling</p>
    <div class="goal-text">
      <span>Voorgestelde doel voor de klant is om</span>
      <input
        formControlName="GOALNUMBER"
        onlynumber
        min="0"
        max="100"
        maxlength="3"
      />
      <span>% hernieuwbare energie te gebruiken in 2030. </span>
      <div
        class="d-flex pt-2"
        id="error_message_goal"
        [ngClass]="
          (deepdiveGoalsForm.get('GOALNUMBER')?.hasError('min') ||
            deepdiveGoalsForm.get('GOALNUMBER')?.hasError('max')) &&
          deepdiveGoalsForm.get('GOALNUMBER')?.touched
            ? 'visible'
            : 'invisible'
        "
      >
        <img
          src="../../../assets/images/exclamation-circle.svg"
          alt="Error"
          class="align-self-start"
        />
        <span
          class="error px-1"
          *ngIf="
            deepdiveGoalsForm?.get('GOALNUMBER')?.hasError('min') ||
            deepdiveGoalsForm?.get('GOALNUMBER')?.hasError('max')
          "
          >Maximale toegestane waarde is 100</span
        >
      </div>
    </div>
    <p class="deepdive-notes-title">Beschrijving doelstelling</p>

    <p
      class="deepdive-text-info d-flex justify-content-between align-items-center"
      *ngIf="deepdiveGoalsForm?.get('ADDED')?.value"
    >
      <span
        class="deepdive-text-info"
        [innerHTML]="deepdiveGoalsForm.get('TEXT')?.value ?? ''"
      >
      </span>
      <app-context-menu
        [menuOptions]="menuOptions"
        (menuOptionSelected)="onMenuOptionSelected($event, deepdiveGoalsForm)"
      ></app-context-menu>
    </p>

    <app-text-editor
      id="deepdive_goal"
      [editorFormGroup]="deepdiveGoalsForm"
      controlName="TEXT"
      *ngIf="!deepdiveGoalsForm?.get('ADDED')?.value"
    ></app-text-editor>
    <div class="add-buttons-block">
      <button class="cancel-button bg-white border-0">Cancel</button>
      <button
        class="button-primary-white"
        [disabled]="
          !deepdiveGoalsForm.get('GOALNUMBER')?.value ||
          !deepdiveGoalsForm.get('TEXT')?.value
        "
      >
        <span
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
          *ngIf="deepdiveGoalsForm?.get('LOADER')?.value"
        ></span>
        <span
          *ngIf="
            !deepdiveGoalsForm?.get('LOADER')?.value &&
            menuSelected?.dataKey !== 'edit'
          "
          >Toevoegen</span
        >
        <span
          *ngIf="
            !deepdiveGoalsForm?.get('LOADER')?.value &&
            menuSelected?.dataKey === 'edit'
          "
          >Opslaan</span
        >
      </button>
    </div>
  </form>
</div>
