import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { set } from 'lodash';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent implements OnInit {
  @Input() selectOptions?: any;
  @Input() placeholder = '';
  @Input() id = '';
  @Input() label = '';
  @Input() disabled?: any;
  @Input() readonly?: any;
  @Input() selectedValue: any = '';
  @Output() selectedOption = new EventEmitter<any>();
  open: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  openChange(event: any) {
    this.open = event;
  }

  select(option: any) {
    this.selectOptions.forEach((option: any) => delete option.isSelected);
    set(option, 'isSelected', true);
    this.selectedValue = option;
    this.selectedOption.emit(option);
  }
}
