<div ngbDropdown [open]="isDropdownOpen" class="menu-wrapper">
    <button class="btn btn-sm" id="dropdownMenuButton" (click)="toggleDropdown()">
        <img src="assets/images/three-dots-horizontal.svg" alt="Descending" />
    </button>
    <div ngbDropdownMenu  display="dynamic" class="p-4 flex-xl-row dropdown-menu dropdown-wrapper menu-container" aria-labelledby="dropdownMenuButton">
        <div class="menu-item p-2">
            <label class="label">Sort By</label>
            <div ngbDropdown [open]="isSubmenuOpen" class="sub-menu-wrapper">
                <label class="label-selected" (click)="toggleSubMenu()">
                    {{sortBy}}
                    <img src="assets/images/right-arrow-enabled.svg" class="sorting-arrow-right" alt="arrow icon" />
                </label>
                <div ngbDropdownMenu  display="dynamic" class="p-4 flex-xl-row dropdown-menu dropdown-wrapper sub-menu-container" aria-labelledby="dropdownMenuButton">
                    <label class="label-selected pb-2" (click)="toggleSubMenu()">
                        <img src="assets/images/left-arrow-enabled.svg" class="sorting-arrow-left" alt="arrow icon" />
                        Sort by
                    
                    </label>
                    <div class="menu-item p-2" >
                        <label class="label" (click)="setSortByValue(sortingEnums.HIGH_TO_LOW)"> Value: {{sortingEnums.HIGH_TO_LOW}} </label>
                    </div>
                    <div class="menu-item p-2">
                        <label class="label" (click)="setSortByValue(sortingEnums.LOW_TO_HIGH)">Value: {{sortingEnums.LOW_TO_HIGH}} </label>
                    </div>
                    <div class="menu-item p-2" >
                        <label class="label" (click)="setSortByValue(sortingEnums.LOCATION_NAME_AZ)" >{{sortingEnums.LOCATION_NAME_AZ}}</label>
                    </div>
                    <div class="menu-item p-2" >
                        <label class="label" (click)="setSortByValue(sortingEnums.LOCATION_NAME_ZA)" >{{sortingEnums.LOCATION_NAME_ZA}}</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="menu-item p-2">
            <label class="label align-items-end">Show Total</label>
            <label class="switch">
                <input type="checkbox" [(ngModel)]="isShowTotalEnabled" (ngModelChange)="onChangeSwitch($event)">
                <span class="slider round"></span>
            </label>
        </div>
        <!-- <label class="label p-2">Download as Excel</label> -->
        <!-- <label class="label p-2">Download as Image</label> -->
    </div>
</div>


