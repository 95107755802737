import { StoreModule, combineReducers, MetaReducer } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { AppReducers, metaReducers } from './reducers';
import { environment } from 'src/environments/environment';
// import { AuthEffects } from './effects/auth';

const combinedReducers = combineReducers(AppReducers);

export function reducer(state: any, action: any) {
  return combinedReducers(state, action);
}

let modules = [
  StoreModule.forRoot(AppReducers, { metaReducers }),
  EffectsModule.forRoot([]),
  !environment.production ? StoreDevtoolsModule.instrument() : [],
];

// if (localStorage.getItem('debug') === 'true') {
//   modules.push(StoreDevtoolsModule.instrument({ maxAge: 25 }));
// }

export const STORE = modules;
