import { ActionReducerMap, MetaReducer } from '@ngrx/store';

import { IAppState } from '../state';
import { deepdiveAssessmentReducer } from './deepdiveAssessment.reducer';
import { hydrationMetaReducer } from '../hydration/hydration.reducer';

export const AppReducers: ActionReducerMap<IAppState, any> = {
  deepdiveAnswerState: deepdiveAssessmentReducer,
};

export const metaReducers: MetaReducer[] = [hydrationMetaReducer];
