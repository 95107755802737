export const regions = [
    { REGION_ID: "01_GRONINGEN_DRENTHE", NAME: "Groningen-Drenthe" },
    { REGION_ID: "02_FRIESLAND", NAME: "Friesland" },
    { REGION_ID: "03_MIDDEN_OOST_NEDERLAND", NAME: "Midden Oost Nederland" },
    { REGION_ID: "04_TWENTE_ACHTERHOEK", NAME: "Twente-Achterhoek" },
    { REGION_ID: "05_GELDERLAND_ZUID", NAME: "Gelderland-Zuid" },
    { REGION_ID: "06_MIDDEN_NEDERLAND", NAME: "Midden Nederland" },
    { REGION_ID: "07_NOORD_HOLLAND_NOORD", NAME: "Noord-Holland-Noord" },
    { REGION_ID: "08_METROPOOLREGIO_AMSTERDAM", NAME: "Metropoolregio Amsterdam" },
    { REGION_ID: "09_LEIDEN_HAAGLANDEN", NAME: "Leiden-Haaglanden" },
    { REGION_ID: "10_ZUID_HOLLAND", NAME: "Zuid-Holland" },
    { REGION_ID: "11_ZUIDWEST_NEDERLAND", NAME: "Zuidwest-Nederland" },
    { REGION_ID: "12_MIDDEN_BRABANT", NAME: "Midden-Brabant" },
    { REGION_ID: "13_OOST_BRABANT", NAME: "Oost-Brabant" },
    { REGION_ID: "14_LIMBURG", NAME: "Limburg" }
];