import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {
  public activeModal = inject(NgbActiveModal);
  @Output() actionButtonClicked: EventEmitter<any> = new EventEmitter();
  showButtonLoader: boolean = false;

  @Input() data: any;

  constructor() {}

  ngOnInit(): void {}

  clickModal() {
    this.showButtonLoader = true;
    this.actionButtonClicked.emit(this.data?.buttonLabel);
  }
}
