<div class="d-flex">
  <img
    [src]="
      groupId === 1
        ? '../../../assets/images/left-arrow-disabled.svg'
        : '../../../assets/images/left-arrow-enabled.svg'
    "
    alt="Left-Arrow"
    (click)="groupId > 1 && navigateGroups('previous')"
    [ngClass]="groupId === 1 ? 'cursor-not-allowed' : 'cursor'"
  />
  <span class="px-3 heading">{{ label }}</span>
  <img
    [src]="
      groupId === groupsLength
        ? '../../../assets/images/right-arrow-disabled.svg'
        : '../../../assets/images/right-arrow-enabled.svg'
    "
    alt="Right-Arrow"
    (click)="groupId < groupsLength && navigateGroups('next')"
    [ngClass]="groupId === groupsLength ? 'cursor-not-allowed' : 'cursor'"
  />
</div>
