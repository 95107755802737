import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GROUPS_LENGTH } from 'src/app/shared/utilities/constants';

@Component({
  selector: 'app-answers-navigation',
  templateUrl: './answers-navigation.component.html',
  styleUrls: ['./answers-navigation.component.scss'],
})
export class AnswersNavigationComponent implements OnInit {
  groupsLength: number = GROUPS_LENGTH;
  @Input() label = '';
  @Input() groupId: any;
  @Output() onClickingNavigation = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  /**
   * Emitting when user clicks on the left/right navigation arrows
   * @param direction - string (Left/Right)
   */
  navigateGroups(direction: string) {
    this.onClickingNavigation.emit(direction);
  }
}
