import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, Validators, FormGroup, AbstractControl } from '@angular/forms';
import { SharedService } from 'src/app/shared/services/shared.service';
import { UtilService } from 'src/app/shared/services/util.service';
@Component({
  selector: 'app-add-location-modal',
  templateUrl: './add-location-modal.component.html',
  styleUrls: ['./add-location-modal.component.scss']
})
export class AddLocationModalComponent implements OnInit {

  @Input() maxCharacters: number = 40;
  @Input() locationNameInput!: any;
  locationForm!: FormGroup | AbstractControl | any;

  constructor(public activeModal: NgbActiveModal,
    private sharedService: SharedService,
    private formBuilder: FormBuilder,
    public utilService: UtilService) { }



  ngOnInit() {
    this.initForm();
  }

  isDisabled() {
    let locationNameFormControl = this.locationForm.controls['locationName'];
    if (locationNameFormControl.hasError('required') ||
      locationNameFormControl.hasError('maxlength') ||
      (this.locationNameInput && locationNameFormControl.value === this.locationNameInput)) {
      return true;
    }
    return false;
  }

  initForm() {
    this.locationForm = this.formBuilder.group({
      locationName: [
        this.locationNameInput ?? '', [Validators.required, Validators.maxLength(40)]]
    });
  }
  saveLocation() {
    let locationNameValue = this.locationForm?.controls?.['locationName']?.value;
    this.activeModal.close(locationNameValue);
    this.locationForm.reset();
  }

  cancel() {
    this.activeModal.dismiss();
  }
}
