export const deepdiveGroup8 =        [
    {
      "ID": "43",
      "SPREADSHEET_ID": "1.1",
      "TYPE": "CHECKBOX",
      "RELATED_QUESTIONS": [
        "56"
      ],
      "TEXT": "Elektronica en ICT:",
      "PLACEHOLDER": "",
      "ANSWER_LABELS": [
        "Mobiele telefoons, tablets, laptops, laders...",
        "Printers en cartridges",
        "Verlichting"
      ],
      "ANSWER": {
        "QUESTION_ID": "43",
        "ANSWERS": [
          {
            "LABEL": "Mobiele telefoons, tablets, laptops, laders...",
            "ANSWER": "false"
          },
          {
            "LABEL": "Printers en cartridges",
            "ANSWER": "false"
          },
          {
            "LABEL": "Verlichting",
            "ANSWER": "false"
          }
        ]
      },
      "DISABLED": false
    },
    {
      "ID": "44",
      "SPREADSHEET_ID": "1.2",
      "TYPE": "CHECKBOX",
      "RELATED_QUESTIONS": [
        "57"
      ],
      "TEXT": "Batterijen en voertuigen:",
      "PLACEHOLDER": "",
      "ANSWER_LABELS": [
        "Batterijen en accu's",
        "Autowrakken"
      ],
      "ANSWER": {
        "QUESTION_ID": "44",
        "ANSWERS": [
          {
            "LABEL": "Batterijen en accu's",
            "ANSWER": "false"
          },
          {
            "LABEL": "Autowrakken",
            "ANSWER": "false"
          }
        ]
      },
      "DISABLED": false
    },
    {
      "ID": "45",
      "SPREADSHEET_ID": "1.3",
      "TYPE": "CHECKBOX",
      "RELATED_QUESTIONS": [
        "58"
      ],
      "TEXT": "Verpakking:",
      "PLACEHOLDER": "",
      "ANSWER_LABELS": [
        "Koffiebekers",
        "PD",
        "Piepschuim"
      ],
      "ANSWER": {
        "QUESTION_ID": "45",
        "ANSWERS": [
          {
            "LABEL": "Koffiebekers",
            "ANSWER": "false"
          },
          {
            "LABEL": "PD",
            "ANSWER": "false"
          },
          {
            "LABEL": "Piepschuim",
            "ANSWER": "false"
          }
        ]
      },
      "DISABLED": false
    },
    {
      "ID": "46",
      "SPREADSHEET_ID": "1.4",
      "TYPE": "CHECKBOX",
      "RELATED_QUESTIONS": [
        "59"
      ],
      "TEXT": "Kunststoffen:",
      "PLACEHOLDER": "",
      "ANSWER_LABELS": [
        "Plastic"
      ],
      "ANSWER": {
        "QUESTION_ID": "46",
        "ANSWERS": [
          {
            "LABEL": "Plastic",
            "ANSWER": "false"
          }
        ]
      },
      "DISABLED": false
    },
    {
      "ID": "47",
      "SPREADSHEET_ID": "1.5",
      "TYPE": "CHECKBOX",
      "RELATED_QUESTIONS": [
        "60"
      ],
      "TEXT": "Textiel:",
      "PLACEHOLDER": "",
      "ANSWER_LABELS": [
        "Textiel"
      ],
      "ANSWER": {
        "QUESTION_ID": "47",
        "ANSWERS": [
          {
            "LABEL": "Textiel",
            "ANSWER": "false"
          }
        ]
      },
      "DISABLED": false
    },
    {
      "ID": "48",
      "SPREADSHEET_ID": "1.6",
      "TYPE": "CHECKBOX",
      "RELATED_QUESTIONS": [
        "61"
      ],
      "TEXT": "Constructie en gebouwen:",
      "PLACEHOLDER": "",
      "ANSWER_LABELS": [
        "Puin",
        "Hout",
        "Metalen",
        "Gips",
        "Steen",
        "Dakgrind"
      ],
      "ANSWER": {
        "QUESTION_ID": "48",
        "ANSWERS": [
          {
            "LABEL": "Puin",
            "ANSWER": "false"
          },
          {
            "LABEL": "Hout",
            "ANSWER": "false"
          },
          {
            "LABEL": "Metalen",
            "ANSWER": "false"
          },
          {
            "LABEL": "Gips",
            "ANSWER": "false"
          },
          {
            "LABEL": "Steen",
            "ANSWER": "false"
          },
          {
            "LABEL": "Dakgrind",
            "ANSWER": "false"
          }
        ]
      },
      "DISABLED": false
    },
    {
      "ID": "49",
      "SPREADSHEET_ID": "1.7",
      "TYPE": "CHECKBOX",
      "RELATED_QUESTIONS": [
        "62"
      ],
      "TEXT": "Levensmiddelen, nutriënten, en groen:",
      "PLACEHOLDER": "",
      "ANSWER_LABELS": [
        "Swill/GFE",
        "Frituurvet",
        "GFT/Groen afval"
      ],
      "ANSWER": {
        "QUESTION_ID": "49",
        "ANSWERS": [
          {
            "LABEL": "Swill/GFE",
            "ANSWER": "false"
          },
          {
            "LABEL": "Frituurvet",
            "ANSWER": "false"
          },
          {
            "LABEL": "GFT/Groen afval",
            "ANSWER": "false"
          }
        ]
      },
      "DISABLED": false
    },
    {
      "ID": "50",
      "SPREADSHEET_ID": "1.8",
      "TYPE": "CHECKBOX",
      "RELATED_QUESTIONS": [
        "63"
      ],
      "TEXT": "Gevaarlijk Afval:",
      "PLACEHOLDER": "",
      "ANSWER_LABELS": [
        "Chemisch afval",
        "Medisch afval",
        "Ander gevaarlijk afval"
      ],
      "ANSWER": {
        "QUESTION_ID": "50",
        "ANSWERS": [
          {
            "LABEL": "Chemisch afval",
            "ANSWER": "false"
          },
          {
            "LABEL": "Medisch afval",
            "ANSWER": "false"
          },
          {
            "LABEL": "Ander gevaarlijk afval",
            "ANSWER": "false"
          }
        ]
      },
      "DISABLED": false
    },
    {
      "ID": "51",
      "SPREADSHEET_ID": "1.9",
      "TYPE": "CHECKBOX",
      "RELATED_QUESTIONS": [
        "64"
      ],
      "TEXT": "Glas:",
      "PLACEHOLDER": "",
      "ANSWER_LABELS": [
        "Glas"
      ],
      "ANSWER": {
        "QUESTION_ID": "51",
        "ANSWERS": [
          {
            "LABEL": "Glas",
            "ANSWER": "false"
          }
        ]
      },
      "DISABLED": false
    },
    {
      "ID": "52",
      "SPREADSHEET_ID": "1.10",
      "TYPE": "CHECKBOX",
      "RELATED_QUESTIONS": [
        "65"
      ],
      "TEXT": "Papier en karton:",
      "PLACEHOLDER": "",
      "ANSWER_LABELS": [
        "Papier",
        "Karton"
      ],
      "ANSWER": {
        "QUESTION_ID": "52",
        "ANSWERS": [
          {
            "LABEL": "Papier",
            "ANSWER": "false"
          },
          {
            "LABEL": "Karton",
            "ANSWER": "false"
          }
        ]
      },
      "DISABLED": false
    },
    {
      "ID": "53",
      "SPREADSHEET_ID": "1.11",
      "TYPE": "CHECKBOX",
      "RELATED_QUESTIONS": [
        "66"
      ],
      "TEXT": "Restafval:",
      "PLACEHOLDER": "",
      "ANSWER_LABELS": [
        "Restafval"
      ],
      "ANSWER": {
        "QUESTION_ID": "53",
        "ANSWERS": [
          {
            "LABEL": "Restafval",
            "ANSWER": "false"
          }
        ]
      },
      "DISABLED": false
    },
    {
      "ID": "54",
      "SPREADSHEET_ID": "1.12",
      "TYPE": "CHECKBOX",
      "RELATED_QUESTIONS": [
        "67"
      ],
      "TEXT": "Restafval:",
      "PLACEHOLDER": "",
      "ANSWER_LABELS": [
        "Restafval"
      ],
      "ANSWER": {
        "QUESTION_ID": "54",
        "ANSWERS": [
          {
            "LABEL": "Restafval",
            "ANSWER": "false"
          }
        ]
      },
      "DISABLED": false
    },
    {
      "ID": "55",
      "SPREADSHEET_ID": "1.13",
      "TYPE": "TEXT_AREA",
      "MIN_VALUE": "0",
      "MAX_VALUE": "500",
      "TEXT": "Anders, namelijk…",
      "PLACEHOLDER": "",
      "ANSWER": {
        "QUESTION_ID": "55",
        "ANSWERS": [
          {
            "ANSWER": ""
          }
        ]
      },
      "DISABLED": false
    },
    {
      "ID": "56",
      "SPREADSHEET_ID": "1.14",
      "TYPE": "NUMBER",
      "MIN_VALUE": "0",
      "MAX_VALUE": "500000000000",
      "UNIT": "kg",
      "TEXT": "Elektronica en ICT",
      "PLACEHOLDER": "kg 0",
      "ANSWER": {
        "QUESTION_ID": "56",
        "ANSWERS": [
          {
            "ANSWER": ""
          }
        ]
      },
      "DISABLED": true
    },
    {
      "ID": "57",
      "SPREADSHEET_ID": "1.15",
      "TYPE": "NUMBER",
      "MIN_VALUE": "0",
      "MAX_VALUE": "500000000000",
      "UNIT": "kg",
      "TEXT": "Batterijen en voertuigen",
      "PLACEHOLDER": "kg 0",
      "ANSWER": {
        "QUESTION_ID": "57",
        "ANSWERS": [
          {
            "ANSWER": ""
          }
        ]
      },
      "DISABLED": true
    },
    {
      "ID": "58",
      "SPREADSHEET_ID": "1.16",
      "TYPE": "NUMBER",
      "MIN_VALUE": "0",
      "MAX_VALUE": "500000000000",
      "UNIT": "kg",
      "TEXT": "Verpakking",
      "PLACEHOLDER": "kg 0",
      "ANSWER": {
        "QUESTION_ID": "58",
        "ANSWERS": [
          {
            "ANSWER": ""
          }
        ]
      },
      "DISABLED": true
    },
    {
      "ID": "59",
      "SPREADSHEET_ID": "1.17",
      "TYPE": "NUMBER",
      "MIN_VALUE": "0",
      "MAX_VALUE": "500000000000",
      "UNIT": "kg",
      "TEXT": "Kunststoffen",
      "PLACEHOLDER": "kg 0",
      "ANSWER": {
        "QUESTION_ID": "59",
        "ANSWERS": [
          {
            "ANSWER": ""
          }
        ]
      },
      "DISABLED": true
    },
    {
      "ID": "60",
      "SPREADSHEET_ID": "1.18",
      "TYPE": "NUMBER",
      "MIN_VALUE": "0",
      "MAX_VALUE": "500000000000",
      "UNIT": "kg",
      "TEXT": "Textiel",
      "PLACEHOLDER": "kg 0",
      "ANSWER": {
        "QUESTION_ID": "60",
        "ANSWERS": [
          {
            "ANSWER": ""
          }
        ]
      },
      "DISABLED": true
    },
    {
      "ID": "61",
      "SPREADSHEET_ID": "1.19",
      "TYPE": "NUMBER",
      "MIN_VALUE": "0",
      "MAX_VALUE": "500000000000",
      "UNIT": "kg",
      "TEXT": "Constructie en gebouwen",
      "PLACEHOLDER": "kg 0",
      "ANSWER": {
        "QUESTION_ID": "61",
        "ANSWERS": [
          {
            "ANSWER": ""
          }
        ]
      },
      "DISABLED": true
    },
    {
      "ID": "62",
      "SPREADSHEET_ID": "1.20",
      "TYPE": "NUMBER",
      "MIN_VALUE": "0",
      "MAX_VALUE": "500000000000",
      "UNIT": "kg",
      "TEXT": "Levensmiddelen en nutriënten",
      "PLACEHOLDER": "kg 0",
      "ANSWER": {
        "QUESTION_ID": "62",
        "ANSWERS": [
          {
            "ANSWER": ""
          }
        ]
      },
      "DISABLED": true
    },
    {
      "ID": "63",
      "SPREADSHEET_ID": "1.21",
      "TYPE": "NUMBER",
      "MIN_VALUE": "0",
      "MAX_VALUE": "500000000000",
      "UNIT": "kg",
      "TEXT": "Gevaarlijk afval",
      "PLACEHOLDER": "kg 0",
      "ANSWER": {
        "QUESTION_ID": "63",
        "ANSWERS": [
          {
            "ANSWER": ""
          }
        ]
      },
      "DISABLED": true
    },
    {
      "ID": "64",
      "SPREADSHEET_ID": "1.22",
      "TYPE": "NUMBER",
      "MIN_VALUE": "0",
      "MAX_VALUE": "500000000000",
      "UNIT": "kg",
      "TEXT": "Glas",
      "PLACEHOLDER": "kg 0",
      "ANSWER": {
        "QUESTION_ID": "64",
        "ANSWERS": [
          {
            "ANSWER": ""
          }
        ]
      },
      "DISABLED": true
    },
    {
      "ID": "65",
      "SPREADSHEET_ID": "1.23",
      "TYPE": "NUMBER",
      "MIN_VALUE": "0",
      "MAX_VALUE": "500000000000",
      "UNIT": "kg",
      "TEXT": "Papier en karton",
      "PLACEHOLDER": "kg 0",
      "ANSWER": {
        "QUESTION_ID": "65",
        "ANSWERS": [
          {
            "ANSWER": ""
          }
        ]
      },
      "DISABLED": true
    },
    {
      "ID": "66",
      "SPREADSHEET_ID": "1.24",
      "TYPE": "NUMBER",
      "MIN_VALUE": "0",
      "MAX_VALUE": "500000000000",
      "UNIT": "kg",
      "TEXT": "Restafval",
      "PLACEHOLDER": "kg 0",
      "ANSWER": {
        "QUESTION_ID": "66",
        "ANSWERS": [
          {
            "ANSWER": ""
          }
        ]
      },
      "DISABLED": true
    },
    {
      "ID": "67",
      "SPREADSHEET_ID": "1.25",
      "TYPE": "NUMBER",
      "MIN_VALUE": "0",
      "MAX_VALUE": "500000000000",
      "UNIT": "kg",
      "TEXT": "Anders",
      "PLACEHOLDER": "kg 0",
      "ANSWER": {
        "QUESTION_ID": "67",
        "ANSWERS": [
          {
            "ANSWER": ""
          }
        ]
      },
      "DISABLED": true
    }
  ]