import { Component, AfterViewInit, Renderer2, ElementRef, Input , OnInit} from '@angular/core';
import { round } from 'lodash';
import { LegendPosition, LegendType } from 'ngx-charts-extended';

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss']
})

export class PieChartComponent implements AfterViewInit, OnInit {
  @Input() chartDimensions: [number, number] = [938, 314]; // Default view dimensions
  @Input() graphTopTitle: string = ''; // Default top title
  @Input() graphBottomTitle: string = ''; // Default bottom title
  @Input() colorScheme: any; // You may need to define the type of colorScheme based on your data structure
  @Input() data: any[] = []; // Your chart data
  @Input() yScaleMax: number = 100; // Default yScaleMax
  @Input() barPadding: number = 42; // Default bar padding
  @Input() formatDataLabel!: any; // You may need to define the type of formatDataLabel based on your data structure
  @Input() yAxisLabel: string = '';
  @Input() legend!: boolean;
  @Input() labels!: boolean;
  @Input() unit!: any;
  @Input() showTotal: any = true;
  @Input() pieChartTotal: any;
  width: number = this.chartDimensions[0] || 0;
  height: number = this.chartDimensions[1] || 0;

  view: [number, number] = [this.width, this.height];

  // options
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = false;
  showXAxisLabel = true;
  xAxisLabel = 'Renewable';
  showYAxisLabel = true;
  // yAxisLabel = 'Ratio of renewable energy (%)';
  rotateXAxisTicks = false;
  legendPosition: LegendPosition = LegendPosition.Below;
  legendType: LegendType = LegendType.Legend;

  formatXAxisTickLabel(value: any): string {
    // Your custom formatting logic here
    // For example, you can format dates using a library like moment.js
    // return moment(value).format('YYYY-MM-DD');

    // or simply return the value as is
    return `${value}`;
  }


  ngOnInit(): void {}
  ngAfterViewInit(): void { 
    const labels = this.elementRef.nativeElement.querySelectorAll('.textDatalabel'); 
    labels.forEach((element: HTMLElement) => { this.renderer.setStyle(element, 'font-size', '25px'); });
    console.log("after view init");
  }


  constructor(private renderer: Renderer2, private elementRef: ElementRef) { }

  onSelect(event: any) {
    console.log(event);
  }

  formatTooltipText=(chartValue: any):any =>{
    console.log("format tooltiptext", chartValue);
    console.log("current data", this.pieChartTotal, this.xAxisLabel);
    const percentage = round((chartValue.value/this.pieChartTotal)*100)
    if(!this.showTotal){
      return `${chartValue?.data?.label}<br/>(${percentage}%)`;
    }

    return `${chartValue?.data?.label}:   ${chartValue?.data?.value} ${this.unit}<br/>(${percentage}%)`;
  }
}
