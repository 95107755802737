import { IDeepdiveAssessment } from 'src/app/shared/models/deepdive-assessment.interface';

export interface IDeepdiveAssessmentState {
  deepdiveAnswers: IDeepdiveAssessment[];
  groupId: 1,
}

export const initialDeepdiveAssessmentState: IDeepdiveAssessmentState = {
  deepdiveAnswers: [],
  groupId: 1,
};
