<div
  class="checkbox-group-layout content-container"
  [ngClass]="{
    boxShadow: !applyBoxShadow
  }"
>
  <div class="content-container">
    <ng-container *ngFor="let item of itemsList">
      <a
        class="list-group flex-row justify-content-between list-item-content"
        (click)="toggleCheckbox($event, item)"
      >
        <div>
          <span class="icon">
            <img [src]="item.ICON" class="me-2" alt="icon" />
          </span>
          <label class="itemLabel">{{ item.TITLE }}</label>
        </div>
        <div>
          <input class="checkbox" type="checkbox" [ngModel]="item.checked" />
        </div>
      </a>
    </ng-container>
  </div>
</div>
