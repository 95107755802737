import { Component, inject, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { cloneDeep } from 'lodash';
import { ConsultantService } from 'src/app/shared/services/consultant.service';
import { ErrorService } from 'src/app/shared/services/error.service';
import { ModalService } from 'src/app/shared/services/modal.service';
import {
  ToastService,
  TOAST_STATE,
} from 'src/app/shared/services/toast.service';
import { UtilService } from 'src/app/shared/services/util.service';
import {
  COMMENT_MENU_OPTIONS,
  DEEPDIVE_PERFORMANCE_SECTIONS,
} from 'src/app/shared/utilities/constants';
import { ModalComponent } from '../modals/modal/modal.component';

@Component({
  selector: 'app-deepdive-performance',
  templateUrl: './deepdive-performance.component.html',
  styleUrls: ['./deepdive-performance.component.scss'],
})
export class DeepdivePerformanceComponent implements OnInit {
  energyPerformanceSections: any = cloneDeep(DEEPDIVE_PERFORMANCE_SECTIONS);
  menuOptions: any = cloneDeep(COMMENT_MENU_OPTIONS);
  menuSelected: any = {};
  modal: any;
  performanceForm!: FormGroup;
  @Input() type: any;
  @Input() customerID: any;
  @Input() breakEvenGoal: any;
  formBuilder = inject(FormBuilder);
  private errorService = inject(ErrorService);
  private modalService = inject(ModalService);
  private toastService = inject(ToastService);
  public utilService = inject(UtilService);
  private consultantService = inject(ConsultantService);

  constructor() {}

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.performanceForm = this.formBuilder.group({
      performanceSections: this.formBuilder.array(
        this.energyPerformanceSections.map((value: any) =>
          this.formBuilder.group({
            TITLE: this.formBuilder.control(value?.title ?? ''),
            TEXT: this.formBuilder.control(''),
            ADDED: this.formBuilder.control(false),
            SAVED: this.formBuilder.control(false),
            LOADER: this.formBuilder.control(false),
          })
        )
      ),
    });
  }

  get performanceSections() {
    return this.performanceForm.controls['performanceSections'] as FormArray;
  }

  getPerformanceContent(content: any, section: any) {
    section[section?.key] = content;
  }

  addPerformanceText(section: any, index: number) {
    this.errorService.hide();
    this.utilService.setControlValue(
      this.performanceSections,
      'LOADER',
      true,
      index
    );
    const postObj = {
      [this.type]: [
        {
          ID: Number(index) + 1,
          TEXT: this.performanceSections?.at(index)?.get('TEXT')?.value,
        },
      ],
    };
    this.consultantService
      .addOrEditDeepdiveNotes(
        this.customerID,
        this.breakEvenGoal.ID,
        this.type,
        postObj
      )
      .subscribe({
        next: (response: any) => {
          this.utilService.setControlValue(
            this.performanceSections,
            'ADDED',
            true,
            index
          );
        },
        error: (error) => {
          // handle error
          this.utilService.setControlValue(
            this.performanceSections,
            'LOADER',
            false,
            index
          );
          this.errorService.show();
        },
        complete: () => {
          //Request completed
          this.utilService.setControlValue(
            this.performanceSections,
            'LOADER',
            false,
            index
          );
        },
      });
  }

  /**
   * It opens the confirmation modal
   */
  openModal(
    title?: string,
    description?: string,
    buttonLabel?: string,
    modalType?: string,
    section?: any,
    index?: any
  ) {
    this.modal = this.modalService.open(ModalComponent, {
      backdrop: 'static',
      windowClass: 'submitAnswersModal',
      centered: true,
    });
    this.modal.componentInstance.data = {
      title,
      description,
      buttonLabel,
      showCancelButton: true,
    };
    this.modal.componentInstance.actionButtonClicked.subscribe(
      (actionType: string) => {
        if (actionType === 'Verwijderen') {
          this.deleteTextInfo(section, index);
          return;
        }
      }
    );
  }

  deleteTextInfo(section?: any, index?: any) {
    this.errorService.hide();
    this.utilService.setControlValue(
      this.performanceSections,
      'LOADER',
      true,
      index
    );
    const postObj = {
      [this.type]: [
        {
          ID: Number(index) + 1,
        },
      ],
    };
    this.consultantService
      .addOrEditDeepdiveNotes(
        this.customerID,
        this.breakEvenGoal.ID,
        this.type,
        postObj
      )
      .subscribe({
        next: (response: any) => {
          this.utilService.resetControlValue(
            this.performanceSections,
            'TEXT',
            index
          );
          this.utilService.setControlValue(
            this.performanceSections,
            'ADDED',
            false,
            index
          );
        },
        error: (error) => {
          // handle error
          this.utilService.setControlValue(
            this.performanceSections,
            'LOADER',
            false,
            index
          );
          this.errorService.show();
        },
        complete: () => {
          //Request completed
          this.utilService.setControlValue(
            this.performanceSections,
            'LOADER',
            false,
            index
          );
        },
      });
  }

  onMenuOptionSelected(option: any, section: any, index: number) {
    this.menuSelected = option;
    if (option?.dataKey === 'edit') {
      this.utilService.setControlValue(
        this.performanceSections,
        'ADDED',
        false,
        index
      );
      // this.addPerformanceText(section, index);
    } else {
      let title = 'Toelichting verwijderen';
      let description =
        'Weet u zeker dat u de toelichting wilt verwijderen? Deze actie kan niet ongedaan gemaakt worden.';
      let buttonLabel = 'Verwijderen';
      this.openModal(
        title,
        description,
        buttonLabel,
        'deleteText',
        section,
        index
      );
      return;
    }
  }

  cancel(section: any, index: number) {
    this.utilService.setControlValue(
      this.performanceSections,
      'ADDED',
      true,
      index
    );
  }
}
