<div class="step-indicator">
  <div *ngFor="let step of indicatorSteps">
    <div class="step-indicator-item margin">
      <div class="step-indicator-circle"
        [class.active-background-color]="stepService.currentStep === step.indicatorText"
        [class.completed-background-color]="stepService.stepCompleted[0]"
        [class.disabled-background-color]="stepService.currentStep !== step.indicatorText && !stepService.stepCompleted[0]">
        <span class="indicator-text" [class.active-indicator-text]="stepService.currentStep === step.indicatorText"
          [class.completed-indicator-text]="stepService.stepCompleted[0]"
          [class.disabled-indicator-text]="stepService.currentStep !== step.indicatorText && !stepService.stepCompleted[0]">
          {{step.indicatorText}}
        </span>
      </div>
      <div class="step-indicator-label" [class.active-label-color]="stepService.currentStep === step.indicatorText"
        [class.completed-label-color]="stepService.stepCompleted[0]"
        [class.disabled-label-color]="stepService.currentStep !== step.indicatorText && !stepService.stepCompleted[0]">
        {{step.indicatorLabel}}
      </div>
    </div>
  </div>
</div>



