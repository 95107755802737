<div
  class="container m-20"
  [style.width]="'fit-content'"

  style="margin: 12px; padding: 20px; background-color: white; height: fit-content;"
>
  <div class="row">
    <div class="col-md-6">
      <div class="legend-container" [style.width]="chartDimensions[0] + 'px'">
        <span class="graph-title">{{ graphTopTitle }}</span>
      </div>
      <div  [style.width]="chartDimensions[0] + 'px'">
        <ngx-charts-pie-chart
        [view]="chartDimensions"
        [scheme]="colorScheme"
        [results]="data"
        [gradient]="gradient"
        (select)="onSelect($event)"
        [legendPosition]="legendPosition"
        [legend]="legend"
        [doughnut]="true"
        [labels]="labels"
        [arcWidth]="0.6"
        [legendTitle]="''"
        [trimLabels]="false"
        [labelFormatting]="formatDataLabel"
        [showDoughnutSum]="showTotal"
        [totalUnit]="unit"
        [tooltipText]="formatTooltipText"
      >
      </ngx-charts-pie-chart>
      </div>

      <div
        *ngIf="graphBottomTitle"
        class="legend-container"
        [style.width]="chartDimensions[0] + 'px'"
      >
        <div class="legend-box" ></div>
        <span class="legend-text">{{ graphBottomTitle }}</span>
      </div>
    </div>
  </div>
</div>
