import { Component, inject, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ChartDataset, ChartOptions, Colors } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { UtilService } from 'src/app/shared/services/util.service';

@Component({
  selector: 'app-deepdive-charts',
  templateUrl: './deepdive-charts.component.html',
  styleUrls: ['./deepdive-charts.component.scss'],
})
export class DeepdiveChartsComponent implements OnInit {
  @Input() deepdiveStates!: any[];
  private router = inject(Router);
  deepdiveCharts: any = [];
  chartData: any;
  chartLabels: string[] = [];
  chartOptions: ChartOptions = {};
  chartDataLabel: any;
  options: any;
  @Input() deepdiveYear: any;
  @Input() currentCustomer:any;

  public utilService = inject(UtilService);

  constructor() {}

  ngOnInit(): void {}

  /**
   * Navigates to the respective screen based on the deepdive assessment states
   */
  navigateToDeepDiveInitialPage(deepdive: any) {
    window?.sessionStorage?.setItem(
      'selectDeepdiveStatus',
      deepdive?.DEEPDIVE_STATE
    );
    window?.sessionStorage?.setItem(
      'selectDeepdiveName',
      deepdive?.BREAK_EVEN_GOAL_TITLE
    );
    let route = '';
    // return this.router.navigate([`clients/intial-deepdive/${deepdive?.BREAK_EVEN_GOAL_ID}`]);
    switch (deepdive?.DEEPDIVE_STATE) {
      case 'DEEPDIVE_NOT_STARTED':
        route = `clients/intial-deepdive/${deepdive?.BREAK_EVEN_GOAL_ID}`;
        break;
      case 'DEEPDIVE_STARTED':
        route = `clients/ml-take-assessment/${deepdive?.BREAK_EVEN_GOAL_ID}`;
        break;
      case 'DEEPDIVE_SUBMITTED':
        route = `clients/ml-take-assessment/${deepdive?.BREAK_EVEN_GOAL_ID}`;
        break;
      case 'DEEPDIVE_PUBLISHED':
        route = `clients/deepdive-published/${this.currentCustomer.ID}/${deepdive?.BREAK_EVEN_GOAL_ID}/${this.deepdiveYear}`
        break;
    }

    return this.router.navigate([route]);
  }
}
