import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { Inject } from '@angular/core';
import { PreviousRouteService } from 'src/app/shared/services/previous-route.service';

@Component({
  selector: 'app-go-back',
  templateUrl: './go-back.component.html',
  styleUrls: ['./go-back.component.scss'],
})
export class GoBackComponent implements OnInit {
  @Input() route!: any;
  @Input() label!: any;
  isLocationBack!: boolean;
  currentUrl: any;
  previousUrl: any;

  constructor(private location: Location, private router: Router,  @Inject(DOCUMENT) private document: Document
  ,  private previousRouteService: PreviousRouteService
  ) {}

  ngOnInit(): void {
    // this.router.events.subscribe(event => {
    //   if (event instanceof NavigationEnd) {
    //     this.currentUrl = this.router.url;
    //     this.previousUrl =this.previousRouteService.getPreviousUrl();
    //     this.label = this.currentUrl !== this.previousUrl ? 'Terug' : this.label;
    //   }
    // });
  }


  // goBack(): void {
  //   //consultants/overview

    
  //   let dashboardType = this.router.url.split('/')[1];
  //   if(dashboardType === 'consultants'){
  //     this.location.back();
  //     if (this.currentUrl === this.previousUrl && this.route) {
  //       this.router.navigate([this.route]);
  //     } else {
  //       this.location.back();
  //     }
  //   } else {
  //     if (this.route) {
  //       this.router.navigate([this.route]);
  //     } else {
  //       this.location.back();
  //     }
  //   }

  // }


  goBack(): void {
    if (this.route) {
      this.router.navigate([this.route]);
    } else {
      this.location.back();
    }
  }
}
