export var multi = [
  {
    name: 'Zero Bar',
    series: [
      {
        name: 'none1',
        value: 0
      },
      {
        name: 'none',
        value: 100
      },
    ]
  },
  {
    name: 'Amsterdam',
    series: [
      {
        name: 'none1',
        value: 20
      },
      {
        name: 'none',
        value: 80
      },
    ]
  },
  {
    name: 'Utrecht',
    series: [
      {
        name: 'none1',
        value: 22
      },
      {
        name: 'none',
        value: 78
      },
    ]
  },
  {
    name: 'Ouderkerkaan den Ijssel',
    series: [
      {
        name: 'none1',
        value: 25
      },
      {
        name: 'none',
        value: 75
      },
    ]
  },
  {
    name: 'Hendrik Ido Ambacht',
    series: [
      {
        name: 'none1',
        value: 28
      },
      {
        name: 'none',
        value: 72
      },
    ]
  },
  {
    name: 'Westerhaar Vriezenveensewijk',
    series: [
      {
        name: 'none1',
        value: 35
      },
      {
        name: 'none',
        value: 65
      },
    ]
  },
  {
    name: 'Hoogezand',
    series: [
      {
        name: 'none1',
        value: 53
      },
      {
        name: 'none',
        value: 47
      },
    ]
  },
  {
    name: 'Gasselterboerveenschemond',
    series: [
      {
        name: 'none1',
        value: 82
      },
      {
        name: 'none',
        value: 18
      },
    ]
  },
  {
    name: 'Westerhaar Vriezenveensewijk (max40char)',
    series: [
      {
        name: 'none1',
        value: 84
      },
      {
        name: 'none',
        value: 16
      },
    ]
  },

];

