export const ENERGY_GRADES = ['A+++++', 'A++++', 'A+++', 'A++', 'A+', 'A', 'B'];

export function getFormGroupStyle(value: any): any {
  return {
    border: (value?.ID > 42 && value?.ID < 68) || (value?.ID >=13 && value?.ID <= 22) || (value?.ID == 28 && value?.DISABLED) ? 'none' : '',
    padding: (value?.ID > 42 && value?.ID < 68) || (value?.ID >=13 && value?.ID <= 22) || (value?.ID == 28 && value?.DISABLED)  ? '0' : '',
    display: ((value?.ID > 55 && value?.ID < 68)  && value?.DISABLED) ? 'none' : ''
  };
}


export function parseRefYear(deepDiveQuestions: any, year: any) {
    const searchRegExp = /<ref-year>/gi;
    const dDQuestions = JSON.stringify(deepDiveQuestions);
    const result = dDQuestions.replace(searchRegExp, year);
    return JSON.parse(result);
}


export function getLabel(value: any, refYear: string){
if(value?.ID >=13 && value?.ID <=19) {
  return value?.TEXT?.replace('Wat is het aantal wagens in uw wagenpark? - ', '')?.replace(' (0 indien n.v.t.)', '');
} else if(value?.ID >=20 && value?.ID <=22) {
  return value?.TEXT?.replace(`Wat was uw brandstofverbruik (liter) van uw wagenpark in ${refYear}? - `, '')?.replace(' (0 indien n.v.t.)', '');
} else if (value?.ID > 42 && value?.ID < 68){
  return value?.TEXT;
}
return '';
}