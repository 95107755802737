<div class="container-fluid">
  <div class="row">
    <div class="col">
      <div class="deepdive-tabs-header">
        <ul ngbNav #tabNav="ngbNav" class="nav-pills flex-row" [activeId]="deepdiveTab?.HEADER">
          <li id="{{ link.HEADER }}" [ngbNavItem]="link.HEADER" [domId]="link.key" *ngFor="let link of deepdiveTabs"
            class="me-4 pe-2 my-3">
            <a ngbNavLink (click)="onSelectingTab(link)" [ngClass]="{ active: link?.HEADER == deepdiveTab?.HEADER }">{{
              link.HEADER ?? "" }} {{link?.TYPE === "GENERIC" ? "(General)" : "" }}</a>
            <ng-template ngbNavContent>
              <ng-container *ngIf="deepDiveGroupQuestions?.QUESTIONS?.length">
                <div class="position-relative">
                  <div class="d-flex loader justify-content-center align-items-center mt-5 pt-5" *ngIf="showLoader">
                    <div class="spinner mt-5 pt-5">
                      <div class="spinner__circle">
                        <div class="spinner__circle-gradient"></div>

                        <div class="spinner__circle-inner"></div>
                      </div>
                      <span class="ms-2 text"> Een momentje...</span>
                    </div>
                  </div>
                  <app-deepdive-questions-multiple-locations *ngIf="!showLoader"
                    [deepDiveQuestions]="deepDiveGroupQuestions?.QUESTIONS" [selectedTab]="deepdiveTab"
                    (onSubmittingGroupAnswers)="onGroupAnswersSubmitted($event)"
                    (onSelectingPreviousTab)="getTab('previous')" [groupDeepDiveDetails]="deepDiveGroupQuestions"
                    [tabsLength]="deepdiveTabs?.length" [showDescription]="false"
                    [refYear]="refYear"></app-deepdive-questions-multiple-locations>
                  <div class="d-flex justify-content-end">
                    <button class="mt-5 button-secondary-white" 
                    [disabled]="!getQuestionFormState()"
                    (click)="onChangeDeepdiveAnswers()" *ngIf="!showLoader">
                      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                        *ngIf="showButtonLoader"></span>
                      <span *ngIf="!showButtonLoader">Wijzigingen opslaan</span>
                    </button>
                  </div>
                  <app-error [errorType]="'consultant'"></app-error>
                </div>
              </ng-container>
              <ng-container *ngIf="!deepDiveGroupQuestions?.QUESTIONS?.length">
                <p class="no-assessment-submitted mt-4">
                  Your client hasn't submitted the assessment for this Break Even Goal
                  yet.
                </p>
              </ng-container>
            </ng-template>
          </li>
        </ul>
      </div>
    </div>
    <div class="col pencil-icon" style="cursor: pointer;" (click)="onPencilIconClick()">
      <img
      src="../../assets/images/pencil.svg"
      alt="edit-icon"
    />
    </div>
  </div>
</div>
<div class="deepdive-content-container">
  <div class="left-layout">
    <div [ngbNavOutlet]="tabNav"></div>
  </div>
  <div class="right-layout">
    <ng-container *ngIf="deepdiveCharts?.DEEPDIVE_CHARTS?.length">
      <div
        *ngFor="
          let deepiveChart of deepdiveCharts?.DEEPDIVE_CHARTS;
          let i = index
        "
        class="charts"
      >
        <p class="chart-title">
          {{chartTitle[i]}}
        </p>

        <app-chart
          [id]="deepiveChart?.ID"
          [chartDetails]="
            utilService.getChartData(deepiveChart, deepdiveCharts?.TITLE)
          "
          (onTotalCalculated)="onTotalCalculated($event)"
        ></app-chart>
      </div>
    </ng-container>
  </div>
</div>
