import { Component, inject, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { cloneDeep, range, set } from 'lodash';
import { Location } from '@angular/common';
import { ModalComponent } from 'src/app/components/modals/modal/modal.component';
import { ModalService } from 'src/app/shared/services/modal.service';
import { pieChartDataSingle } from 'src/app/chart-demo/data';
import { ConsultantService } from 'src/app/shared/services/consultant.service';
import { ErrorService } from 'src/app/shared/services/error.service';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { UtilService } from 'src/app/shared/services/util.service';
import { forkJoin, Observable } from 'rxjs';
import { ElementRef } from '@angular/core';
import {
  ToastService,
  TOAST_STATE,
} from 'src/app/shared/services/toast.service';
import {
  BE_GOALS,
  CONSULTANT_ASSESSMENT_NOTES_TABS,
  DEEPDIVE_PERFORMANCE_SECTIONS,
} from 'src/app/shared/utilities/constants';
import { getDescriptionText, getNotesSectionDescription, getNotesSectionTitle, incrementLastElement } from './constants';
import { StepService } from 'src/app/shared/services/step.service';
import { SharedService } from 'src/app/shared/services/shared.service';

const RL = {
  COMPANIES_WITH_SAME_SECTOR: [{
    COMPANY_NAME: "test one",
    ID: 1,
    BREAK_EVEN_GOAL_ID: 1,
  },
  {
    COMPANY_NAME: "test 2",
    ID: 1,
    BREAK_EVEN_GOAL_ID: 1,
  },
  {
    COMPANY_NAME: "test 3",
    ID: 1,
    BREAK_EVEN_GOAL_ID: 1,
  }
  ],
  COMPANIES_WITH_SAME_ENERGY_USE: [
    {
      COMPANY_NAME: "test one",
      ID: 1,
      BREAK_EVEN_GOAL_ID: 1,
    },
    {
      COMPANY_NAME: "test 2",
      ID: 1,
      BREAK_EVEN_GOAL_ID: 1,
    },
    {
      COMPANY_NAME: "test 3",
      ID: 1,
      BREAK_EVEN_GOAL_ID: 1,
    }
  ],
  OTHER_COMPANIES: [
    {
      COMPANY_NAME: "test one",
      ID: 1,
      BREAK_EVEN_GOAL_ID: 1,
    },
    {
      COMPANY_NAME: "test 2",
      ID: 1,
      BREAK_EVEN_GOAL_ID: 1,
    },
    {
      COMPANY_NAME: "test 3",
      ID: 1,
      BREAK_EVEN_GOAL_ID: 1,
    }
  ],
}

@Component({
  selector: 'app-assessment-comments-tab',
  templateUrl: './assessment-comments-tab.component.html',
  styleUrls: ['./assessment-comments-tab.component.scss'],
})
export class AssessmentCommentsTabComponent implements OnInit {
  customerId!: any;
  notesSectionDetails: any = [];
  commentTabs: any = cloneDeep(CONSULTANT_ASSESSMENT_NOTES_TABS);
  @Input() selectedBEGoal: any;
  private activatedRoute = inject(ActivatedRoute);
  private router = inject(Router);
  private consultantService = inject(ConsultantService);
  public errorService = inject(ErrorService);
  public loaderService = inject(LoaderService);
  public utilService = inject(UtilService);
  public stepService = inject(StepService)
  private toastService = inject(ToastService);
  private modalService = inject(ModalService);
  stateOfDashboard: any;
  recommendationList: any;
  deepdiveYear: any;
  breakEvenGoalId: any;
  maxTextEditors: any = [1, 2];
  getBeDescriptionText: any = getDescriptionText;
  getTitle: any = getNotesSectionTitle;
  getDescription: any = getNotesSectionDescription;
  isPublishView: any = false;
  isConsultantPreview: any = false;
  deepdiveCharts: any;
  modal: any;
  dashboardGlobalState: any;
  constructor(private elementRef: ElementRef, private location: Location,
    private sharedService: SharedService) { }

  ngOnInit(): void {
    this.customerId =
      this.activatedRoute?.parent?.snapshot?.paramMap?.get('id');
    const queryParams = this.activatedRoute.snapshot.queryParamMap;
    this.deepdiveYear = queryParams.get('year');
    this.breakEvenGoalId = queryParams.get('breakEvenGoalId');
    this.selectedBEGoal = BE_GOALS.find((beGoal: any) => beGoal.ID === Number(this.breakEvenGoalId));

    this.fetchNotesAndCharts();
    this.fetchNoteRecommendations();
    this.activatedRoute.url.subscribe(segments => {
      const path = segments[0].path;
      if (path === 'publish') {
        this.isPublishView = true;
        this.isConsultantPreview = false;
      } else if (path === 'consultant-preview') {
        this.isConsultantPreview = true;
        this.isPublishView = true;
      } else {
        this.isConsultantPreview = false;
        this.isPublishView = false;
      }
    });

    if (this.isPublishView) {
      this.sharedService.getCustomerDashaboardState().subscribe((state) => {
        this.dashboardGlobalState = state;
        // console.log("Here is the state of dashboard", state);
        if (state) {
          if (state.hasOwnProperty(this.customerId)) {
            this.stateOfDashboard = state[this.customerId][this.deepdiveYear][this.breakEvenGoalId];
            // console.log("Here is the state of dashboard", this.stateOfDashboard);
          }
        }

      })
    }

    this.stepService.setCurrentStep(this.isPublishView ? 3 : 2);
  }

  fetchNotesAndChartsX() {
    this.fetchNotesAndCharts();
    //#TODO fetch companies;
    // if (tab?.noteType === 'ACTIONS' || tab?.noteType === "GOALS" && !this.recommendationList) {
    //   this.fetchNoteRecommendations();
    // }
  }


  setDashboardStateGlobally() {
    let dashboardState;
    if (this.dashboardGlobalState) {
      dashboardState = this.dashboardGlobalState;
      if (dashboardState.hasOwnProperty(this.customerId)) {
        // Check if the deep dive year already exists for this customer
        if (dashboardState[this.customerId].hasOwnProperty(this.deepdiveYear)) {
          // Merge the existing deep dive year with the new BE goal ID parameter
          dashboardState[this.customerId][this.deepdiveYear][this.selectedBEGoal.ID] = "PUBLISHED";
        } else {
          // Create a new deep dive year entry for this customer
          dashboardState[this.customerId][this.deepdiveYear] = {
            [this.selectedBEGoal.ID]: "PUBLISHED"
          };
        }
      } else {
        // Create a new entry for this customer
        dashboardState[this.customerId] = {
          [this.deepdiveYear]: {
            [this.selectedBEGoal.ID]: "PUBLISHED"
          }
        };
      }
    } else {
      // If no state exists, create a new one
      dashboardState = {
        [this.customerId]: {
          [this.deepdiveYear]: {
            [this.selectedBEGoal.ID]: "PUBLISHED"
          }
        }
      };
    }

    this.sharedService.setCustomerDashaboardState(dashboardState);
  }


  openModal(
    title?: string,
    description?: string,
    buttonLabel?: string,
    modalType?: string
  ) {
    this.modal = this.modalService.open(ModalComponent, {
      backdrop: 'static',
      windowClass: 'submitAnswersModal',
      centered: true,
    });
    this.modal.componentInstance.data = {
      title,
      description,
      buttonLabel,
      showCancelButton: true,
    };
    this.modal.componentInstance.actionButtonClicked.subscribe(
      (actionType: string) => {
        if (actionType === 'Dashboard publiceren') {
          this.publishDeepdiveDashboard('PUBLISH_QUESTIONNAIRE');
        }
      }
    );
  }

  publishDashboard() {
    let title = 'Dashboard publiceren';
    let description =
      'Weet u zeker dat u het dashboard wilt publiceren? Het dashboard zal zichtbaar worden voor uw klant. ';
    let buttonLabel = 'Dashboard publiceren';
    this.openModal(title, description, buttonLabel, 'publishDeepdiveDashboard');
  }

  openDashboardPreview() {
    const baseUrl = window.location.origin;
    const redirectUrl = `${baseUrl}/consultants/selected-client/${this.customerId}/consultant-preview?breakEvenGoalId=${this.selectedBEGoal?.ID}&year=${this.deepdiveYear}`
    window.open(redirectUrl, "_blank");
  }

  /**
 * Consultant publishing the deepdive dashboard
 * @param eventId - string
 */
  publishDeepdiveDashboard(eventId: any) {
    let error_message = '';
    this.consultantService
      .publishDeepdive(this.customerId, this.selectedBEGoal?.ID, eventId, this.deepdiveYear)
      .subscribe({
        next: (response: any) => {
          this.toastService.showToast(
            TOAST_STATE.success,
            'Dashboard is gepubliceerd'
          );
          this.modal.dismiss();
          this.stateOfDashboard = "DEEPDIVE_PUBLISHED";
          this.setDashboardStateGlobally();
        },
        error: (error) => {
          // handle error
          this.modal.dismiss();
          error_message =
            error?.error?.message ??
            'Er ging iets mis. Vernieuw de pagina of neem contact op met het website ondersteuningsteam.';
          this.toastService.showToast(TOAST_STATE.danger, error_message);
        },
        complete: () => {
          //Request completed
        },
      });
  }

  onCompanyNameClick(company: any) {
    const baseUrl = window.location.origin;
    // const redirectUrl = `${baseUrl}/consultants/preview-dashboard?customerId=${company?.ID}&beGoalID=${company?.BREAK_EVEN_GOAL_ID}&`
    const redirectUrl =`${baseUrl}/consultants/selected-client/${company?.ID}/consultant-preview?breakEvenGoalId=${company?.BREAK_EVEN_GOAL_ID}&year=${this.deepdiveYear}`
    window.open(redirectUrl, "_blank");
  }

  // getPerformanceText(notes: any) {
  //   if (notes?.length === 2) {
  //     notes.map((note: any) =>
  //       Number(note?.ID) === 1
  //         ? set(note, 'TITLE', 'Hernieuwbare energie')
  //         : set(note, 'TITLE', 'Energieverbruik')
  //     );
  //   } else {
  //     const missedID = this.maxTextEditors.filter((total: any) =>
  //       notes.every((note: any) => parseInt(note.ID) !== total)
  //     );
  //     notes.map((note: any) =>
  //       Number(note?.ID) === 1
  //         ? set(note, 'TITLE', 'Hernieuwbare energie')
  //         : set(note, 'TITLE', 'Energieverbruik')
  //     );
  //     const extraObject = {
  //       ID: missedID[0],
  //       TEXT: '',
  //       TITLE: missedID[0] === 1 ? 'Hernieuwbare energie' : 'Energieverbruik',
  //     };
  //     notes = [...notes, extraObject];
  //   }
  //   return notes;
  // }


  getMaxNotes(tab: any) {
    if (tab?.commentGroupId === 1) {
      return this.utilService.getDeepdiveMaxNotePerformanceSections(this.selectedBEGoal?.TITLE)
    } else if (tab?.commentGroupId === 3) {
      return this.utilService.getDeepdiveMaxNoteGoalsSections(this.selectedBEGoal?.TITLE);
    }
    return [1];

  }

  fetchNotesAndCharts() {
    // Define an array to store observables for each API call
    const apiCalls: Observable<any>[] = [];
    this.errorService.hide();
    // this.loader = true;
    this.loaderService.show();
    // Loop through each note type and create an observable for each API call
    apiCalls.push(this.consultantService
      .getDeepdiveCharts(this.customerId, this.selectedBEGoal?.ID, this.deepdiveYear));
    for (const tabs of this.commentTabs) {
      apiCalls.push(
        this.consultantService.getDeepdiveNotesForConsultant(
          this.customerId,
          this.breakEvenGoalId,
          this.deepdiveYear,
          tabs.noteType
        )
      );
    }

    // Execute all API calls in parallel
    forkJoin(apiCalls).subscribe({
      next: (responses: any[]) => {
        for (let i = 0; i < responses.length; i++) {
          const response = responses[i];
          if (i === 0) {
            this.deepdiveCharts = response;
          } else {
            // Process each response based on the corresponding noteType
            const tab = this.commentTabs[i - 1];
            if (response.length > 0) {
              this.notesSectionDetails.push({
                [tab.commentGroupId]: response
              });
            } else {
              this.notesSectionDetails.push({
                [tab.commentGroupId]: this.getMaxNotes(tab)
                // tab.commentGroupId === 2 || tab.commentGroupId === 4 ? [1, 2, 3, 4] : [1]
              });
            }
          }

        }
      },
      error: (error) => {
        // handle error
        this.loaderService.hide();
        this.errorService.show();
      },
      complete: () => {
        // Request completed
        this.loaderService.hide();
      },
    });
  }
  /**
   * Fetching the Deepdive Notes Recommendations
   */
  fetchNoteRecommendations() {
    this.errorService.hide();
    this.consultantService
      .getDeepdiveNotesRecommendations(
        this.customerId,
        this.breakEvenGoalId,
        this.deepdiveYear,
      )
      .subscribe({
        next: (response: any) => {
          this.recommendationList = response;
          //response;
        },
        error: (error) => {
          // handle error
          //#TODO uncomment this line
          // this.errorService.show();
        },
        complete: () => {
          //Request completed
        },
      });
  }

  navigate(route: any) {
    if (route === 'previous') {
      this.location.back();
    } else {
      if (!this.isPublishView) {
        let currentUrl = this.router.url;
        let newUrl = currentUrl.replace('notes', 'publish');
        this.router.navigateByUrl(newUrl);
      }
    }
  }
}
