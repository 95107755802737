import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { set } from 'lodash';
import { AuthService } from 'src/app/core/services/auth.service';
import { UtilService } from 'src/app/shared/services/util.service';

@Component({
  selector: 'app-select-searchable',
  templateUrl: './select-searchable.component.html',
  styleUrls: ['./select-searchable.component.scss'],
})
export class SelectSearchableComponent implements OnInit {
  @Input() id = '';
  @Input() label = '';
  @Input() info = '';
  @Input() prefix = '';
  @Input() postfix = '';
  @Input() controlName = '';
  @Input() customError = '';
  @Input() formGroup: FormGroup | AbstractControl | any;
  @Input() selectOptions?: any;
  @Input() disabled?: any;
  @Input() readonly?: any;
  @Output() selectedOption = new EventEmitter<any>();
  open: boolean = false;

  constructor(
    public utilService: UtilService,
    public authService: AuthService
  ) {}

  get control(): AbstractControl {
    return this.formGroup.get(this.controlName);
  }

  getSearchTerm() {
    return this.formGroup.get(this.controlName)?.value;
  }

  chosenOption(option: any) {
    this.control.setValue(option?.NAME, {
      onlySelf: true,
    });
  }

  search() {
    if (!this.formGroup.get(this.controlName).value) {
      this.selectOptions.forEach((option: any) => delete option.isSelected);
      this.selectedOption.emit(this.controlName);
    }
  }

  select(option: any) {
    this.chosenOption(option);
    this.selectOptions.forEach((option: any) => delete option.isSelected);
    if (this.controlName === 'sector') {
      option.SUB_SECTORS.forEach((option: any) => delete option.isSelected);
      this.authService.setSector(option);
      this.formGroup.get('subSector').setValue('');
      this.formGroup.get('businessActivity').setValue('');
    } else if (this.controlName === 'subSector') {
      option.BUSINESS_ACTIVITIES.forEach(
        (option: any) => delete option.isSelected
      );
      this.authService.setSubsector(option);
      this.formGroup.get('businessActivity').setValue('');
    }  else if (this.controlName === 'clientRegion') {
      this.authService.setClientRegion(option);
    } else if (this.controlName === 'assginedConsultant') {
      this.authService.setAssignedConsultant(option);
    }
    else {
      this.authService.setBusinessActivity(option);
    }
    set(option, 'isSelected', true);
    this.selectedOption.emit(option);
  }

  ngOnInit(): void {}

  openChange(event: any) {
    this.open = event;
  }
}
