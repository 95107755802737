import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  SecurityContext,
} from '@angular/core';
import { AsyncSubject, Subject } from 'rxjs';
import { Editor } from 'tinymce';
import {
  DomSanitizer,
  SafeResourceUrl,
  SafeUrl,
} from '@angular/platform-browser';
import { AbstractControl, FormGroup } from '@angular/forms';
import { forbiddenWordsChecker, replaceUnmarkedOccurrences, removeUnallowedHighlightSpans } from 'src/app/shared/utilities/forbidden-words-checker';
@Component({
  selector: 'app-text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.scss'],
})

export class TextEditorComponent implements OnInit {
  editor!: Editor;
  settings: any;
  content: any;
  forbiddenContent: any;
  sanitizer = inject(DomSanitizer);
  @Input() id!: any;
  @Input() editorContent: any;
  @Input() controlName = '';
  @Input() editorFormGroup: FormGroup | AbstractControl | any;
  @Output() emitContent = new EventEmitter<any>();
  @Output() autoSaveContent = new EventEmitter<any>();
  editorSubject: Subject<any> = new AsyncSubject();
  constructor() { }

  get editorValue(): AbstractControl {
    return this.editorFormGroup?.get(this.controlName)?.value;
  }

  ngOnInit() {
    this.setupEditor(this.editorContent ?? '');
  }

  ngOnChanges() {
    if (!this.editorValue) {
      this.editor?.setContent('');
    }
    if (this.editorValue) {
      this.editorContent = this.sanitizer.sanitize(
        SecurityContext.HTML,
        this.editorValue
      );
      this.setupEditor(this.editorContent);
    }
  }

  //We can show a message till the editor initializes or loads in the DOM
  onEditorInit(event: any) {
    this.editorSubject.next(event.editor);
    this.editorSubject.complete();
  }

  getText() {
    //Getting the text editor content in the html format
    let content = this.editor.getContent({ format: 'html' });
    //Sanitizing offers a security mechanism to remove unsafe (and potentially malicious) content from untrusted raw HTML strings before presenting them to the user.
    this.content = this.sanitizer.bypassSecurityTrustHtml(content);
    //Emitting the content to the parent component
    this.emitContent.emit(this.content);
  }

  checkForbiddenWords() {
    const inputText = this.editor?.getContent({ format: 'text' });
    const { isError, markedWords } = forbiddenWordsChecker(inputText);

    const forbidden = markedWords as any;
    this.forbiddenContent = { isError, markedWords: forbidden?.join(', ') };

    var formattedContent = this.editor?.getContent({ format: 'html' });
    var updatedContent = formattedContent;


    markedWords?.forEach((markedWord) => {
      updatedContent = replaceUnmarkedOccurrences(updatedContent, markedWord);;
    });

    updatedContent = removeUnallowedHighlightSpans(updatedContent, markedWords);

    if (formattedContent !== updatedContent) {
      this.editor.resetContent();
      this.editor.insertContent(updatedContent);
    }

  }


  //Setting up the the text editor with the required plugins
  setupEditor(content?: any) {
    this.settings = {
      selector: 'textarea',
      browser_spellcheck: true,
      contextmenu: false,
      base_url: '/tinymce', // Root for tinymce resources
      suffix: '.min', // Suffix to use when loading tinymce resources
      height: 420,
      // statusbar: false, //We can remove the footer
      menubar: false, //Removed the menubar
      branding: false, //Removed the tinymce branding in the footer
      promotion: false, //Removing the tinymce promotions (i.e. upgrading the tinymce plugins, etc)
      link_default_target: '_blank', //Opening the links in the new tab (Note: Still we have the option in the text editor to either open the link in new tab or in the current tab)
      plugins: [
        '  searchreplace autolink directionality  visualblocks visualchars link  codesample table charmap pagebreak nonbreaking anchor  insertdatetime advlist lists  wordcount   help  charmap autosave'
      ],
      autosave_interval: '20s',
      toolbar:
        'undo redo spellcheckdialog formatpainter | blocks | bold italic | link | checklist bullist numlist | removeformat | autosave',

      external_plugins: {}, //Can add if there are any external plugins
      allow_conditional_comments: true,
      content_style: '.highlight { color: #E03000; } body { background-color: #fffff; }',

      setup: (editor: Editor) => {
        this.editor = editor;
        //On initializing the text-editor, prepopulating the content in the raw format
        editor.on('init', () => {
          editor.setContent(content ?? '', { format: 'raw' });
        });
        editor.on('paste', () => {
          this.checkForbiddenWords();
        });
        editor.on('blur', () => {
          this.checkForbiddenWords();
          this.autoSave(editor.getContent());
        });

        editor.on('input', () => {
          this.checkForbiddenWords();
        });
        //Getting the text-editor content on the blur event
        editor.on('keypress', () => {
          this.getText();
        });
      },
    };
  }

  autoSave(content: any) {
    if(content){
      this.autoSaveContent.emit(content);
    }

  }
}
