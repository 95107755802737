import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { set } from 'lodash';
import { UtilService } from 'src/app/shared/services/util.service';
@Component({
  selector: 'searchable-dropdown',
  templateUrl: './searchable-dropdown.component.html',
  styleUrls: ['./searchable-dropdown.component.scss'],
})
export class SearchableDrowndownComponent implements OnInit {
  @Input() id = '';
  @Input() label = '';
  @Input() info = '';
  @Input() prefix = '';
  @Input() postfix = '';
  @Input() controlName = '';
  @Input() customError = '';
  @Input() formGroup: FormGroup | AbstractControl | any;
  @Input() selectOptions?: any;
  @Input() disabled?: any;
  @Input() readonly?: any;
  @Output() selectedOption = new EventEmitter<any>();
  open: boolean = false;

  constructor(
    public utilService: UtilService,
  ) {}

  get control(): AbstractControl {
    return this.formGroup.get(this.controlName);
  }

  getSearchTerm() {
    return this.formGroup.get(this.controlName)?.value;
  }

  chosenOption(option: any) {
    this.control.setValue(option?.NAME, {
      onlySelf: true,
    });
  }

  search() {
    if (!this.formGroup.get(this.controlName).value) {
      this.selectOptions.forEach((option: any) => delete option.isSelected);
      this.selectedOption.emit(this.controlName);
    }
  }

  select(option: any) {
    this.chosenOption(option);
    this.selectOptions.forEach((option: any) => delete option.isSelected);
    set(option, 'isSelected', true);
    this.selectedOption.emit({
      selection: option,
      controlName: this.controlName,
    });
  }

  ngOnInit(): void {
    if (!this.formGroup.get(this.controlName).value) {
      this.selectOptions.forEach((option: any) => delete option.isSelected);
    }
  }

  openChange(event: any) {
    this.open = event;
  }
}
