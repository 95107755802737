export const TEMP_BG = [
  '#FFA500',
  '#38C868',
  '#146EC5',
  '#F9DC5C',
  '#90D1E2',
  '#FF7523',
  '#00FFFF',
  '#900211',
  '#008000',
  '#90D1E2',
  '#FFC066',
  '#660211',
  '#3A6EC5',
];

export const isStackedGraph = (dataLabel: string) => {
  const isStacked =
    (dataLabel == 'WASTE' ||
      dataLabel == 'Afval' ||
      dataLabel == 'TOTAL_WATER_SAVED' ||
      dataLabel == 'TOTAL_WATER_DISCHARGED' ||
      dataLabel == 'TOTAL_WATER_INPUT');
  return isStacked;
}

const MIN_THRESHOLD = 30;
const MIN_RANGE = 50;
const MAX_RANGE = 100;
/**
 * To normalize the data in a way that they become closer to each other,
 * we can use the Z-score normalization technique.
 * This technique scales the values by subtracting the mean
 * and dividing by the standard deviation,
 * which helps bring the values closer to a standard distribution.
 * Then we can scale the values according to our min and max range
 */
export const normalizeData = (data: number[]) => {
  if (data?.length < 2) {
    return data;
  }
  // Calculate the mean and standard deviation
  const sum = data.reduce((acc: number, value: number) => acc + value, 0);
  const mean = sum / data.length;
  const squaredDifferences = data.map((value: number) =>
    Math.pow(value - mean, 2)
  );
  const variance =
    squaredDifferences.reduce((acc: number, value: number) => acc + value, 0) /
    data.length;
  const standardDeviation = Math.sqrt(variance);

  // Normalize the values using Z-score
  const normalizedValues = data.map(
    (value: number) => (value - mean) / standardDeviation
  );

  const minValue = Math.min(...normalizedValues);
  const maxValue = Math.max(...normalizedValues);
  let rescaledValues = normalizedValues.map(
    (value: number) =>
      ((value - minValue) / (maxValue - minValue)) * (MAX_RANGE - MIN_RANGE) +
      MIN_RANGE
  );

  if (rescaledValues.indexOf(0) !== -1) {
    rescaledValues = rescaledValues.map((value) => value + MIN_THRESHOLD);
  }

  return rescaledValues;
};

export const splitLabel = (label: string) => {
  const maxLength = 30;

  const words = label.split(' ');

  if (words.length >= 2) {
    // Get the last word and the second last word
    const lastWord = words.pop();
    const secondLastWord = words.pop();

    // Insert the words at the same index
    words.push(`${secondLastWord} ${lastWord}`);
  }

  let result = [];
  let currentPart = '';

  for (let i = 0; i < words.length; i++) {
    const word = words[i];

    if (currentPart.length + word.length + 1 <= maxLength) {
      // Add the word to the current part
      if (currentPart.length > 0) {
        currentPart += ' ';
      }
      currentPart += word;
    } else {
      // Push the current part to the result and start a new part
      result.push(currentPart);
      currentPart = word;
    }
  }

  // Push the last part to the result
  result.push(currentPart);
  return result;
};


export const getBarThickness = (data: number[]) => {
  const minThickness = 150;
  const maxValue = Math.max(...data);
  return Math.max(maxValue.toString().length * 30, minThickness);
}