import { Component, Input, OnInit } from '@angular/core';
import { cloneDeep } from 'lodash';
import { RISK_LEVELS } from 'src/app/shared/utilities/constants';

@Component({
  selector: 'app-risk-levels',
  templateUrl: './risk-levels.component.html',
  styleUrls: ['./risk-levels.component.scss'],
})
export class RiskLevelsComponent implements OnInit {
  @Input() beGoal: any;
  @Input() width: any;
  riskLevels: any = cloneDeep(RISK_LEVELS);

  constructor() {}

  ngOnInit(): void {}
}
