<div class="modal-view">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header ">
        <h4 class="modal-title">Voeg een vestiging toe</h4>
        <button type="button" class="close" aria-label="Close" (click)="cancel()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form [formGroup]="locationForm" (ngSubmit)="saveLocation()">
        <div class="modal-body">
          <div class="form-group">
            <p class="location-description-text">Vul hieronder een vestigingsnaam in.</p>
            <label for="locationName" class="input-label">Vestiging naam</label>
            <input type="text" class="form-control input-box" id="locationName" formControlName="locationName"
              placeholder="Naam (max 40 tekens)" (keydown.space)="utilService.restrictLeadingSpace($event)"
              autocomplete="off" [ngClass]="{errorInputBorder: (
              locationForm.controls['locationName'].hasError('required') ||
              locationForm.controls['locationName'].hasError('maxlength')
              ) &&
              locationForm.controls['locationName'].touched 
            }">
            <div class="d-flex pt-2" id="locationName-error-message" [ngClass]="
              locationForm.controls['locationName'].hasError('maxlength')
               ? 'visible'
               : 'invisible'
           ">
              <img src="../../assets/images/exclamation-circle.svg" alt="Error" class="align-self-start" />
              <span class="error px-1">	U heeft ongeldige tekens ingevoerd.</span>
            </div>

          </div>
        </div>
        <div class="modal-footer">
          <button class="button-secondary-white btn-width" (click)="cancel()">
            <span>Annuleren</span>
          </button>
          <button class="button-primary-orange btn-width" type="submit" (click)="saveLocation()"
            [disabled]="isDisabled()">
            <span>Opslaan</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
