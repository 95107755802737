<div
  ngbDropdown
  #selectDropdown="ngbDropdown"
  class="dropdown-container"
  (openChange)="openChange($event)"
>
  <div class="search-input">
    <input
      type="text"
      class="form-control customInput"
      placeholder="{{ placeholder }}"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
      autocomplete="off"
      id="{{ id }}"
      value="{{ selectedValue }}"
      ngbDropdownAnchor
      (focus)="selectDropdown.open()"
      readonly
    />
    <img
      class="arrow-icon cursor"
      ngbDropdownToggle
      [src]="
        open
          ? '../../../assets/images/select-upArrow.svg'
          : '../../../assets/images/select-downArrow.svg'
      "
      alt="arrow"
      [ngClass]="{ disabling: disabled || readonly, positionArrow: open }"
    />
  </div>
  <div
    class="select-dropdown scrollbar"
    ngbDropdownMenu
    aria-labelledby="dropdownBasic2"
    attr.aria-labelledby="dropdownBasic_{{ selectedValue }}"
  >
    <ul>
      <li
        class="select-options"
        *ngFor="let option of selectOptions"
        ngbDropdownItem
        role="option"
        (click)="select(option)"
        attr.value="{{ option }}"
        [ngClass]="{ active: option === selectedValue }"
      >
        <span>{{ option }}</span>
        <img
          *ngIf="option === selectedValue"
          src="../../../assets/images/select-tick.svg"
          alt="Selected"
        />
      </li>
      <li class="select-options" *ngIf="!selectOptions?.length">
        No years found
      </li>
    </ul>
  </div>
</div>
