import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss'],
})
export class ContextMenuComponent implements OnInit {
  @Input() menuOptions: any;
  @Output() menuOptionSelected = new EventEmitter<any>();
  selectedMenu: string = '';
  constructor() {}

  ngOnInit(): void {}

  selectMenuOption(option: any) {
    this.selectedMenu = option?.dataKey;
    this.menuOptionSelected.emit(option);
  }
}
